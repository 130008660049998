import React, { useCallback, useEffect, useState } from 'react'
import { ArrowPathIcon, CalendarDaysIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAssessmentDetailById, postAssessment, updateAssessmentDetailsById } from '../../WebCalls';
import { getCurrentFormattedDate } from '../../helpers/constants';
import Notification from '../Shared/Notification';

function AddEditAssessment() {

    const [assessmentDetails, setAssessmentDetails] = useState({
        title: '',
        description: '',
        baseScore: '',
        time: '',
        exceptionalScore: '',
        numOfQuestions: '',
        lastUpdatedDate: getCurrentFormattedDate(),
        startTime: null as string | null
    })
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>(null);

    const [postAssessmentDetails, setPostAssessmentDetails] = useState({
        title: '',
        description: '',
        baseScore: '',
        time: '',
        exceptionalScore: '',
        numOfQuestions: '',
        lastUpdatedDate: getCurrentFormattedDate(),
        startTime: null as string | null
    })
    const [successtNotification, setSuccessNotification] = useState(false);
    const [notifcationMessage, setNotificationMessage] = useState('');

    const { id } = useParams();

    const navigate = useNavigate();

    const handleChangeDate = (e: any) => {
        setSelectedDate(e.target.value);
    };

    const handleChangeTime = (e: any) => {
        setSelectedTime(e.target.value);
    };

    const getAssessmentDetails = useCallback(async () => {
        if (id) {
            getAssessmentDetailById(id).then((res) => {
                setAssessmentDetails(res);
                setPostAssessmentDetails(res);
                if (res.startTime) {
                    const dateAndTime = res.startTime;
                    const date = dateAndTime.split("T")[0];
                    const time = dateAndTime.split("T")[1];
                    setSelectedTime(time)
                    setSelectedDate(date)
                }
            });
        }
        else {
            setAssessmentDetails({
                title: '',
                description: '',
                baseScore: '',
                time: '',
                exceptionalScore: '',
                numOfQuestions: '',
                lastUpdatedDate: getCurrentFormattedDate(),
                startTime: null

            })
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            getAssessmentDetails();
        }
    }, [id, getAssessmentDetails]);

    const handleAssessmentSubmit = async (e: any) => {
        e.preventDefault();
        await postAssessment(assessmentDetails);
        setNotificationMessage('Assessment Addedd Successfully');
        setSuccessNotification(true);
        setTimeout(() => {
            setNotificationMessage('');
            navigate('/assessments');
        }, 2000)

    }

    const handleAssessmentUpdate = async (e: any) => {
        e.preventDefault();
        updateAssessmentDetailsById(assessmentDetails);
        setNotificationMessage('Assessment Edited Successfully');
        setSuccessNotification(true);
        setTimeout(() => {
            setNotificationMessage('');
            navigate('/assessments');
        }, 2000)
    }

    const validate = () => {
        const { title, description, baseScore, time, exceptionalScore, numOfQuestions } = assessmentDetails;
        if (!title.trim() || !description.trim() || !(baseScore?.toString().length > 0) || !(time?.toString().length > 0) || !(exceptionalScore?.toString().length > 0) || !(numOfQuestions?.toString().length > 0)) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (selectedDate && selectedTime) {
            const combinedDateTime = new Date(`${selectedDate}T${selectedTime}`).toISOString();

            setPostAssessmentDetails(prevDetails => ({
                ...prevDetails,
                startTime: combinedDateTime
            }));
            setAssessmentDetails(prevDetails => ({
                ...prevDetails,
                startTime: combinedDateTime
            }));
        }
    }, [selectedDate, selectedTime]);

    const handleResetTime = () => {
        setAssessmentDetails(prevDetails => ({
            ...prevDetails,
            startTime: null
        }));
        setPostAssessmentDetails(prevDetails => ({
            ...prevDetails,
            startTime: null
        }));
        setSelectedDate('');
        setSelectedTime('');
    };

    return (
        <div className=''>
            <header className="bg-gray-50 py-8 dark:bg-darkbglight">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <Link
                                            to="/assessments"
                                            className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                        >
                                            Assessments
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                                            aria-hidden="true"
                                        />
                                        {
                                            id ? <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                Edit
                                            </span>
                                                : <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    Create
                                                </span>
                                        }
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        {
                            id ? <>
                                <div className='flex flex-row justify-between align-center text-center'>
                                    <div className=''>
                                        <h1 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight  text-left dark:text-gray-300'>{postAssessmentDetails.title}</h1>
                                        <h4 className='mt-2 text-xs font-bold leading-7 text-gray-900 sm:text-sm sm:tracking-tight dark:text-gray-300 text-left'>Num of Questions: {postAssessmentDetails.numOfQuestions}</h4>
                                    </div>
                                    <div className='flex flex-col align-center justify-center'>
                                        <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs sm:text-base md:text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => navigate(`/assessment/questions/${id}`)}>
                                            Assessment Questions
                                        </button>

                                    </div>
                                </div>
                            </> : <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight  text-left dark:text-gray-300">
                                Create New Assessment
                            </h1>
                        }
                    </div>
                </div>
            </header>
            <form className="space-y-12 sm:space-y-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
                <div className="">
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="assessment-title" className="block text-sm font-medium text-gray-700 w-64 text-left mb-4 dark:text-gray-400">
                            Title:
                        </label>
                        <input
                            id="assessment-title"
                            name="assessment-title"
                            type="text"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, title: e.target.value })}
                            value={assessmentDetails.title}
                            placeholder='Eg. Sr. Software Engineer'
                        />

                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="assessment-desc" className="block text-sm font-medium text-gray-700 w-64 text-left	mb-4 dark:text-gray-400">
                            Description:
                        </label>
                        <textarea
                            id="assessment-desc"
                            name="assessment-desc"
                            rows={3}
                            className="w-full min-h-96 rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, description: e.target.value })} value={assessmentDetails.description}
                            placeholder='Enter Description'

                        />
                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="base-scrore" className="block text-sm font-medium text-gray-700 w-64 text-left	mb-4 dark:text-gray-400">
                            Base Score:
                        </label>
                        <input
                            id="base-scrore"
                            name="base-scrore"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, baseScore: e.target.value })} value={assessmentDetails.baseScore}
                            placeholder='Eg. 60'

                        />
                    </div>

                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="questionTime" className="block text-sm font-medium text-gray-700 w-64 text-left	mb-4 dark:text-gray-400">
                            Time <small>(In minutes)</small> :
                        </label>
                        <input
                            id="questionTime"
                            name="questionTime"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, time: e.target.value })} value={assessmentDetails.time}
                            placeholder='Eg. 60'
                            min={0}

                        />
                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="ex-score" className="block text-sm font-medium text-gray-700 w-64 text-left	mb-4 dark:text-gray-400">
                            Exceptional Score:
                        </label>
                        <input
                            id="ex-score"
                            name="ex-score"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, exceptionalScore: e.target.value })} value={assessmentDetails.exceptionalScore}
                            placeholder='Eg. 90'
                            min={0}
                        />
                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="noOfQues" className="block text-sm font-medium text-gray-700 w-64 text-left	mb-4 dark:text-gray-400">
                            Number of Questions:
                        </label>
                        <input
                            id="noOfQues"
                            name="noOfQues"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                            onChange={(e) => setAssessmentDetails({ ...assessmentDetails, numOfQuestions: e.target.value })} value={assessmentDetails.numOfQuestions}
                            placeholder='Eg. 30'
                            min={0}
                        />
                    </div>
                    <div className='flex flex-col items-start py-10 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                        <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 w-64 text-left mb-4 dark:text-gray-400">
                            Start Time
                        </label>
                        <div className="flex items-center w-full gap-2">
                            <input
                                type="date"
                                onChange={handleChangeDate}
                                value={selectedDate || ''}
                                className='rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600'
                            />
                            <input type="time" name="" id="" className=' rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 ' onChange={handleChangeTime}
                                value={selectedTime || ''}
                            />
                            <ArrowPathIcon height={18} className='dark:text-gray-200 text-gray-800 cursor-pointer' onClick={handleResetTime} />
                        </div>
                    </div>
                </div>

                <div className="mt-8 flex justify-end">
                    {
                        id ? <>
                            <button type="button" className="text-sm font-semibold text-gray-900 mr-4 dark:text-gray-100" onClick={getAssessmentDetails}>
                                Reset
                            </button>
                        </> : <>
                            <button type="button" className="text-sm font-semibold text-gray-900 mr-4 dark:text-gray-100" onClick={getAssessmentDetails}>
                                Clear
                            </button>
                        </>
                    }
                    {
                        id ? <button
                            onClick={handleAssessmentUpdate}
                            type="submit"
                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${!validate() ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                            disabled={validate()}
                        >
                            Update
                        </button> :
                            <button
                                onClick={handleAssessmentSubmit}
                                type="submit"
                                className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${!validate() ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                    }`}
                                disabled={validate()}
                            >
                                Submit
                            </button>
                    }

                </div>
            </form>
            <div>

            </div>
            <Notification
                show={successtNotification}
                setShow={setSuccessNotification}
                title="Success"
                description={notifcationMessage}
                type="success"
            />
        </div>
    )
}

export default AddEditAssessment
