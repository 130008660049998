import { Disclosure } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilSquareIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { Category, CategoryFull } from "../../../../Models";
import {
  deleteRankingCategories,
  getCompanyRankingCategories,
  getUniversityRankingCategories,
  postRankingCategories,
  updateRankingCategories,
} from "../../../../WebCalls";
import { getColorShade } from "../../../../Helpers";
import ConfirmModal from "../../../Shared/ConfirmModal";
import useApiToken from "../../../../hooks/useApiToken";
import RankingSamples from "./RankingSamples";
import useClickOutside from "../../../../hooks/useClickOutside";

const reorder = (
  list: CategoryFull[],
  startIndex: number,
  endIndex: number
) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

export default function CreateRankingSystem({
  rankTypeId,
  tab,
  setCategoriesUpdate,
}: {
  rankTypeId: string;
  tab: string;
  setCategoriesUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [category, setCategory] = useState<CategoryFull[]>([]);
  const [currentCategory, setCurrentCategory] = useState<string>("");
  const [categoryEditMode, setCategoryEditMode] = useState<{
    [id: string]: boolean;
  }>({});
  const [categoryToDelete, setCategoryToDelete] = useState<string>();
  const [errors, setErrors] = useState<string[]>([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const limitRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const orderRef = useRef<HTMLInputElement>(null);
  const supportingTextRef = useRef<HTMLTextAreaElement>(null);
  const editRef = useRef<HTMLDivElement>(null);

  useClickOutside(editRef, () => {
    setCategoryEditMode({});
  });

  const token = useApiToken();

  useEffect(() => {
    if (errors.length > 0) {
      const timer = setTimeout(() => {
        setErrors([]);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errors]);

  useEffect(() => {
    if (!token) return;
    const fetchCategory = async () => {
      let res: CategoryFull[];
      if (tab === "Companies")
        res = await getCompanyRankingCategories(rankTypeId, token);
      else res = await getUniversityRankingCategories(rankTypeId, token);
      return res;
    };
    try {
      fetchCategory().then((c) => {
        setCategory(c);
      });
    } catch (err) {
      console.error(err);
    }
  }, [rankTypeId, tab, token]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let newCategory = reorder(
      category,
      result.source.index,
      result.destination.index
    );

    newCategory = newCategory.map((nc, i) => ({ ...nc, rankNumber: i + 1 }));

    updateAllCategory(newCategory);
  };

  const handleAddCategory = () => {
    if (!token) return;
    const newOrder = category.length + 1;
    const newCategory: Category = {
      isCompanyCategory: tab === "Companies" ? true : false,
      rankTypeId: rankTypeId,
      title: currentCategory,
      rankNumber: newOrder,
      limit: 0,
    };
    postRankingCategories(newCategory, token).then((res) => {
      setCategory((prev) => [...prev, res]);
      setCategoriesUpdate(true);
    });
    setCurrentCategory("");
  };

  const updateAllCategory = async (newCategory: CategoryFull[]) => {
    if (!token) return;
    const errs: string[] = [];
    const unsuccessfullUpdates: string[] = [];
    await Promise.all(
      newCategory.map(async (c) => {
        try {
          const updatedCategory: Category = {
            rankTypeId: c.rankTypeId,
            title: c.title,
            rankNumber: c.rankNumber,
            limit: c.limit,
            samples: c.samples,
            supportingText: c.supportingText,
            isCompanyCategory: tab === "Companies" ? true : false,
          };
          await updateRankingCategories(c.id, updatedCategory, token);
        } catch (er: any) {
          unsuccessfullUpdates.push(c.id);
          errs.push(er.message);
        }
      })
    );

    const categoryUpdate = newCategory.map((nc) => {
      const unsuccessfull = unsuccessfullUpdates.find((uc) => uc === nc.id);
      if (unsuccessfull)
        return category.find((c) => c.id === nc.id) as CategoryFull;
      else return nc;
    });
    setCategory(categoryUpdate);
    setCategoriesUpdate(true);
    setErrors(errs);
  };

  const handleEditCategory = async (id: string) => {
    if (!token) return;
    const newLimit = limitRef.current?.value;
    const newOrder = orderRef.current?.value;
    const newTitle = titleRef.current?.value;
    const newSupportingText = supportingTextRef.current?.value;

    const oldOrder = category.find((c) => c.id === id)?.rankNumber as number;

    let reordered = [...category];
    if (newOrder)
      reordered = reorder(category, oldOrder - 1, Number(newOrder) - 1);
    const newCategory = reordered.map((c, i) => {
      if (c.id === id) {
        return {
          ...c,
          rankNumber: i + 1,
          limit: newLimit ? Number(newLimit) : c.limit,
          title: newTitle ? newTitle : c.title,
          supportingText:
            newSupportingText !== undefined
              ? newSupportingText
              : c.supportingText,
        };
      } else return c;
    });

    updateAllCategory(newCategory);

    handleEditMode(id);
  };

  const handleDeleteCategory = async (id: string) => {
    if (!token) return;
    await deleteRankingCategories(id, token);
    let reorder = true;
    let reorderIndex = 0;
    category.forEach((c, ind) => {
      if (c.id === id) {
        if (
          category[ind].rankNumber === category[ind - 1]?.rankNumber ||
          category[ind].rankNumber === category[ind + 1]?.rankNumber
        ) {
          reorder = false;
          return;
        }
        reorderIndex = ind;
      }
    });

    let updatedCategory = [...category];
    if (reorder) {
      updatedCategory = category.map((c, ind) => {
        if (ind >= reorderIndex) return { ...c, rankNumber: c.rankNumber - 1 };
        else return c;
      });
    }
    updatedCategory = updatedCategory.filter((c) => c.id !== id);

    updateAllCategory(updatedCategory);
    setCategory(updatedCategory);
    setDeleteModal(false);
    setCategoriesUpdate(true);
  };

  const handleEditMode = (id: string) => {
    const editMode = { ...categoryEditMode };
    Object.keys(editMode).forEach((key) => {
      if (key !== id) {
        editMode[key] = false;
      }
    });

    editMode[id] = !editMode[id];
    setCategoryEditMode(editMode);
  };

  const getRenderItem =
    (items: CategoryFull[]) =>
    (
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot,
      rubric: DraggableRubric
    ) => (
      <Disclosure
        as="div"
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className="relative"
      >
        <div
          ref={categoryEditMode[items[rubric.source.index].id] ? editRef : null}
        >
          <Disclosure.Button
            as="div"
            className="w-full flex rounded-md shadow-sm"
          >
            <div
              className={
                "flex flex-1 items-center justify-between truncate rounded-md border dark:border-gray-400 bg-white dark:bg-darkbglight"
              }
            >
              <div
                style={{
                  background:
                    tab === "Universities"
                      ? `linear-gradient(to right, ${
                          (
                            getColorShade(
                              items[rubric.source.index].rankNumber,
                              tab === "Universities"
                            ) as { grad: string; postGrad: string }
                          ).grad
                        } 50%, ${
                          (
                            getColorShade(
                              items[rubric.source.index].rankNumber,
                              tab === "Universities"
                            ) as { grad: string; postGrad: string }
                          ).postGrad
                        } 50%)`
                      : (getColorShade(
                          items[rubric.source.index].rankNumber,
                          false
                        ) as string),
                }}
                className={`flex h-8 w-9 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium ${
                  items[rubric.source.index].rankNumber >= 3
                    ? "text-gray-800"
                    : "text-gray-100"
                } border-gray-400 border dark:text-gray-200 relative`}
              >
                {categoryEditMode[items[rubric.source.index].id] ? (
                  <input
                    type="number"
                    className="h-4 border border-gray-400 rounded-md px-0 w-8 text-xs pl-1 font-normal focus:border-none bg-transparent text-white border-t-0 border-l-0 border-r-0 remove-arrow"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    defaultValue={items[rubric.source.index].rankNumber}
                    min={1}
                    max={category.length}
                    ref={orderRef}
                  />
                ) : (
                  <span>{items[rubric.source.index].rankNumber}</span>
                )}
              </div>
              <div className="flex-1 truncate text-sm  ml-2">
                {categoryEditMode[items[rubric.source.index].id] ? (
                  <input
                    id="title"
                    type="text"
                    className="h-4 border border-gray-200 rounded-md px-0 w-24 text-xs pl-1 focus:border-none focus:ring-1 focus:ring-entntblue"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    defaultValue={items[rubric.source.index].title}
                    ref={titleRef}
                  />
                ) : (
                  <span className="font-medium text-gray-900 dark:text-gray-400">
                    {items[rubric.source.index].title}
                  </span>
                )}
              </div>
              <div className="text-sm dark:text-gray-300">
                <label> Limit : </label>
                {categoryEditMode[items[rubric.source.index].id] ? (
                  <input
                    id="limit"
                    type="number"
                    className="h-4 border border-gray-200 rounded-md px-0 w-8 text-xs pl-1 focus:border-none focus:ring-1 focus:ring-entntblue remove-arrow"
                    min={1}
                    ref={limitRef}
                    defaultValue={items[rubric.source.index].limit ?? 0}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <span>
                    {items[rubric.source.index].limit
                      ? items[rubric.source.index].limit
                      : 0}
                  </span>
                )}
              </div>
              <div
                className="flex-shrink-0"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    categoryEditMode[items[rubric.source.index].id]
                      ? handleEditCategory(items[rubric.source.index].id)
                      : handleEditMode(items[rubric.source.index].id);
                  }}
                >
                  {categoryEditMode[items[rubric.source.index].id] ? (
                    <CheckCircleIcon
                      className="h-4 w-4 cursor-pointer"
                      aria-hidden="true"
                    />
                  ) : (
                    <PencilSquareIcon
                      className="h-4 w-4 cursor-pointer"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </div>
            </div>
            <span
              className="rounded-full text-red-500 hover:text-red-600 absolute -top-2 -right-2 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setCategoryToDelete(items[rubric.source.index].id);
                setDeleteModal(true);
              }}
            >
              <XCircleIcon className="h-4 w-4" aria-hidden="true" />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="bg-gray-100 cursor-default">
            {() => (
              <div className="relative">
                {categoryEditMode[items[rubric.source.index].id] ? (
                  <textarea
                    ref={supportingTextRef}
                    placeholder="Supporting text."
                    className="w-80 sm:w-full text-xs rounded-md min-h-12 border-gray-300 pt-1"
                    defaultValue={items[rubric.source.index].supportingText}
                  />
                ) : (
                  <div className="w-80 sm:w-full text-xs rounded-md border-gray-300 py-2 px-1">
                    <span className="font-medium">Supporting text : </span>{" "}
                    <span>{items[rubric.source.index].supportingText}</span>
                  </div>
                )}
                <RankingSamples
                  tab={tab}
                  rankTypeId={rankTypeId}
                  currentCategory={items[rubric.source.index]}
                  setCategory={setCategory}
                />
              </div>
            )}
          </Disclosure.Panel>
        </div>
      </Disclosure>
    );
  return (
    <>
      <div className="px-4 mt-3 scr">
        <div className="mt-2 sm:col-span-2 sm:mt-0">
          <div className="flex space-x-4">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600  col-span-2 w-full  dark:ring-gray-500 ">
              <input
                type="text"
                className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder={"Enter the category name."}
                onChange={(e) => {
                  setCurrentCategory(e.target.value);
                }}
                value={currentCategory}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && currentCategory.trim() !== "") {
                    e.preventDefault();
                    handleAddCategory();
                  }
                }}
              />
            </div>
            <button
              onClick={handleAddCategory}
              type="button"
              className="text-white cursor-pointer bg-entntblue rounded-md hover:bg-entntorange disabled:bg-gray-300 disabled:cursor-not-allowed"
              disabled={currentCategory.trim() === ""}
            >
              <PlusIcon height={34} />
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="rankingCategory"
            direction="vertical"
            renderClone={getRenderItem(category)}
          >
            {(
              provided: DroppableProvided,
              snapshot: DroppableStateSnapshot
            ) => (
              <ul
                className="mt-4 mb-6 flex-col space-y-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {category.map((c, index) => (
                  <Draggable index={index} draggableId={c.id} key={c.id}>
                    {getRenderItem(category)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {category.length === 0 && (
          <div className="w-full text-center text-sm -mt-2">
            No categories defined
          </div>
        )}
        {errors.map((er, ind) => (
          <div className="text-red-500 text-sm" key={ind}>
            Message : {er}
          </div>
        ))}
      </div>
      <ConfirmModal
        open={deleteModal}
        setOpen={setDeleteModal}
        title="Delete Rank Category"
        description="Are you sure ? Deleting a rank might effect the current ranking"
        type="danger"
        onConfirm={() => {
          categoryToDelete && handleDeleteCategory(categoryToDelete);
        }}
      />
    </>
  );
}
