import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  TimelineGap,
  TimelineCandidateEducation,
  TimelineCandidateExperience,
} from "../../Models";
import { getStyleForCompany, getStyleForUniversity } from "../../Helpers";

type TimelineItem =
  | TimelineCandidateEducation
  | TimelineCandidateExperience
  | TimelineGap;

interface TimelineType {
  timeline: TimelineItem[];
  isMobile?: Boolean;
}

am4core.useTheme(am4themes_animated);

function StraightTimeline({ timeline, isMobile = false }: TimelineType) {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    let chart = am4core.create("chartDiv", am4charts.XYChart);
    chartRef.current = chart;

    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.fontSize = 11;
    chart.padding(50, 0, 50, 0);

    let categoryAxis;
    let dateAxis;
    if (isMobile) {
      categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      dateAxis = chart.yAxes.push(new am4charts.DateAxis());
    } else {
      categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    }

    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.opposite = isMobile ? true : false;

    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = isMobile ? 30 : 50;
    dateAxis.renderer.line.strokeOpacity = 0;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.labels.template.fontSize = 8;
    dateAxis.tooltip!.background.fillOpacity = 0.2;
    dateAxis.tooltip!.background.cornerRadius = 5;
    dateAxis.tooltip!.label.paddingTop = 7;
    dateAxis.startLocation = -0.9;
    dateAxis.renderer.inversed = isMobile ? true : false;

    let labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.7;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    labelTemplate.background.fillOpacity = 0;
    labelTemplate.padding(7, 7, 7, 7);

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields[isMobile ? "categoryX" : "categoryY"] = "category";
    series.dataFields[isMobile ? "openDateY" : "openDateX"] = "start";
    series.dataFields[isMobile ? "dateY" : "dateX"] = "end";

    series.columns.template.height = 0.5;
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 1;
    series.columns.template.strokeWidth = isMobile ? 6 : 6;
    series.columns.template.width = isMobile ? 2 : 4;
    series.tooltip!.pointerOrientation = isMobile ? "vertical" : "horizontal";

    series.columns.template.tooltipText = isMobile
      ? "{name}: [bold]{openDateY}[/] - [bold]{dateY}[/]"
      : "{name}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = isMobile ? 6 : 10;
    bullet.circle.strokeOpacity = 0;
    bullet.propertyFields.fill = "color";
    bullet[isMobile ? "locationY" : "locationX"] = 0;

    let bullet2 = series.bullets.push(new am4charts.CircleBullet());
    bullet2.circle.radius = isMobile ? 6 : 10;
    bullet2.circle.strokeOpacity = 0;
    bullet2.propertyFields.fill = "color";
    bullet2[isMobile ? "locationY" : "locationX"] = 1;

    let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.radius = isMobile ? 10 : 15;
    circleBullet.circle.stroke = am4core.color("white");
    circleBullet.propertyFields.fill = "color";

    let label = circleBullet.createChild(am4core.Label);
    label.propertyFields.text = "label";
    label.fontSize = isMobile ? 10 : 12;
    label.fontWeight = "100";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.stroke = am4core.color("#FFFFFF");

    let aboveLabel = circleBullet.createChild(am4core.Label);
    aboveLabel.propertyFields.text = "name";
    aboveLabel.fontSize = isMobile ? 10 : 10;
    label.fontWeight = "100";
    aboveLabel.horizontalCenter = "middle";
    aboveLabel.verticalCenter = "bottom";
    aboveLabel.dy = -16;
    aboveLabel.stroke = am4core.color("#464a52");
    aboveLabel.fillOpacity = 1;

    aboveLabel.background.fillOpacity = 1;
    aboveLabel.background.strokeOpacity = 0;
    aboveLabel.padding(3, 5, 3, 5);

    chart.data = timeline.flatMap(
      (
        timelineData:
          | TimelineCandidateEducation
          | TimelineCandidateExperience
          | TimelineGap
      ) => {
        let data = [
          {
            category:
              "company" in timelineData && timelineData.company ? "Exp" : "Edu",
            start: new Date(timelineData.startDate),
            end: new Date(timelineData.endDate),
            color: timelineData.color
              ? timelineData.color
              : "company" in timelineData && timelineData.company
                ? getStyleForCompany(timelineData.company.ranking).background
                : "university" in timelineData && timelineData.university
                  ? timelineData.postGrad
                    ? getStyleForUniversity(
                        timelineData.university.ranking,
                        true
                      ).background
                    : getStyleForUniversity(
                        timelineData.university.ranking,
                        false
                      ).background
                  : "#6B7280",
            name:
              "company" in timelineData && timelineData.company
                ? timelineData.company.name
                : "university" in timelineData && timelineData.university
                  ? timelineData.university.name
                  : "Gap",
            label: timelineData.totalMonths,
          },
        ];

        if (
          "university" in timelineData &&
          timelineData.overlappingExperience
        ) {
          timelineData.overlappingExperience.forEach((overlap) => {
            if (overlap.company) {
              data.push({
                category: "Exp",
                start: new Date(overlap.startDate),
                end: new Date(overlap.endDate),
                color: timelineData.color ? timelineData.color : "#0D42EC",
                name: overlap.company?.name,
                label: overlap.totalMonths,
              });
            }
          });
        }

        return data;
      }
    );

    return () => {
      chart.dispose();
    };
  }, [timeline, isMobile]);

  return (
    <div
      id="chartDiv"
      style={{ width: "100%", height: isMobile ? "750px" : "264px" }}
    ></div>
  );
}

export default StraightTimeline;
