import React from "react";

const TimelineHeaderLoader = () => {
  return (
    <div className="relative flex w-full animate-pulse gap-5 py-2">
      <div className="flex flex-col items-center w-3/4 gap-4">
        <div className="h-5 w-full rounded-md bg-slate-300 text-sm"></div>
      </div>
      <div className="flex flex-col items-center w-full gap-4">
        <div className="h-5 w-full rounded-md bg-slate-300 text-sm"></div>
      </div>
      <div className="flex flex-col items-center w-3/4 gap-4">
        <div className="h-5 w-full rounded-md bg-slate-300 text-sm"></div>
      </div>
    </div>
  );
};

export default TimelineHeaderLoader;
