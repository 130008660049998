import React from "react";
import { AssessmentDropdown } from "./CreateProcessAutomation";
import { AssessmentCondition } from "./CreateRoleForm";
const AllAssessmentsDropDown = ({
  allAssessments,
  selectedAssessment,
  setAssessments,
  setSelectedAssessment,
}: {
  allAssessments: AssessmentCondition[];
  selectedAssessment: AssessmentCondition | null;
  setAssessments: React.Dispatch<React.SetStateAction<AssessmentCondition[]>>;
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<AssessmentCondition | null>
  >;
}) => {
  const handleAssessmentSelection = (
    newValue: React.SetStateAction<AssessmentCondition | null>
  ) => {
    setSelectedAssessment((prevState) =>
      typeof newValue === "function" ? newValue(prevState) : newValue
    );
    if (typeof newValue !== "function" && newValue) {
      setAssessments([newValue]);
    } else {
      setAssessments([]);
    }
  };
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400">
        Assessments
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-500 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600"></div>
        <AssessmentDropdown
          assessments={allAssessments}
          selectedAssessment={selectedAssessment}
          setSelectedAssessment={handleAssessmentSelection}
          placeholder=" Select From Assessments"
        />
      </div>
    </div>
  );
};
 
export default AllAssessmentsDropDown;