import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useParams } from "react-router-dom";
import CreateRoleForm from "./CreateRoleForm";
import { useEffect, useState } from "react";
import { Role, RoleStatus } from "../../../Models";
import { GetRole, GetStatusForRole } from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";

export default function CreateRole() {
  const [role, setRole] = useState<Role>();
  const [roleStatuses, setRoleStatuses] = useState<RoleStatus[]>();
  const { roleId } = useParams();
  const token = useApiToken();
  const location = useLocation();

  const isEditRole = location.pathname.includes("edit");

  useEffect(() => {
    if (!roleId || !token) return;
    GetRole(roleId, token).then((r) => {
      setRole(r);
    });
    GetStatusForRole(roleId, token).then((sr) => {
      setRoleStatuses(sr.roleStatuses);
    });
  }, [roleId, token]);

  return (
    <div>
      {!isEditRole && (
        <header className="bg-gray-50 py-8 dark:bg-darkbglight">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="min-w-0 flex-1">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <Link
                        to="/roles"
                        className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        Roles
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                        create
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                Create new job
              </h1>
            </div>
          </div>
        </header>
      )}

      <CreateRoleForm role={role} roleStatuses={roleStatuses} />
    </div>
  );
}
