import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Candidate, Role } from "../../Models";
import { Link, useParams } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import Review from "./Review";
import { submitReview, fetchComments, GetCandidateData } from "../../WebCalls";
import { useEffect, useState } from "react";

type FileType = {
  type: string;
  mime: string;
};

const getFileType = (base64String: string): FileType | null => {
  const fileType: FileType = { type: "", mime: "" };

  if (base64String.startsWith("JVB")) {
    fileType.mime = "data:application/pdf;base64,";
    fileType.type = "pdf";
  } else if (base64String.startsWith("UEs")) {
    fileType.mime =
      "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
    fileType.type = "docx";
  } else {
    return null;
  }

  return fileType;
};

export default function CandidateDetails({
  candidate,
  candidateRole,
}: {
  candidate: Candidate | undefined;
  candidateRole: Role[];
}) {
  const [showReviewbox, setShowReviewbox] = useState<boolean>(false);
  const [githublink, setgithublink] = useState("");
  const [previousReview, setpreviousReview] = useState("");
  const { roleId, candidateId } = useParams();

  const checkAssignmentOnPageLoad = async () => {
    if (!candidateId || !roleId) {
      return;
    }
    try {
      const userdata = await GetCandidateData(candidateId, roleId);
      if (userdata.assignmentLink != null) {
        setgithublink(userdata.assignmentLink);
        setpreviousReview(userdata.comments);
      }
    } catch (error) {
      console.error("Error checking assignment submission:", error);
    }
  };

  useEffect(() => {
    checkAssignmentOnPageLoad();
  }, []);

  async function getcomments(candidateId: any, roleId: any) {
    try {
      const review = await fetchComments(candidateId, roleId);
      setpreviousReview(review.comment);
    } catch (error) {
      console.error("Error getting review:");
    }
  }

  const handleCommentSubmit = async (comment: string) => {
    try {
      await submitReview({
        candidateId: candidateId,
        roleId: roleId,
        assignmentLink: githublink,
        comments: comment,
      });
      setShowReviewbox(false);
    } catch (error) {
      console.error("Error handling assignment submission:", error);
    }
    getcomments(candidateId, roleId);
  };

  let cvName, coverName;
  const attachments: { name: string; href: string }[] = [];

  if (candidate) {
    const coverFileType = getFileType(candidate.coverLetter);
    const cvFileType = getFileType(candidate.cv);

    if (coverFileType) {
      coverName = `cover_${candidate.firstName}_${candidate.lastName}.${coverFileType.type}`;
      attachments.push({
        name: coverName,
        href: coverFileType.mime + candidate.coverLetter,
      });
    }
    if (cvFileType) {
      cvName = `resume_${candidate.firstName}_${candidate.lastName}.${cvFileType.type}`;
      attachments.push({ name: cvName, href: cvFileType.mime + candidate.cv });
    }
  }

  return (
    <section
      aria-labelledby="applicant-information-title"
      className="lg:col-span-2"
    >
      <div className="bg-white shadow sm:rounded-lg dark:bg-darkbglight">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300"
          >
            Applicant Information
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 dark:border-gray-800">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Application for
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 ">
                {candidateRole[0]?.title}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                {candidate?.email}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {candidate?.workLocation1City?.name}
                {candidate?.workLocation2City && (
                  <span>, {candidate?.workLocation2City?.name}</span>
                )}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Education
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                <div className="-mx-4 sm:mx-4">
                  {candidate?.education && candidate?.education.length === 0 ? (
                    <p className="dark:text-gray-300">
                      Candidate does not have any education
                    </p>
                  ) : (
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 lg:table-cell dark:text-gray-300"
                          >
                            University
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell dark:text-gray-300"
                          >
                            Degree Type
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell dark:text-gray-300"
                          >
                            Period
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell dark:text-gray-300"
                          >
                            Relevant to Job
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-transparent">
                        {candidate?.education.map((education) => (
                          <tr key={education.id}>
                            <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 dark:text-gray-300">
                              {education?.university?.name}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Degree</dt>
                                <dd className="mt-1 truncate text-gray-900 dark:text-gray-300">
                                  {education.postGrad ? "PG" : "UG"}
                                </dd>
                                <dt className="sr-only sm:hidden">Period</dt>
                                <dd className="mt-1 truncate text-gray-900 sm:hidden dark:text-gray-300">
                                  {education.period}
                                </dd>
                                <dd className="mt-1 truncate text-gray-900 sm:hidden dark:text-gray-300">
                                  {education.relevant ? "Yes" : "No"}
                                </dd>
                              </dl>
                            </td>
                            <td className="hidden px-3 py-2 text-sm text-gray-900 lg:table-cell dark:text-gray-300">
                              {education.postGrad ? "PG" : "UG"}
                            </td>
                            <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                              {education.period}
                            </td>
                            <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                              {education.relevant ? "Yes" : "No"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Experience
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mx-4">
                {candidate?.experience && candidate?.experience.length === 0 ? (
                  <p className="dark:text-gray-300">
                    Candidate does not have any experience
                  </p>
                ) : (
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 lg:table-cell dark:text-gray-300"
                        >
                          Company
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell dark:text-gray-300"
                        >
                          Job Title
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell dark:text-gray-300"
                        >
                          Period
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell dark:text-gray-300"
                        >
                          Relevant to Job
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white dark:bg-transparent dark:divide-gray-700">
                      {candidate?.experience.map((experience) => (
                        <tr key={experience.company?.id + experience.title}>
                          <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 dark:text-gray-300">
                            {experience.company?.name}
                            <dl className="font-normal lg:hidden">
                              <dt className="sr-only">Job Title</dt>
                              <dd className="mt-1 truncate text-gray-900 dark:text-gray-300">
                                {experience.title}
                              </dd>
                              <dt className="sr-only sm:hidden">Period</dt>
                              <dd className="mt-1 truncate text-gray-900 sm:hidden dark:text-gray-300">
                                {experience.period}
                              </dd>
                              <dt className="sr-only sm:hidden">
                                Relevant to Job
                              </dt>
                              <dd className="mt-1 truncate text-gray-900 sm:hidden dark:text-gray-300">
                                {experience.relevant ? "Yes" : "No"}
                              </dd>
                            </dl>
                          </td>
                          <td className="hidden px-3 py-2 text-sm text-gray-900 lg:table-cell dark:text-gray-300">
                            {experience.title}
                          </td>
                          <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                            {experience.period}
                          </td>
                          <td className="hidden px-3 py-2 text-sm text-gray-900 sm:table-cell dark:text-gray-300">
                            {experience.relevant ? "Yes" : "No"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                Attachments
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300 ">
                {attachments.length === 0 ? (
                  <p className="mx-4">Candidate does not have any attachment</p>
                ) : (
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 dardark:divide-gray-700 dark:border-gray-400">
                    {attachments.map(
                      (attachment) =>
                        attachment && (
                          <li
                            key={attachment.name}
                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                          >
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 w-0 flex-1 truncate dark:text-gray-400">
                                {attachment.name}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0 dark:text-gray-400">
                              <a
                                href={attachment.href}
                                download={attachment.name}
                                className="font-medium text-entntblue hover:text-entntorange"
                              >
                                Download
                              </a>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </dd>
            </div>

            <div className="sm:col-span-2">
              {githublink.length !== 0 ? (
                <>
                  <dt className="text-sm font-medium text-gray-900 dark:text-gray-300">
                    GitHub Link
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 dardark:divide-gray-700 dark:border-gray-400">
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <GitHubIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />

                          <Link
                            to={githublink}
                            target="_blank"
                            className="ml-2 w-0 flex-1 truncate dark:text-gray-400"
                          >
                            Assignment link
                          </Link>
                        </div>
                        <div className="ml-4 flex-shrink-0 dark:text-gray-400">
                          <button
                            onClick={() => setShowReviewbox(!showReviewbox)}
                            className="font-medium text-entntblue hover:text-entntorange"
                          >
                            {showReviewbox ? "Cancel" : "Review"}
                          </button>
                        </div>
                      </li>
                    </ul>
                    <div>
                      {showReviewbox ? (
                        <Review
                          onCommentSubmit={handleCommentSubmit}
                          previousReview={previousReview}
                          candidateId={candidateId}
                          roleId={roleId}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </dd>
                </>
              ) : (
                <></>
              )}
            </div>
          </dl>
        </div>
        <div className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg dark:bg-transparent"></div>
      </div>
    </section>
  );
}
