import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

const PaginationComponent = ({ currentPage, totalPages, handlePageChange }: {
    currentPage: number;
    totalPages: number;
    handlePageChange: (pageNumber: number) => void
}) => {

    const [pageNumber, setPageNumber] = useState<(string | number)[]>([]);

    useEffect(() => {
        let compressedPageNumbers = [];
        const dots = "...";
        if (totalPages <= 5)
            for (let i = 1; i <= totalPages; i++) compressedPageNumbers.push(i);
        else {
            if (currentPage < 5)
                compressedPageNumbers = [1, 2, 3, 4, 5, dots, totalPages];
            else if (currentPage > totalPages - 5)
                compressedPageNumbers = [
                    1,
                    dots,
                    totalPages - 4,
                    totalPages - 3,
                    totalPages - 2,
                    totalPages - 1,
                    totalPages,
                ];
            else
                compressedPageNumbers = [
                    1,
                    dots,
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    dots,
                    totalPages,
                ];
        }
        let filteredArr = [];
        for (let i = 0; i < compressedPageNumbers.length; i++) {
            if (compressedPageNumbers[i] === "...") {
                // Check if the previous and next elements have a difference of 1
                if (i > 0 && i < compressedPageNumbers.length - 1 && Number(compressedPageNumbers[i - 1]) + 1 === compressedPageNumbers[i + 1]) {
                    continue;  // Skip adding the dots
                }
            }
            filteredArr.push(compressedPageNumbers[i]);
        }
        setPageNumber(filteredArr);
    }, [currentPage, totalPages]);


    return pageNumber.length > 1 ? (
        <nav
            className="flex items-center justify-between border-t border-gray-300 px-4 pt-0 sm:px-0 dark:border-gray-600 py-2"
            aria-label="Pagination"
        >
            <div className="px-10 flex w-0 flex-1 cursor-pointer">
                {currentPage > 1 && (
                    <span
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:hover:text-gray-400"
                        onClick={() => {
                            handlePageChange(currentPage - 1);
                        }}
                    >
                        <ArrowLongLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        Previous
                    </span>
                )}
            </div>
            <div className="hidden md:-mt-px md:flex">
                {pageNumber.map((page, index) => {
                    return typeof page === "number" ? (
                        <div
                            key={index}
                            className={`${currentPage === page
                                ? "border-entntblue text-entntblue hover:border-blue-400 hover:text-blue-400"
                                : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 dark:hover:text-gray-300"
                                } cursor-pointer inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium `}
                            onClick={() => {
                                handlePageChange(page);
                            }}
                        >
                            {page}
                        </div>
                    ) : (
                        <span
                            className="font-medium flex justify-center items-end pt-4 text-gray-500 text-sm px-3 dark:hover:text-gray-300 cursor-pointer"
                            key={index}
                        >
                            {page}
                        </span>
                    );
                })}
            </div>
            <div className="px-10 flex w-0 flex-1 justify-end cursor-pointer">
                {currentPage < totalPages && (
                    <span
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500  hover:text-gray-700 dark:hover:text-gray-400"
                        onClick={() => {
                            handlePageChange(currentPage + 1);
                        }}
                    >
                        Next
                        <ArrowLongRightIcon
                            className="ml-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                )}
            </div>
        </nav>
    ) : null;
};

export default PaginationComponent;
