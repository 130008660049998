import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useRef } from "react";
import {
  TimelineGap,
  TimelineCandidateEducation,
  TimelineCandidateExperience,
} from "../../Models";
import { getStyleForCompany, getStyleForUniversity } from "../../Helpers";

type TimelineItem =
  | TimelineCandidateEducation
  | TimelineCandidateExperience
  | TimelineGap;

interface TimelineType {
  timeline: TimelineItem[];
  isMobile?: Boolean;
}

am4core.useTheme(am4themes_animated);

export default function SerpentineTimeline({
  timeline,
  isMobile = false,
}: TimelineType) {
  const chartRef = useRef<am4charts.XYChart | null>(null);
  var level = 1;

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4plugins_timeline.SerpentineChart);
    chartRef.current = chart;
    if (isMobile) {
      chart.curveContainer.padding(0, 0, 0, 0);
    } else chart.curveContainer.padding(50, 0, 50, 0);
    chart.yAxisRadius = am4core.percent(25);
    chart.yAxisInnerRadius = am4core.percent(-25);
    chart.maskBullets = false;
    chart.orientation = isMobile ? "horizontal" : "vertical";

    let colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.5;

    chart.data = timeline.flatMap(
      (
        timelineData:
          | TimelineCandidateEducation
          | TimelineCandidateExperience
          | TimelineGap
      ) => {
        let data = [
          {
            category:
              "company" in timelineData && timelineData.company ? "Exp" : "Edu",
            start: new Date(timelineData.startDate),
            end: new Date(timelineData.endDate),
            color:
              "company" in timelineData && timelineData.company
                ? getStyleForCompany(timelineData.company.ranking).background
                : "university" in timelineData && timelineData.university
                  ? timelineData.postGrad
                    ? getStyleForUniversity(
                        timelineData.university.ranking,
                        true
                      ).background
                    : getStyleForUniversity(
                        timelineData.university.ranking,
                        false
                      ).background
                  : "#6B7280",
            name:
              "company" in timelineData && timelineData.company
                ? timelineData.company.name
                : "university" in timelineData && timelineData.university
                  ? timelineData.university.name
                  : "Gap",
            label: timelineData.totalMonths,
          },
        ];

        if (
          "university" in timelineData &&
          timelineData.overlappingExperience
        ) {
          timelineData.overlappingExperience.forEach((overlap) => {
            if (overlap.company) {
              data.push({
                category: "Exp",
                start: new Date(overlap.startDate),
                end: new Date(overlap.endDate),
                color: getStyleForCompany(overlap.company.ranking).background,
                name: overlap.company?.name,
                label: overlap.totalMonths,
              });
            }
          });
        }

        return data;
      }
    );

    level = Math.floor(chart.data.length / 3) + 1;
    chart.levelCount = level;
    const chartDiv = document.getElementById("chartdiv");
    if (chartDiv) {
      if (isMobile) chartDiv.style.height = "750px";
      else chartDiv.style.height = chart.levelCount * 120 + "px";
    }
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.fontSize = 11;
    // chart.orientation = "horizontal";

    let categoryAxis = chart.yAxes.push(
      new am4charts.CategoryAxis<am4plugins_timeline.AxisRendererCurveY>()
    );
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer = new am4plugins_timeline.AxisRendererCurveY();
    categoryAxis.renderer.grid.template.disabled = false;
    // categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.innerRadius = -60;
    categoryAxis.renderer.radius = 60;
    if (isMobile) {
      categoryAxis.renderer.labels.template.rotation = 90;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
    }

    let dateAxis = chart.xAxes.push(
      new am4charts.DateAxis<am4plugins_timeline.AxisRendererCurveX>()
    );
    dateAxis.renderer = new am4plugins_timeline.AxisRendererCurveX();
    dateAxis.renderer.minGridDistance = isMobile ? 30 : 50;
    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    dateAxis.renderer.tooltipLocation = 0;
    // dateAxis.renderer.axis.disabled = true;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.startLocation = -0.1;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.labels.template.fontSize = 8;
    dateAxis.renderer.line.strokeOpacity = 0;
    dateAxis.tooltip!.background.fillOpacity = 0.2;
    dateAxis.tooltip!.background.cornerRadius = 5;
    // dateAxis.renderer.labels.template.disabled = true;
    dateAxis.tooltip!.label.fill = new am4core.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    dateAxis.tooltip!.label.paddingTop = 7;

    let labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.7;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    labelTemplate.background.fillOpacity = 0;
    labelTemplate.padding(7, 7, 7, 7);

    let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
    series.columns.template.tooltipText =
      "{name}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 1;
    series.columns.template.strokeWidth = isMobile ? 6 : 8;
    series.columns.template.height = 1;
    series.sequencedInterpolation = false;

    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = isMobile ? 6 : 10;
    bullet.circle.strokeOpacity = 0;
    bullet.propertyFields.fill = "color";
    bullet.locationX = 0;

    let bullet2 = series.bullets.push(new am4charts.CircleBullet());
    bullet2.circle.radius = isMobile ? 6 : 10;
    bullet2.circle.strokeOpacity = 0;
    bullet2.propertyFields.fill = "color";
    bullet2.locationX = 1;

    let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.locationX = 0.5;
    circleBullet.locationY = 0.5;
    circleBullet.circle.radius = isMobile ? 10 : 15;
    circleBullet.circle.stroke = am4core.color("white");
    circleBullet.propertyFields.fill = "color";

    let label = circleBullet.createChild(am4core.Label);
    label.propertyFields.text = "label";
    label.fontSize = isMobile ? 10 : 12;
    label.fontWeight = "100";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.stroke = am4core.color("#FFFFFF");

    let aboveLabel = circleBullet.createChild(am4core.Label);
    aboveLabel.propertyFields.text = "name";
    aboveLabel.fontSize = isMobile ? 10 : 10;
    aboveLabel.fontWeight = "100";
    aboveLabel.horizontalCenter = "middle";
    aboveLabel.verticalCenter = "bottom";
    aboveLabel.dy = -25;
    aboveLabel.stroke = am4core.color("#464a52");
    aboveLabel.fillOpacity = 1;

    aboveLabel.background.fillOpacity = 1;
    aboveLabel.background.strokeOpacity = 0;
    aboveLabel.padding(3, 5, 3, 5);

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis.cursorTooltipEnabled = false;
    return () => {
      chart.dispose();
    };
  }, [timeline, isMobile]);

  return <div id="chartdiv" style={{ width: "100%" }}></div>;
}
