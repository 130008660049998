import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { ArrowUturnLeftIcon, CheckIcon, ChevronDownIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { addQuestionsToAssessment, deleteAudioQuestion, fetchQuestionGroups, postAssessment, postAudioQuestion, postCandidateSpecificQuestionnaire, postQuestion } from '../../../WebCalls';
import { AssessmentQuestion, QuestionGroup } from '../../../Models';
import DropdownQuestionGroup from '../Dropdowns/DropdownQuestionGroup';
import { blobToBase64, formats, toolbar } from '../../../helpers/constants';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';

interface SavedRecording {
    questionId: string;
    recording: string;
}

interface AssessmentQuestionMapping {
    questionId: string;
    questionnaireId: string;
}


export default function AddCandidateAudioQuestionModal({ title, content, size, onAdd, onCancel, isModalClosed, selectedCandidateIds }: { title: string, content: string, size: string, onAdd: Function, onCancel: Function, isModalClosed: Function, selectedCandidateIds: string[] }) {
    const [open, setOpen] = useState(true);
    const cancelButtonRef = useRef(null);
    const [pageNo, setPageNo] = useState<number>(1);
    useEffect(() => {
        if (open === false) {
            isModalClosed();
        }
    }, [open, isModalClosed]);

    const { roleId } = useParams();
    const [latestRecording, setLatestRecording] = useState(null);
    const [allSavedRecordings, setAllSavedRecordings] = useState<SavedRecording[]>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [questionnaireData, setQuestionnaireData] = useState({
        id: "00000000-0000-0000-0000-000000000000",
        title: "",
        description: "",
        numOfQuestions: "",
        lastUpdatedDate: new Date()
    });

    const [questionGroups, setQuestionGroup] = useState<QuestionGroup[]>([]);
    const [selectedQGroup, setSelectedQGroup] = useState<QuestionGroup | undefined>(undefined)
    const [currentQuestionData, setCurrentQuestionData] = useState<AssessmentQuestion>({
        id: "",
        text: "",
        type: "audio",
        tags: "",
    });
    const [addedAudioElements, setAddedAudioElements] = useState<HTMLAudioElement[]>([]);

    const [addedQuestions, setAddedQuestions] = useState<AssessmentQuestionMapping[]>([]);
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        fetchQuestionGroups().then((res) => setQuestionGroup(res));
    }, []);




    const addAudioElement = (blob: any) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
        setLatestRecording(blob);
        setAddedAudioElements((prevElements) => [...prevElements, audio]);
    };

    const saveAudioRec = async () => {
        if (latestRecording) {
            // post recording
            if (selectedQGroup) {
                const res = await postQuestion(currentQuestionData.text, '', currentQuestionData.tags, selectedQGroup.id, "audio", undefined)
                const questionId = res?.id;
                setAllSavedRecordings((prevRecordings) => ([
                    ...prevRecordings,
                    { questionId: questionId, recording: latestRecording }
                ]));
                // setAddedQuestionIds((prev) => ([...prev, res.id]))

                setAddedQuestions((prev) => ([...prev, { questionId: questionId, questionnaireId: questionnaireData.id }]));
                var url = URL.createObjectURL(latestRecording);
                const response = await fetch(url);

                const blob = await response.blob();
                const base64String = await blobToBase64(blob);
                await postAudioQuestion(questionId, base64String);

                setCurrentQuestionData({
                    id: "",
                    text: "",
                    type: "audio",
                    tags: "",
                })
            }

        }
        setLatestRecording(null);
    }

    const resetAudioRec = () => {
        setLatestRecording(null);
    }
    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
        setDeleteConfirm(false);
    };

    const confirmDeleteRecording = async (questionId: string) => {
        await deleteAudioQuestion(questionId);
        const tempRecordings = allSavedRecordings.filter((r) => {
            return r.questionId !== questionId;
        });

        const tempAddedQuestions = addedQuestions.filter((q) => {
            return q.questionId !== questionId;
        });

        setAddedQuestions(tempAddedQuestions);
        setAllSavedRecordings(tempRecordings);
    }

    const postQuestionnaire = async () => {
        if (questionnaireData.title && questionnaireData.description && questionnaireData.numOfQuestions
            && questionnaireData.id === "00000000-0000-0000-0000-000000000000") {
            const res: any = await postAssessment(questionnaireData);

            setQuestionnaireData(res);
        }
    }

    const setSelectedQuestionGroup = (selectedQuestionGroup: QuestionGroup | undefined) => {
        setSelectedQGroup(selectedQuestionGroup);
    }

    const addCandidateSpecificQuestionnaire = async () => {
        if (questionnaireData?.id !== "" && roleId) {
            await postCandidateSpecificQuestionnaire(selectedCandidateIds, questionnaireData?.id, roleId)
            await addQuestionsToAssessment(addedQuestions);
            addedAudioElements.forEach(audio => {
                document.body.removeChild(audio);
            });
            setAddedAudioElements([]);
            setDeleteConfirm(false);
        }
    }

    const sendCandidatesEmail = async () => {
        setQuestionnaireData({
            id: "00000000-0000-0000-0000-000000000000",
            title: "",
            description: "",
            numOfQuestions: "",
            lastUpdatedDate: new Date()
        })
        onAdd();

    }

    return (
        <Transition.Root show={open} as={Fragment} >
            <Dialog
                as="div"
                className="relative z-10 "
                initialFocus={cancelButtonRef}
                onClose={() => {
                    onCancel();
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full h-[31rem]  ${size === 'small' ? 'sm:max-w-sm' : size === 'large' ? 'sm:max-w-4xl' : 'sm:max-w-lg'
                                    }`}
                            >
                                <div className='bg-gray-300 h-12 p-4 mb-4 flex items-center'>
                                    <h2 className='font-bold text-gray-600  text-sm text-left'>
                                        {pageNo === 1 ? "Create Questionnaire" : pageNo === 2 ? "Record Audio Questions" : "Email Template"}
                                    </h2>
                                </div>
                                {pageNo === 1 && <>
                                    <div className="p-4">
                                        <div className="flex flex-col gap-2 dark:border-gray-200">
                                            <div className='gap-1 flex flex-col '>
                                                <label htmlFor="assessment-title" className='ml-1 font-semibold text-sm'>Title:</label>
                                                <input id="assessment-title" name="assessment-title" type="text" className="w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm" placeholder="Title" value={questionnaireData.title}
                                                    onChange={(e) => {
                                                        setQuestionnaireData((prev) => ({
                                                            ...prev, title: e.target.value
                                                        }))
                                                    }}
                                                />
                                            </div>
                                            <div className='gap-1 flex flex-col '>
                                                <label htmlFor="assessment-description" className='ml-1 font-semibold text-sm'>Description:</label>
                                                <textarea id="assessment-description" name="assessment-description" className="w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 h-40 text-sm" placeholder="Description"
                                                    value={questionnaireData.description}
                                                    onChange={(e) => {
                                                        setQuestionnaireData((prev) => ({
                                                            ...prev, description: e.target.value
                                                        }))
                                                    }}
                                                />
                                            </div>
                                            <div className='gap-1 flex flex-col '>
                                                <label htmlFor="assessment-questions" className='ml-1 font-semibold text-sm'>No. of Questions:</label>
                                                <input id="assessment-questions" name="assessment-questions" type="number" className="w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm" placeholder="No. of Questions"
                                                    min={0}
                                                    value={questionnaireData.numOfQuestions}
                                                    onChange={(e) => {
                                                        setQuestionnaireData((prev) => ({
                                                            ...prev, numOfQuestions: e.target.value
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>}
                                {pageNo === 2 && <div>
                                    <div className='flex flex-row-reverse '>
                                        <DropdownQuestionGroup group={questionGroups} setSelectedQuestionGroup={setSelectedQuestionGroup} type='modal' selectedQGroup={selectedQGroup} />
                                    </div>

                                    <div className="flex items-center justify-center p-4">
                                        <div className="w-1/2 flex justify-center border-r-2 p-2 h-[18rem]">
                                            <div className=" text-center  sm:text-left flex flex-col gap-6 m-auto ">
                                                {!selectedQGroup &&
                                                    <div className='text-xs text-blue-700'>
                                                        Please select a question group to start recording
                                                    </div>}
                                                {!latestRecording && selectedQGroup && (
                                                    <div className='flex flex-col justify-center items-center gap-5'>
                                                        <div>
                                                            <AudioRecorder
                                                                onRecordingComplete={addAudioElement}
                                                                audioTrackConstraints={{
                                                                    noiseSuppression: true,
                                                                    echoCancellation: true,
                                                                }}
                                                                onNotAllowedOrFound={(err: any) => console.table(err)}
                                                                mediaRecorderOptions={{
                                                                    audioBitsPerSecond: 128000,
                                                                }}
                                                                showVisualizer={true}
                                                            />
                                                        </div>
                                                        {/* <div>
                                                            <input
                                                                max={9999}
                                                                type="number"
                                                                name=""
                                                                id=""
                                                                className='sm:w-36 md:w-56 lg:w-96    rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm'
                                                                placeholder='Time'
                                                                value={currentQuestionData.time} onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (value === "" || (parseInt(value) < 9999 && parseInt(value) >= 0)) {
                                                                        setCurrentQuestionData((prev) => ({ ...prev, time: (e.target.value) }))
                                                                    }
                                                                }}
                                                            />
                                                        </div> */}
                                                        <div>
                                                            <textarea
                                                                name=""
                                                                id=""
                                                                className='sm:w-36 md:w-56 lg:w-96 rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm h-32'
                                                                placeholder='Question Text'
                                                                value={currentQuestionData.text} onChange={(e) => setCurrentQuestionData((prev) => ({ ...prev, text: e.target.value }))}
                                                            />

                                                        </div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name=""
                                                                id=""
                                                                className='sm:w-36 md:w-56 lg:w-96 rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm'
                                                                placeholder='Tags'
                                                                value={currentQuestionData.tags} onChange={(e) => setCurrentQuestionData((prev) => ({ ...prev, tags: e.target.value }))}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {latestRecording && (
                                                    <div className='flex flex-col gap-5 items-center'>
                                                        <audio controls src={URL.createObjectURL(latestRecording)} />
                                                        <textarea
                                                            name=""
                                                            id=""
                                                            className='sm:w-36 md:w-56 lg:w-96 rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm h-20'
                                                            placeholder='Question Text'
                                                            value={currentQuestionData.text} onChange={(e) => setCurrentQuestionData((prev) => ({ ...prev, text: e.target.value }))}
                                                        />
                                                        <input type="text" name="" id="" className='w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm' placeholder='Tags' value={currentQuestionData.tags} onChange={(e) => setCurrentQuestionData((prev) => ({ ...prev, tags: e.target.value }))} />
                                                        <div className=''>
                                                            <button className={`inline-flex items-center justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-gray-600 m-2`} disabled={!(currentQuestionData.tags.trim() && currentQuestionData.text.trim())} onClick={saveAudioRec}>Save</button>
                                                            <button className='inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 m-2' onClick={resetAudioRec}>Reset</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className="w-1/2  flex justify-start flex-col p-2 gap-4 h-[18rem]">
                                            {allSavedRecordings.map((savedRecording: any, index) => (
                                                <div key={index}>
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => toggleAccordion(index)}
                                                    >
                                                        <div className='flex flex-row justify-between items-center bg-gray-200 p-2 rounded-lg' >
                                                            <div className='text-sm font-semibold'>Audio Recording {index + 1}</div>
                                                            <div>
                                                                <ChevronDownIcon height={20} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {activeIndex === index && (
                                                        <div className='flex items-center justify-center'>
                                                            <audio controls src={URL.createObjectURL(savedRecording.recording)} className='m-2' />
                                                            <button>
                                                                <TrashIcon height={20} color='red' onClick={() => setDeleteConfirm(true)} />
                                                            </button>
                                                            {deleteConfirm &&
                                                                <div>
                                                                    <div className='flex  px-5 py-2.5 '>
                                                                        <CheckIcon height={20} className='cursor-pointer border-0 border-r-2 border-gray-400 pr-2' onClick={() => confirmDeleteRecording(savedRecording.questionId)} color='red' />
                                                                        <XMarkIcon height={20} className='cursor-pointer pl-2 dark:text-blue-400' onClick={() => setDeleteConfirm(false)} />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                }
                                {pageNo === 3 && <>
                                    <div className="p-4">
                                        <div className="flex flex-col gap-2 dark:border-gray-200">

                                            {/* <div className='gap-1 flex flex-col '>
                                                <label htmlFor="assessment-time" className='ml-1 font-semibold text-sm'>Time (in minuites):</label>
                                                <input max={9999} id="assessment-time" name="assessment-time" type="number" className="w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 text-sm" placeholder="Time" value={questionnaireData.time}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === "" || (parseInt(value) < 9999 && parseInt(value) >= 0)) {
                                                            setQuestionnaireData((prev) => ({
                                                                ...prev, time: e.target.value
                                                            }))
                                                        }
                                                    }} />
                                            </div> */}
                                            <div className='gap-1 flex flex-col '>
                                                <label htmlFor="assessment-questions" className='ml-1 font-semibold text-sm'>&nbsp; Email : </label>
                                                {/* <textarea id="assessment-questions" name="assessment-questions" className="w-full rounded-md focus:outline-none focus:ring-1 focus:border-indigo-500 dark:bg-transparent dark:border-gray-600 h-[19rem] text-sm" placeholder="Email"
                                                /> */}
                                                <ReactQuill
                                                    value={email}
                                                    onChange={setEmail}
                                                    id="assessment-questions"
                                                    formats={formats}
                                                    modules={{
                                                        toolbar: {
                                                            container: toolbar,
                                                        }
                                                    }}
                                                    className='w-full rounded-md p-2 focus:outline-none focus:ring focus:border-indigo-500 h-[19rem] text-left pb-6 dark:text-black dark:border-gray-600'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                <div className="bg-gray-50 px-4 py-3 sm:px-6">
                                    <div>
                                        {pageNo === 1 ? <div className='flex flex-row-reverse'>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setPageNo(2);
                                                    // setOpen(false);
                                                    postQuestionnaire()
                                                }}
                                                disabled={!(questionnaireData.title.trim() && questionnaireData.description.trim() && questionnaireData.numOfQuestions)}
                                                className={`inline-flex w-full justify-center rounded-md ${(questionnaireData.title.trim() && questionnaireData.description.trim() && questionnaireData.numOfQuestions) ? "bg-blue-600 hover:bg-blue-500" : "bg-gray-600"} px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto`}
                                            >
                                                Next
                                            </button>
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        onCancel();
                                                        setOpen(false);
                                                    }}
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    ref={cancelButtonRef}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                            : pageNo === 2 ?
                                                <div className='flex flex-row justify-between'>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setPageNo(1);
                                                        }}
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                    >
                                                        <ArrowUturnLeftIcon height={20} className='mr-2' />
                                                        Back
                                                    </button>

                                                    <div>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                onCancel();
                                                                setOpen(false);
                                                            }}
                                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                            ref={cancelButtonRef}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                addCandidateSpecificQuestionnaire();
                                                                // setPageNo(3);
                                                                onAdd();
                                                                setOpen(false);
                                                            }}
                                                            disabled={!(addedQuestions.length > 0)}
                                                            className={`inline-flex w-full justify-center rounded-md ${(addedQuestions.length > 0) ? "bg-blue-600 hover:bg-blue-500" : "bg-gray-600"} px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto`}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div> : <div className='flex flex-row justify-end'>

                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            sendCandidatesEmail();
                                                            setOpen(false);
                                                        }}
                                                        disabled={!(email.length > 0)}
                                                        className={`inline-flex w-full justify-center rounded-md ${(email.length > 0) ? "bg-blue-600 hover:bg-blue-500" : "bg-gray-600"} px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto`}
                                                    >
                                                        Send Email
                                                    </button>
                                                </div>}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}