import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { postQuestionGroup } from '../../WebCalls';
import Notification from '../Shared/Notification';

function AddQuestionGroup() {

    const [title, setTitle] = useState<string>('');
    const [successtNotification, setSuccessNotification] = useState(false);
    const navigate = useNavigate();

    const handleCreateGroup = async (e: any) => {
        e.preventDefault();
        try {
            await postQuestionGroup(title);
            setSuccessNotification(true);
            setTimeout(() => {
                navigate('/questions')
            }, 2000)
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <header className="bg-gray-100 py-8 dark:bg-darkbglight">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <Link
                                            to="/questions"
                                            className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                        >
                                            Questions
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                                            Add Question Group
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <div className="min-w-0 flex-1">
                            <div className='flex items-center justify-between mb-4'>
                                <h1 className="mt-2 text-3xl font-bold leading-7 text-gray-900 sm:text-4xl sm:tracking-tight dark:text-gray-300">
                                    Create Question Group
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div>
                <form className="container mx-auto px-4 py-8">
                    <div className=" p-6 ">

                        <div className='flex items-start py-14 border-b border-gray-300 dark:border-gray-600'>
                            <label htmlFor="questionText" className="block text-sm font-medium text-gray-700 w-64 text-left dark:text-gray-400">
                                Title: *
                            </label>
                            <textarea
                                id="questionText"
                                name="questionText"
                                rows={3}
                                className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 dark:bg-transparent dark:text-gray-300 dark:focus:border-0"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>

                    </div>
                    <div className="mt-8 flex justify-end">
                        <button
                            type="submit"
                            onClick={handleCreateGroup}
                            className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring focus:border-indigo-500 ${title
                                ? 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                            disabled={!(title.length > 0)}
                        >
                            Create
                        </button>

                    </div>
                </form>
            </div>
            <Notification
                show={successtNotification}
                setShow={setSuccessNotification}
                title="Success"
                description="Question Group Created Successfully"
                type="success"
            />
        </div>
    )
}

export default AddQuestionGroup
