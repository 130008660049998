import { Candidate, RoleStatus } from "../../../Models";
import { CandidateAction } from "./RoleCandidates";
import { ArrowLongRightIcon } from "@heroicons/react/20/solid";
type Props = {
  candidatesWithAction: CandidateAction[];
  handleCandidateWithAction: (candidate: Candidate[]) => void;
  roleStatuses: RoleStatus[];
  setSelectedCandidate: React.Dispatch<React.SetStateAction<CandidateAction[]>>;
  bulkActionIsDisqualify: boolean;
};
export default function BulkActionModalDescription({
  candidatesWithAction,
  handleCandidateWithAction,
  roleStatuses,
  bulkActionIsDisqualify,
}: Props) {
  const actionIndex = bulkActionIsDisqualify ? 1 : 0;

  const handleToggleEmail = (id: string) => {
    const updatedCandidates = candidatesWithAction.map((sc) => {
      if (id === sc.id && sc.actions) {
        sc.actions[actionIndex] = {
          ...sc.actions[actionIndex],
          email: !sc.actions[actionIndex].email,
        };
      }
      return sc;
    });
    handleCandidateWithAction(updatedCandidates);
  };

  const getStatusName = (candidate: CandidateAction) => {
    if (!candidate.actions) return;
    const action = candidate.actions[actionIndex];
    const roleStatus = roleStatuses.find((rs) => rs.id === action.roleStatusId);
    return roleStatus ? roleStatus.name : "";
  };

  return (
    <>
      <div className="flex justify-between items-center space-x-4 text-gray-700 pr-3 py-[1px] mb-1">
        <span></span>
        <span className="font-medium text-sm">Email</span>
      </div>
      <ul className="h-fit max-h-[20rem] min-w-80 overflow-scroll thin-scroll py-1">
        {candidatesWithAction.map((sc) => (
          <li
            key={sc.id}
            className="flex justify-between items-center space-x-4 text-gray-700 pr-3 py-[1px] "
          >
            {sc.actions && (
              <>
                <span className="flex items-end text-sm">
                  {`${sc.firstName} ${sc.lastName}`}
                  <ArrowLongRightIcon className="h-5 px-2" />
                  {getStatusName(sc)}
                </span>
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={sc.actions[actionIndex].email}
                  onChange={() => {
                    handleToggleEmail(sc.id);
                  }}
                />
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
