import React, { useEffect, useState } from 'react';
import { Question } from '../../Models';
import { getQuestionById, putAssessmentQuestion } from '../../WebCalls';
import ReactQuill from 'react-quill';
import { formats, toolbar } from '../../helpers/constants';
import DropdownQuestionType from '../Shared/Dropdowns/DropDownQuestionType';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import Notification from './Notification';
import { useNavigate } from 'react-router-dom';


function QuestionSidebar({ questionId, resetEditPanel }: { questionId: string, resetEditPanel: Function }) {

    const [questionDetails, setQuestionDetails] = useState<any>({})
    const [selectedQType, setSelectedQType] = useState<Question>({
        id: Math.random().toLocaleString(),
        title: 'single'
    });
    const [tempQuestionDetails, setTempQuestionDetails] = useState<any>();
    const [successtNotification, setSuccessNotification] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (questionId) {
            getQuestionById(questionId).then((res: any) => {
                setQuestionDetails(res);
                setTempQuestionDetails(res);
                const qType = res.type.toUpperCase();
                setSelectedQType({ id: Math.random().toLocaleString(), title: qType });
            });
        }
    }, [questionId])



    const handleTextChange = (e: string) => {
        if (tempQuestionDetails) {
            setTempQuestionDetails((prevState: any) => ({
                ...prevState,
                text: e
            }));
        }
    }

    const handleGoBack = () => {
        resetEditPanel();
    }

    const handleTagsChange = (e: any) => {
        if (tempQuestionDetails) {
            setTempQuestionDetails((prevState: any) => ({
                ...prevState,
                tags: e.target.value
            }));
        }
    }

    const handleTimeChange = (e: any) => {
        if (tempQuestionDetails) {
            setTempQuestionDetails((prevState: any) => ({
                ...prevState,
                time: e.target.value
            }));
        }
    }

    const setSelectedQuestionType = (selected: Question) => {
        setSelectedQType(selected);
        if (tempQuestionDetails) {
            setTempQuestionDetails((prevState: any) => ({
                ...prevState,
                type: selected.title
            }));
        }

    }

    const handleSaveAndUpdate = async () => {
        if (JSON.stringify(questionDetails) !== JSON.stringify(tempQuestionDetails)) {
            const { id, text, time, type, tags, questionGroup } = tempQuestionDetails;
            await putAssessmentQuestion(id, text, time, type, tags, questionGroup.id);
            setSuccessNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }

    }

    const checkDisabled = () => {
        if (!tempQuestionDetails) {
            return true;
        }
        if (JSON.stringify(questionDetails) === JSON.stringify(tempQuestionDetails)) {
            return true;
        }
        const { text, time, type, tags } = tempQuestionDetails;

        if (!text || !time || !type || !tags) {
            return true;
        }

        return false;
    }

    const handleNavigate = async () => {
        if (!checkDisabled()) {
            const { id, text, time, type, tags, questionGroup } = tempQuestionDetails;
            await putAssessmentQuestion(id, text, time, type, tags, questionGroup.id);
        }
        navigate(`/questions/edit/${questionId}`);

    }

    return (
        <div
            className={`fixed top-0 right-0 h-full lg:w-2/5 md:w-1/2 sm:w-full bg-white shadow-sm overflow-y-auto z-50  p-10 border border-gray-200 transition-transform rounded-tl-3xl rounded-bl-3xl duration-300 transform dark:bg-darkbglight dark:border-gray-500 ${questionId.length > 0 ? "translate-x-0 " : "translate-x-full"}`}
        >
            <div className='flex mb-4 '>
                <button className=' inline-flex items-center gap-x-1.5 rounded-md bg-white  px-5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50' onClick={() => { handleGoBack() }}>
                    <ArrowUturnLeftIcon height={15} />
                    &nbsp;
                    Go Back
                </button>

            </div>
            <h2 className='text-2xl font-bold text-center dark:text-gray-300'>Edit Question</h2>
            <div>

                <div>
                    <div className='flex items-start border-b border-gray-300 py-10 lg:h-96 sm:h-fit '>
                        <label htmlFor="questionText" className="block text-sm font-medium text-gray-700  text-left px-2 dark:text-gray-400">
                            Text:
                        </label>
                        <ReactQuill
                            value={tempQuestionDetails?.text}
                            onChange={handleTextChange}
                            formats={formats}
                            modules={{
                                toolbar: {
                                    container: toolbar,
                                }
                            }}
                            className='w-full !rounded-lg lg:h-[calc(100%-5rem)] sm:h-fit focus:outline-none focus:ring focus:border-indigo-500 text-left bg-white mx-2 dark:bg-transparent dark:text-gray-300'
                            id="questionText"
                        />
                    </div>
                    <div className='flex flex-col items-center py-10 border-b border-gray-300 lg:flex-row gap-2'>
                        <label htmlFor="" className="  block text-sm font-medium text-gray-700  text-left px-2 dark:text-gray-400" >
                            Type:
                        </label>
                        <div className='mx-2 px-2'>
                            <DropdownQuestionType setSelectedQuestionType={setSelectedQuestionType} selectedQType={selectedQType} />
                        </div>
                    </div>
                    <div className='flex flex-col items-center py-10 border-b border-gray-300 lg:flex-row gap-2'>
                        <label htmlFor="questionTags" className="block text-sm font-medium text-gray-700  text-left px-2 dark:text-gray-400">
                            Tags:
                        </label>
                        <input
                            id="questionTags"
                            name="questionTags"
                            type="text"
                            className="w-full  rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 mx-2 min-w-20 dark:bg-transparent dark:text-gray-300"
                            value={tempQuestionDetails?.tags}
                            onChange={(e) => handleTagsChange(e)}

                        />
                        <label htmlFor="questionTime" className="  block text-sm font-medium text-gray-700  text-left dark:text-gray-400">
                            Time:
                        </label>
                        <input
                            id="questionTime"
                            name="questionTime"
                            type="number"
                            className="w-full rounded-md border-gray-300 border p-2 focus:outline-none focus:ring focus:border-indigo-500 mx-2  min-w-20 dark:bg-transparent dark:text-gray-300"
                            value={tempQuestionDetails?.time}
                            onChange={handleTimeChange}

                        />
                    </div>
                    <div className='flex items-center justify-between pt-10'>
                        <div>
                            <button className='text-black border-2 b-black bg-white-700 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2  dark:bg-gray-600 dark:hover:bg-gray-100 focus:outline-none dark:focus:ring-gray-800' onClick={() => handleNavigate()}>Edit Options</button>
                        </div>
                        <div>
                            <button className='text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800' onClick={() => resetEditPanel()}>Cancel</button>
                            <button
                                className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 ${checkDisabled() ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700'
                                    }`}
                                disabled={checkDisabled()}
                                onClick={handleSaveAndUpdate}
                            >
                                Save and Update
                            </button>
                        </div>

                    </div>

                </div>
            </div>
            <Notification
                show={successtNotification}
                setShow={setSuccessNotification}
                title="Success"
                description="Question Updated Successfully"
                type="success"
            />
        </div>
    );
}

export default QuestionSidebar;
