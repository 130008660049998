import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { QuarantineCompany, QuarantineUniversity } from "../../../Models";
import SearchDropDown from "./searchDropDown";

type props = {
  type: "info" | "danger";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  title: string;
  description: string;
  dropDownPlaceHolder:string;
  dropDownCurrentValue:string|undefined;
  dropDownSetCurrentValue:(uId: string) => void;
  dropDownValues: QuarantineCompany[] | QuarantineUniversity[];
  hideOthers?:boolean;
  selected?:string;
  rejectQuery:string;
  setRejectQuery:(uId: string) => void;
};

export default function ConfirmModal(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";
  const [rejectWithoutSelecting, setRejectWithoutSelecting] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    props.dropDownSetCurrentValue('00000000-0000-0000-0000-000000000000');
    setRejectWithoutSelecting(!rejectWithoutSelecting);
  };

  
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.setRejectQuery("");
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <div className="bg-white px-4 pb-4 pt-5 rounded-lg sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                        danger ? "bg-red-100" : "bg-blue-100"
                      } sm:mx-0 sm:h-10 sm:w-10`}
                    >
                      <ExclamationTriangleIcon
                        className={`h-6 w-6 ${
                          danger ? "text-red-600" : "text-blue-600"
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {props.description}
                        </p>
                      </div>
                      <SearchDropDown
                       placeHolder={props.dropDownPlaceHolder}
                       currentValue={props.dropDownCurrentValue}
                       setCurrentValue={props.dropDownSetCurrentValue}
                       dropDownValues={props.dropDownValues}
                       hideOthers={props.hideOthers}
                       currentTab={props.selected}
                       disable={rejectWithoutSelecting}
                       query={props.rejectQuery}
                       setQuery={props.setRejectQuery}
                      />
                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                            checked={rejectWithoutSelecting}
                            onChange={handleCheckboxChange}
                          />
                          <span className="ml-2 text-sm">Reject without selecting</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 rounded-lg sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`disabled:hover:bg-red-600 inline-flex w-full justify-center rounded-md ${
                      danger ? "bg-red-600" : "bg-blue-600"
                    } px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                      danger ? "hover:bg-red-500" : "hover:bg-entntorange"
                    } sm:ml-3 sm:w-auto ${
                      !rejectWithoutSelecting && typeof props.dropDownCurrentValue === "string"
                        ? "bg-gray-700 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={props.onConfirm}
                    disabled={!rejectWithoutSelecting && typeof(props.dropDownCurrentValue)==="string"}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      props.setRejectQuery("");
                      props.setOpen(false)
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
