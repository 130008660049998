import {
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import {
  getCompaniesByRankType,
  getUniversitiesByRankType,
} from "../../../../WebCalls";
import { CategoryFull, Company, UniversityCompany } from "../../../../Models";

export default function RankingSamples({
  tab,
  rankTypeId,
  currentCategory,
  setCategory,
}: {
  tab: string;
  rankTypeId: string;
  currentCategory: CategoryFull;
  setCategory: React.Dispatch<React.SetStateAction<CategoryFull[]>>;
}) {
  const [searchText, setSearchText] = useState("");
  const [sampleList, setSampleList] = useState<Company[] | UniversityCompany[]>([]);
  const [currentData, setCurrentData] = useState<Company[] | UniversityCompany[]>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!currentCategory.samples) return;
    const list = JSON.parse(currentCategory.samples) as
      | Company[]
      | UniversityCompany[];
    setSampleList(list);
  }, [currentCategory]);

  useEffect(() => {
    if (searchText.length < 3) {
      setCurrentData(undefined);
      return;
    }
    const fetchDataByRankTypeId = async (id: string) => {
      let data;
      if (tab === "Companies") {
        data = await getCompaniesByRankType(id, 1, 15, "", false, searchText);
      } else {
        data = await getUniversitiesByRankType(
          id,
          1,
          15,
          "",
          false,
          searchText
        );
      }
      setCurrentData(data.records);
    };
    fetchDataByRankTypeId(rankTypeId);
  }, [rankTypeId, tab, searchText]);

  useEffect(() => {
    setCategory((prev) => {
      const updatedCategory = [...prev];
      const cur = updatedCategory.find((uc) => uc.id === currentCategory.id);
      if (cur) cur.samples = JSON.stringify(sampleList);
      return updatedCategory;
    });
  }, [sampleList, currentCategory, setCategory]);

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const delayedSearch = debounce((query: string) => {
    setSearchText(query);
  }, 1000);

  const handleAddSample = (cd: Company | UniversityCompany) => {
    const exist = sampleList.find((sl) => sl.id === cd.id);
    if (exist) return;
    const updatedSampleList = [...sampleList];
    updatedSampleList.push(cd);
    setSampleList(updatedSampleList);
  };

  const handleRemoveSample = (id: string) => {
    const filteredSamples = sampleList.filter((sl) => sl.id !== id);
    setSampleList(filteredSamples);
  };
  return (
    <div>
      <div className="p-2 pt-0 flex flex-wrap box-border">
        {sampleList &&
          sampleList.map((sl: Company | UniversityCompany) => (
            <span
              key={sl.id}
              className="flex items-center bg-blue-100 text-entntblue py-1 px-2 mr-1 mt-2 rounded-full relative text-xs w-20 truncate"
              title={sl.name}
            >
              <span className="truncate w-16">{sl.name}</span>
              <XMarkIcon
                onClick={() => {
                  handleRemoveSample(sl.id);
                }}
                className="h-3 w-3 cursor-pointer text-gray-600 ml-1 hover:text-gray-700"
              />
            </span>
          ))}
      </div>
      <div className="flex relative">
        <div className="flex flex-grow items-stretch focus-within:z-10 bg-gray-50 px-1">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 ">
            <MagnifyingGlassIcon
              className="h-4 w-4 text-gray-400 dark:text-gray-500"
              aria-hidden="true"
            />
          </div>
          <input
            ref={inputRef}
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-md border-0 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntorange sm:text-xs bg-transparent dark:text-gray-300 dark:placeholder:text-gray-500 py-1 placeholder:text-xs"
            placeholder="Add samples."
            onChange={(e) => {
              delayedSearch(e.target.value);
            }}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
            onClick={(e) => {
              if (inputRef.current) {
                inputRef.current.value = "";
              }
              setSearchText("");
            }}
          >
            <XMarkIcon
              className="h-4 w-4 text-gray-400 dark:text-gray-500"
              aria-hidden="true"
            />
          </div>
        </div>
        {currentData && (
          <div className="absolute bg-white text-xs w-[calc(100%-0.50rem)] border border-gray-200 rounded-md max-h-40 overflow-auto z-30 top-6 shadow-md left-[0.25rem]">
            {currentData.length === 0 ? (
              <div className="text-center py-1">
                No results found
              </div>
            ) : (
              currentData.map((cd) => (
                <li
                  key={cd.id}
                  className="px-4 cursor-pointer py-1 hover:bg-entntblue hover:text-white"
                  onClick={(e) => {
                    handleAddSample(cd);
                  }}
                >
                  {cd.name}
                </li>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}
