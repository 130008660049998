import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

export default function useApiToken() {
  const { instance } = useMsal();
  const [token, setToken] = useState<string>();
  useEffect(() => {
    const getToken = async () => {
      const currentAccount = instance.getActiveAccount();
      if (!currentAccount) return;
      const accessTokenRequest = {
        scopes: ["api://7da5a83f-58c1-4fdf-b5e4-bb00829c8ac1/Recruitment.Read"],
        account: currentAccount,
      };
      const accessToken = await instance.acquireTokenSilent(accessTokenRequest);
      setToken(accessToken.accessToken);
    };

    getToken();
  }, [instance]);
  return token;
}
