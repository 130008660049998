import { Link } from "react-router-dom";

const tabs = ["Companies", "Universities"];

interface Props {
  selectedRankType: { id: string; name: string };
  currentTab: string;
}

const RankingSideBar = ({ selectedRankType, currentTab }: Props) => {
  return (
    <>
      <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
        <div className="min-w-0 px-4 pt-2 pb-1">
          {selectedRankType && (
            <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
              <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
                {selectedRankType.name} Ranking
              </h3>
            </div>
          )}

          <div className="flex text-sm justify-between items-center py-1 my-1 mt-2 rounded-full bg-white border border-gray-200 relative dark:bg-darkbg dark:border-gray-500">
            <div
              className={`absolute bg-entntblue rounded-full top-[10%] left-[2%] h-[80%] w-[48%] z-0 transform transition-all ${
                currentTab === tabs[1] ? "translate-x-full" : "-translate-x-0"
              }`}
            ></div>
            <Link
              to={`/rankboard/companies${selectedRankType ? `?type=${selectedRankType.id}` : ""}`}
              className="z-10 flex-1 text-center"
            >
              <button
                type="button"
                className={`inline-flex items-center gap-x-1.5  bg-transparent px-3 py-1 text-sm ${
                  currentTab === tabs[0]
                    ? "text-gray-100"
                    : "text-gray-400 font-medium"
                }`}
              >
                Companies
              </button>
            </Link>
            <Link
              to={`/rankboard/universities${selectedRankType ? `?type=${selectedRankType.id}` : ""}`}
              className="flex-1 z-10 text-center"
            >
              <button
                type="button"
                className={`inline-flex items-center gap-x-1.5  bg-transparent px-3 py-1 text-sm ${
                  currentTab === tabs[1]
                    ? "text-gray-100"
                    : "text-gray-400 font-medium"
                }`}
              >
                Universities
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingSideBar;
