import React, { useState, useEffect } from "react";
import { GetAssignmentData, submitReview } from "../WebCalls";
import GitHubIcon from "@mui/icons-material/GitHub";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";

interface Candidate {
  firstName: string;
  lastName: string;
  assignmentLink: string;
  roleName: string;
  comments: string;
  candidateId: string;
  roleId: string;
}

const Assignments: React.FC = () => {
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [filteredCandidates, setFilteredCandidates] = useState<Candidate[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [filterRole, setFilterRole] = useState<string | null>("all");
  const [filterReviewed, setFilterReviewed] = useState<string | null>("all");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetAssignmentData();
        setCandidates(data);
        setFilteredCandidates(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleRoleFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedRole = event.target.value;
    Rolefilter(selectedRole);
  };

  const handleReviewedFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedReview = event.target.value;
    ReviewFilter(selectedReview);
  };

  const Rolefilter = (role: any) => {
    const selectedRole = role;
    setFilterRole(selectedRole);
    let filtered = candidates;

    if (selectedRole !== "all") {
      filtered = filtered.filter(
        (candidate) => candidate.roleName === selectedRole
      );
    }

    if (filterReviewed === "reviewed") {
      filtered = filtered.filter((candidate) => candidate.comments.length > 0);
    } else if (filterReviewed === "not-reviewed") {
      filtered = filtered.filter(
        (candidate) => candidate.comments.length === 0
      );
    }

    setFilteredCandidates(filtered);
  };

  const ReviewFilter = (reviewSelect: any) => {
    const selectedReview = reviewSelect;
    setFilterReviewed(selectedReview);
    let filtered = candidates;

    if (selectedReview === "reviewed") {
      filtered = filtered.filter((candidate) => candidate.comments.length > 0);
    } else if (selectedReview === "not-reviewed") {
      filtered = filtered.filter(
        (candidate) => candidate.comments.length === 0
      );
    }

    if (filterRole !== "all") {
      filtered = filtered.filter(
        (candidate) => candidate.roleName === filterRole
      );
    }

    setFilteredCandidates(filtered);
  };

  const handleReviewChange = (index: number, review: string) => {
    const updatedFilteredCandidates = [...filteredCandidates];
    updatedFilteredCandidates[index].comments = review;
    setFilteredCandidates(updatedFilteredCandidates);
  };
 

  const toggleReview = (index: number) => {
    if (editingIndex === index) {
      setEditingIndex(null);
    } else {
      setEditingIndex(index);
    }
  };

  const closeReview = (index: number, review: string) => {
    const previousFilteredCandidates = [...filteredCandidates];
    const previousComment = previousFilteredCandidates[index].comments;
    previousFilteredCandidates[index].comments = previousComment;
    setFilteredCandidates(previousFilteredCandidates);
    setEditingIndex(null);
  };

  const handleCommentSubmit = async (
    candidateId: string,
    roleId: string,
    assignmentlink: string,
    comment: string,
    index: number
  ) => {
    try {
      await submitReview({
        candidateId: candidateId,
        roleId: roleId,
        assignmentLink: assignmentlink,
        comments: comment,
      });
    } catch (error) {
      console.error("Error handling assignment submission:", error);
    }
    setEditingIndex(null);
    Rolefilter(filterRole);
    ReviewFilter(filterReviewed);
  };

  return (
    
    <div className="m-16">
      <div className="flex justify-end">
        <div className="mb-4 mr-4">
          <label className="mr-2">Filter by Role:</label>
          <select
            className="p-2 border border-gray-300 rounded"
            value={filterRole || "all"}
            onChange={handleRoleFilterChange}
          >
            <option value="all">All</option>
            {Array.from(
              new Set(candidates.map((candidate) => candidate.roleName))
            ).map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="mr-2">Filter by Review:</label>
          <select
            className="p-2 border border-blue-300 rounded"
            value={filterReviewed || "all"}
            onChange={handleReviewedFilterChange}
          >
            <option value="all">All</option>
            <option value="reviewed">Reviewed</option>
            <option value="not-reviewed">Not Reviewed</option>

          </select>
        </div>
      </div>

      <table className="w-full ">
        <thead className="border-b-2 border-gray-300">
          <tr className="text-blue-700">
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">Role</th>
            <th className="px-4 py-2 text-left">GitHub Link</th>
            <th className="px-7 py-2 text-center">Review</th>
          </tr>
        </thead>
        <tbody>
          {filteredCandidates.map((candidate, index) => (
            <tr key={index} className="">
              <td className="border-t-0 border-l-0 border-r-0 px-4 py-8 font-bold">{`${candidate.firstName} ${candidate.lastName}`}</td>
              <td className="border-t-0 border-l-0 border-r-0 px-4 py-4">
                {candidate.roleName}
              </td>
              <td className="border-t-0 border-l-0 border-r-0 px-4 py-4">
                <a
                  href={candidate.assignmentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHubIcon className="h-5 w-5 flex-shrink-0 text-gray-400 mr-3" />
                  Github link
                </a>
              </td>
              <td className="border-t-0 border-l-0 border-r-0 px-4 py-2 w-1/3 text-center ">
                {editingIndex === index ? (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 h-1/2">
                      <p className="font-bold m-2 text-xl">
                        Write your thoughts on assignment
                      </p>
                      <textarea
                        value={filteredCandidates[index].comments}
                        onChange={(e) =>
                          handleReviewChange(index, e.target.value)
                        }
                        className="w-full h-56 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 resize-none"
                      />
                      <div className="flex justify-end mt-4">
                        <button
                          onClick={() => closeReview(index, "")}
                          className="text-red-600 font-bold px-4 mr-4"
                        >
                          Close
                        </button>
                        <button
                          onClick={() =>
                            handleCommentSubmit(
                              candidate.candidateId,
                              candidate.roleId,
                              candidate.assignmentLink,
                              candidate.comments,
                              index
                            )
                          }
                          className="inline-flex cursor-pointer items-center justify-center rounded-md bg-blue-500 text-white px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
                          disabled={!candidate.comments.trim()}
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="inline-flex text-left">
                      <button
                        onClick={() => toggleReview(index)}
                        className="text-blue-600 font-bold py-2 px-4 mr-[10px]"
                      >
                        Write
                      </button>
                      {candidate.comments.length > 0 && (
                        <CheckBadgeIcon className="w-[20px] text-yellow-600 ml-[-20px]" />
                      )}
                    </div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};

export default Assignments;
