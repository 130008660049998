import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React from "react";

type props = {
  placeholder: string;
  label: string;
  name: string;
  inputId: string;
  autoComplete: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  defaultValue?: string;
};

export const InputField = ({
  placeholder,
  label,
  name,
  inputId,
  autoComplete,
  setValue,
  value,
  defaultValue = "",
}: props) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label
        htmlFor={inputId}
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
      >
        {label}
        <span className="text-red-500"> *</span>
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-500 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600">
          <input
            type="text"
            name={name}
            id={inputId}
            autoComplete={autoComplete}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:text-gray-300 dark:placeholder:text-gray-600"
            placeholder={placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
          {defaultValue && (
            <ArrowPathIcon
              className="w-5 h-10 mr-3 cursor-pointer dark:text-gray-300"
              onClick={() => {
                setValue(defaultValue);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
