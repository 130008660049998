import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Questions } from '../../../Models';
import { convert } from 'html-to-text';

export default function AddModal({ title, content, size, onAdd, onCancel, isModalClosed, addedQuestions, removedQuestions }: { title: string, content: string, size: string, onAdd: Function, onCancel: Function, isModalClosed: Function, addedQuestions: Questions[], removedQuestions: Questions[] }) {
    const [open, setOpen] = useState(true);
    const cancelButtonRef = useRef(null);
    const [expandQuestion, setExpandQuestion] = useState<string>('');

    useEffect(() => {
        if (open === false) {
            isModalClosed();
        }
    }, [open, isModalClosed]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    onCancel();
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full ${size === 'small' ? 'sm:max-w-sm' : size === 'large' ? 'sm:max-w-2xl' : 'sm:max-w-lg'
                                    }`}
                            >
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <h2 className='font-bold text-gray-600 my-5 text-sm text-left'>
                                            Are you sure you want to make these changes to the Assessment?
                                        </h2>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            {
                                                addedQuestions.length > 0 && <div className="mt-4">
                                                    <h4 className="text-lg font-semibold text-gray-900 my-2">Added Questions</h4>
                                                    {addedQuestions.map((question, index) => (
                                                        <div key={index} className="mb-2 cursor-pointer">
                                                            {expandQuestion === question.id ?
                                                                <div onClick={() => setExpandQuestion('')} className='border-2 border-gray-300 p-2 rounded-md text-left'>
                                                                    <div>
                                                                        {convert(question.text)}
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <small className='font-bold  underline'>
                                                                            {question.type.toLocaleUpperCase()}
                                                                        </small >
                                                                        <small className='font-bold m-1 underline'>
                                                                            {question.time}
                                                                        </small >
                                                                        <small className='font-bold m-1 underline'>
                                                                            {question.tags}
                                                                        </small >
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div onClick={() => setExpandQuestion(question.id)} className='border-2 border-gray-300 p-2 rounded-md w-full	flex justify-between text-left'>
                                                                    <div>
                                                                        {`${convert(question.text.slice(0, 50))}...`}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            {
                                                removedQuestions.length > 0 && <div>
                                                    <h4 className="text-lg font-semibold text-gray-900 my-2">Removed Questions</h4>
                                                    {removedQuestions.map((question, index) => (
                                                        <div key={index} className="mb-2 cursor-pointer">
                                                            {expandQuestion === question.id ?
                                                                <div onClick={() => setExpandQuestion('')} className='border-2 border-gray-300 p-2 rounded-md'>
                                                                    <div>
                                                                        {convert(question.text)}
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <small className='font-bold  underline'>
                                                                            {question.type.toLocaleUpperCase()}
                                                                        </small >
                                                                        <small className='font-bold m-1 underline'>
                                                                            {question.time}
                                                                        </small >
                                                                        <small className='font-bold m-1 underline'>
                                                                            {question.tags}
                                                                        </small >
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div onClick={() => setExpandQuestion(question.id)} className='border-2 border-gray-300 p-2 rounded-md w-full	'>
                                                                    {`${convert(question.text.slice(0, 50))}...`}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            onAdd(); // Invoke the callback for confirmation
                                            setOpen(false);
                                        }}
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                    >
                                        Add
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            onCancel(); // Invoke the callback for cancellation
                                            setOpen(false);
                                        }}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
