import { Fragment, useEffect, useRef, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { Filter } from "../../../Models";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export default function QuestionsFilter({
    filters,
    handleFilter,
    getAccuracy,
    accuracy,
}: {
    filters: Filter[];
    handleFilter: (filter: Filter[]) => void;
    getAccuracy: Function;
    accuracy: any;
}) {
    const filterRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentAccuracy, setcurrentAccuracy] = useState<{ min: number, max: number }>(accuracy)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterRef.current && event.target) {
                const targetElement = event.target as HTMLElement;
                if (
                    !filterRef.current.contains(targetElement) &&
                    !targetElement.closest(".filter-dropdown")
                ) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setcurrentAccuracy(accuracy);
    }, [accuracy])

    const handleFilterChange = (filterId: string, optionValue: string) => {
        const updatedFilters = [...filters];
        const filterIndex = updatedFilters.findIndex(
            (filter) => filter.id === filterId
        );

        if (filterIndex !== -1) {
            const optionIndex = updatedFilters[filterIndex].options.findIndex(
                (option: any) => option.value === optionValue
            );

            if (filterId === "group" || filterId === "accuracy") {
                updatedFilters[filterIndex].options.forEach((o) => {
                    o.checked = false;
                });
            }

            if (optionIndex !== -1) {
                updatedFilters[filterIndex].options[optionIndex].checked =
                    !updatedFilters[filterIndex].options[optionIndex].checked;
            }
        }
        handleFilter(updatedFilters);
    };


    return (
        <Disclosure
            as="div"
            className="relative inline-block text-white dark:bg-darkbg"
            ref={filterRef}
        >
            <Disclosure.Button
                className="inline-flex  rounded-md bg-white px-2 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-darkbg"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <FunnelIcon className="h-5 w-5 " aria-hidden="true" />
            </Disclosure.Button>

            {isOpen && (
                <Transition
                    as={Fragment}
                    show={isOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Disclosure.Panel className="absolute z-20 right-0 w-60 px-2 bg-white shadow-lg rounded-md mt-2 dark:bg-darkbg">
                        {filters.map((section) => (
                            <Disclosure
                                as="div"
                                key={section.id}
                                className="border-b border-gray-200 py-4  dark:border-gray-700"
                            >
                                {({ open }) => (
                                    <>
                                        <h3 className="-my-3 flow-root dark:bg-darkbg">
                                            <Disclosure.Button className="px-3 flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500 dark:bg-darkbg">
                                                <span className="font-medium text-gray-900 dark:text-gray-300">
                                                    {section.name}
                                                </span>
                                                <span className="ml-6 flex items-center">
                                                    {open ? (
                                                        <MinusIcon
                                                            className="h-5 w-5 filter-dropdown"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <PlusIcon
                                                            className="h-5 w-5 filter-dropdown"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </h3>
                                        <Disclosure.Panel
                                            className={`px-4 ${section.id === "group" || section.id === "skill"
                                                ? "max-h-52 overflow-y-scroll"
                                                : ""
                                                } dark:bg-darkbg`}
                                        >
                                            {section.options.map((option: any, optionIdx: any) => (
                                                <div className="space-y-4 my-1 p-2">
                                                    <div
                                                        key={optionIdx}
                                                        className="flex items-center dark:text-gray-300"
                                                    >
                                                        <input
                                                            id={`filter-${section.id}-${optionIdx}`}
                                                            name={`${section.id}[]`}
                                                            type={`${section.type}`}
                                                            defaultChecked={option.checked}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            onClick={() => {
                                                                handleFilterChange(section.id, option.value);
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                                            className="ml-3 text-sm text-gray-600 dark:text-gray-300"
                                                        >
                                                            {section.id === "skill" ? (
                                                                <>{option.label.name}</>
                                                            ) : (
                                                                <>{option.label.title}</>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </Disclosure.Panel>
                                        {section.id === "accuracy" && (
                                            <div className="">
                                                <Disclosure.Panel className="px-4 overflow-hidden">
                                                    <div className="space-y-2 my-4 p-3 text-black dark:text-white">
                                                        <Slider
                                                            range
                                                            max={100}
                                                            min={0}
                                                            value={[currentAccuracy.min, currentAccuracy.max]}
                                                            onChange={(value: any) => {
                                                                const [min, max] = value as number[];
                                                                if (min >= 0 && max <= 100) {
                                                                    setcurrentAccuracy({
                                                                        min: min,
                                                                        max: max,
                                                                    });
                                                                }
                                                            }}
                                                            onChangeComplete={(value: any) => {
                                                                const [min, max] = value as number[];
                                                                if (min >= 0 && max <= 100) {
                                                                    getAccuracy({
                                                                        min: min,
                                                                        max: max,
                                                                    });
                                                                }
                                                            }}
                                                            marks={{
                                                                0: 0,
                                                                100: 100,
                                                            }}
                                                            styles={{
                                                                track: {
                                                                    background: "#0D42EC",
                                                                },
                                                                handle: {
                                                                    borderColor: "#0D42EC",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </Disclosure.Panel>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </Disclosure.Panel>
                </Transition>
            )}
        </Disclosure>
    );
}
