export default function NoData({
  title,
  description,
}: {
  title?: string;
  description: string;
}) {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-12 lg:px-8 dark:bg-darkbglight">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-entntorange sm:text-5xl">
            {title}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
            {description}
          </p>
        </div>
      </main>
    </>
  );
}
