import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type props = {
  type: "info" | "danger" | "none";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  confirmButtonTitle?: string;
  description: string | JSX.Element;
};

export default function ConfirmModal(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div
                    className={`${
                      props.type !== "none" ? "sm:flex sm:items-start" : ""
                    }`}
                  >
                    {props.type !== "none" && (
                      <div
                        className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                          danger ? "bg-red-100" : "bg-blue-100"
                        } sm:mx-0 sm:h-10 sm:w-10`}
                      >
                        <ExclamationTriangleIcon
                          className={`h-6 w-6 ${
                            danger ? "text-red-600" : "text-blue-600"
                          }`}
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        {typeof props.description === "string" ? (
                          <p className="text-sm text-gray-500">
                            {props.description}
                          </p>
                        ) : (
                          props.description
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md ${
                      danger ? "bg-red-600" : "bg-blue-600"
                    } px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                      danger ? "hover:bg-red-500" : "hover:bg-entntorange"
                    } sm:ml-3 sm:w-auto`}
                    onClick={props.onConfirm}
                  >
                    {props.confirmButtonTitle
                      ? props.confirmButtonTitle
                      : "Confirm"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      if (props.onCancel) props.onCancel();
                      props.setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
