import React, { useEffect, useState } from 'react';
import { deleteAssessment, getAllAssessments } from '../../WebCalls';
import { useNavigate } from 'react-router-dom';
import { CalendarDaysIcon, EllipsisVerticalIcon, ClockIcon } from '@heroicons/react/20/solid';
import { Assessment } from '../../Models';
import Loading from '../Shared/Loading';
import { dateConverter } from '../../Helpers';
import ConfirmModal from '../Shared/ConfirmModal';
import Notification from '../Shared/Notification';
import NoData from '../Shared/NoData';


function Assessments() {
    const [assessments, setAssessments] = useState<Assessment[]>([]);
    const [openDropdown, setOpenDropdown] = useState<string | null | undefined>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [selectedAssessment, setSelectedAssessment] = useState<string | undefined>()
    const [loading, setLoading] = useState<boolean>(true)
    const [assessmentDeleteNotification, setAssessmentDeleteNotification] =
        useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getAssessments();
    }, []);


    const getAssessments = async () => {
        setLoading(true)
        try {
            await getAllAssessments().then((res) => setAssessments(res));

        } catch (error) {
            console.log(error)
        }
        setLoading(false)

    }

    const handleAddAssessment = () => {
        navigate('/assessments/add')
    }


    const handleEdit = (assessmentId: string) => {
        navigate(`/assessments/edit/${assessmentId}`)
    }

    const handleDelete = (assessmentId: string) => {
        // setIsModalOpen(true);
        setSelectedAssessment(assessmentId);
    }

    const toggleDropdown = (e: any, assessmentId: string | null) => {
        setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === assessmentId ? null : assessmentId));

    };

    const confirmDelete = () => {
        setIsDeleteModalOpen(false)
        try {
            deleteAssessment(selectedAssessment);
            setSelectedAssessment('');
            setAssessmentDeleteNotification(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (error) {
            console.log(error);
        }


    };


    const handleAssessmentQuestionRoute = (e: any, id: string) => {
        if (e.target.closest('.dropdown-button')) {
            return;
        }
        navigate(`/assessment/questions/${id}`)

    }

    return (
        <div className=' bg-white dark:bg-darkbg dark:text-gray-400'>
            <header className="bg-gray-100 py-8 dark:bg-darkbglight">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">
                        <div className='flex items-center justify-between mb-4 '>
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                                Assessments
                            </h1>
                            <div className=''>
                                <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={handleAddAssessment}>
                                    Add Assessment
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {(assessments.length > 0) ? <div>
                {assessments.map((assessment: Assessment) => (
                    <div className='border-b mx-5 border-gray-200 sm:mx-10 md:mx-20 lg:mx-40  dark:border-gray-800'>
                        <div className='grid   grid-cols-4 gap-4 bg-white p-2 pt-4 hover:bg-gradient-to-r from-white via-gray-50 to-white cursor-pointer relative border-b dark:border-gray-700 border-gray-300 dark:bg-darkbg dark:text-gray-400 dark:from-darkbg dark:via-darkbglight dark:to-darkbg    ' key={assessment.id} onClick={(e) => handleAssessmentQuestionRoute(e, assessment.id)}>
                            <div className='col-span-2 sm:col-span-1 flex flex-col justify-start items-start'>
                                <h3 className='text-base font-semibold leading-6 text-gray-900 dark:text-gray-300'>{assessment.title}</h3>
                                <div className='text-gray-500 text-sm flex flex-row gap-1 mt-2'><ClockIcon height={20} color='blue' /> <span className='text-sm '>{assessment.time}</span> mins</div>
                            </div>
                            <div className='col-span-2 text-gray-700 lg:text-sm sm:text-xs flex items-center flex-col '>
                                <div className='font-semibold text-sm  dark:text-gray-400 text-left'>Last Updated On</div>
                                <div className='flex flex-row items-start m-1 text-sm  dark:text-gray-400 text-left'>
                                    <span className='mx-1'><CalendarDaysIcon height={20} color='blue' /></span>
                                    <span>{dateConverter(assessment.lastUpdateDate)}</span>
                                </div>
                            </div>
                            <div className='col-span-0.5 items-start text-left hidden sm:block'>
                                <div className='text-sm'>
                                    <p className='font-semibold'><small className=''>
                                        Base Score:
                                    </small> {assessment.baseScore}</p>
                                    <p className='font-semibold text-sm'><small className=''>Exceptional Score:</small> {assessment.exceptionalScore}</p>
                                </div>
                                <div className=''>
                                    <p className='font-semibold text-sm'><small className=''>Number of Ques:</small> {assessment.numOfQuestions}</p>

                                </div>
                            </div>
                            <div className='absolute top-0 right-0 mt-2 mr-2'>
                                <div className='relative inline-block text-left '>
                                    <div>
                                        <button
                                            type='button'
                                            className='inline-flex justify-center w-full px-2 py-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200  dropdown-button'
                                            id={`options-menu-${assessment.id}`}
                                            aria-haspopup='true'
                                            aria-expanded={openDropdown === assessment.id}
                                            onClick={(e) => toggleDropdown(e, assessment.id)}
                                        >
                                            <EllipsisVerticalIcon height={15} />
                                        </button>
                                    </div>
                                    <div className={`z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight ${openDropdown === assessment.id ? 'block' : 'hidden'}`}>
                                        <div className='py-1' role='menu' aria-orientation='vertical' aria-labelledby={`options-menu-${assessment.id}`}>
                                            <button
                                                onClick={() => handleEdit(assessment.id)}
                                                className='block px-4 py-2 text-sm text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100 w-full text-left dropdown-button'
                                                role='menuitem'
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setIsDeleteModalOpen(true)
                                                    handleDelete(assessment.id)
                                                }}
                                                className='block px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-700 hover:text-red-900 dark:hover:text-red-100 w-full text-left dropdown-button'
                                                role='menuitem'
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                ))}
            </div> : !loading && assessments.length === 0 ? <div className='' >
                <NoData title='No Assessment Found' description="There are currently no available Assessments. Click 'Add Assessment' to start adding Assessments for the role." />
            </div>
                :
                <>
                    <Loading />
                </>
            }
            <ConfirmModal
                open={isDeleteModalOpen}
                setOpen={setIsDeleteModalOpen}
                onConfirm={confirmDelete}
                type="danger"
                title="Delete Assessment"
                description=" Are you sure you want to delete this Assessment? All of the
        data will be permanently removed. This action cannot
        be undone."
            />

            <Notification
                show={assessmentDeleteNotification}
                setShow={setAssessmentDeleteNotification}
                title="Deleted"
                description="Assessment Deleted Successfully"
                type="success"
            />

        </div>
    );
}

export default Assessments;
