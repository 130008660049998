import React, { useEffect, useState } from 'react';
import { Option, Question } from '../Models';
import { PlusCircleIcon, TrashIcon, } from '@heroicons/react/20/solid';
import { deleteAssessmentOptions } from '../WebCalls';
import ConfirmModal from './Shared/ConfirmModal';


const CheckboxInput = ({ selectedQType, getOptionsfromChild, selectedOptions }: { selectedQType: Question, getOptionsfromChild: (o: Option[]) => void, selectedOptions: Option[] | undefined }) => {
    const [options, setOptions] = useState<Option[]>([
        {
            id: 1, text: '', isCorrect: false
        }
    ]);


    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // useEffect(() => {
    //     getOptionsfromChild(options);
    // }, [options])

    useEffect(() => {
        if (selectedOptions) {
            setOptions(selectedOptions);
        }
    }, [selectedOptions])

    const handleInputChange = (index: number, value: string): void => {
        const updatedOptions = [...options];
        updatedOptions[index].text = value;
        setOptions(updatedOptions);
        getOptionsfromChild(updatedOptions);

    };

    const handleCheckboxChange = (index: number): void => {
        let renderedOptions;
        let updatedOptions = [...options];
        if (selectedQType.title === 'SINGLE') {
            renderedOptions = options.map((e) => {
                return { id: e.id, text: e.text, isCorrect: false }
            })
            updatedOptions = [...renderedOptions]
        }
        updatedOptions[index].isCorrect = !updatedOptions[index].isCorrect;
        setOptions(updatedOptions)
        getOptionsfromChild(updatedOptions);

    };

    const handleAdd = (e: any): void => {
        e.preventDefault();
        let tempOptions = options;
        tempOptions.push({
            id: options.length + 1, text: '', isCorrect: false
        })
        setOptions([...tempOptions]);

    }

    const handleDelete = (index: number): void => {
        setDeleteIndex(index);
        setIsModalOpen(true);
    };

    const confirmDelete = () => {
        if (deleteIndex !== null) {
            const updatedOptions = [...options];
            const currentDeletedOption = updatedOptions[deleteIndex]
            updatedOptions.splice(deleteIndex, 1);
            setOptions(updatedOptions);
            setIsModalOpen(false);
            setDeleteIndex(null);
            getOptionsfromChild(updatedOptions);

            if (currentDeletedOption.id.toLocaleString().length > 5) {
                //MAKE A DELETE OPTION CALL WITH INDEX
                deleteAssessmentOptions(currentDeletedOption)
            }
        }
    };

    return (
        <div className=" ">
            <div className='flex flex-col items-start py-14 border-b border-gray-300 lg:flex-row dark:border-gray-600'>
                <h3 className="block text-sm font-medium text-gray-700  text-left w-64 py-4 dark:bg-transparent dark:text-gray-400">Options *</h3>
                <div className='w-full'>
                    <>
                        {options.map((option, index) => (
                            <div key={option.id} className="flex items-center mb-4 "  >
                                <input
                                    type="text"
                                    placeholder={`Enter text for Option ${option.id}`}
                                    value={option.text}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    className="border border-gray-300 px-2 py-1 rounded-md mr-2 w-full dark:bg-transparent dark:text-gray-400 dark:border-gray-600"
                                />
                                <input
                                    type="checkbox"
                                    id={`checkbox${option.id}`}
                                    checked={option.isCorrect}
                                    onChange={() => handleCheckboxChange(index)}
                                    className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ml-2"
                                />
                                <label htmlFor={`checkbox${option.id}`} className="text-left hidden sm:w-32 sm:block dark:text-gray-400" >
                                    Is Correct
                                </label>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(index)
                                    }}
                                    className=""
                                >
                                    <TrashIcon className="h-4 text-red-500" />
                                </button>
                            </div>
                        ))}
                    </>
                    <div className="flex">
                        <button onClick={handleAdd} className="">
                            <PlusCircleIcon className="h-8 text-blue-700" />

                        </button>
                    </div>
                </div>

            </div>

            <ConfirmModal
                open={isModalOpen}
                setOpen={setIsModalOpen}
                onConfirm={confirmDelete}
                type="danger"
                title="Delete Option"
                description="Are you sure you want to delete this option?"
            />
        </div>
    );
};

export default CheckboxInput;
