import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CandidateAction } from "./RoleCandidates";
import { Candidate } from "../../../Models";
import { Switch } from "@headlessui/react";
type Headers = {
  name: string;
  asc: boolean;
  fieldName: string;
  sort: boolean;
  isActive: boolean;
};

const initialHeading: Headers[] = [
  {
    name: "Name",
    fieldName: "name",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "Location",
    fieldName: "workLocation1",
    asc: true,
    sort: false,
    isActive: false,
  },
  { name: "Age", fieldName: "age", asc: true, sort: true, isActive: false },
  // { name: "EG", fieldName: "EG", asc: true, sort: true, isActive: false },
  // { name: "WG", fieldName: "WG", asc: true, sort: true, isActive: false },
  {
    name: "Exp",
    fieldName: "experienceInMonths",
    asc: true,
    sort: true,
    isActive: false,
  },
  {
    name: "CvGrade",
    fieldName: "combinedGrade",
    asc: true,
    sort: true,
    isActive: false,
  },

  { name: "Gap", fieldName: "Gap", asc: true, sort: true, isActive: false },

  {
    name: "graph",
    fieldName: "graph",
    asc: false,
    sort: false,
    isActive: false,
  },

  { name: "Score", fieldName: "score", asc: true, sort: true, isActive: false },
  { name: "Grades", fieldName: "", asc: true, sort: false, isActive: false },
  // {
  //   name: "Assignment",
  //   fieldName: "",
  //   asc: true,
  //   sort: false,
  //   isActive: false,
  // },
  // {
  //   name: "YOE/Grad Date",
  //   fieldName: "yearsOfExperience",
  //   asc: true,
  //   sort: true,
  //   isActive: false,
  // },
  // {
  //   name: "Last University/Company",
  //   fieldName: "",
  //   asc: true,
  //   sort: false,
  //   isActive: false,
  // },
  // {
  //   name: "Time/Period",
  //   fieldName: "",
  //   asc: true,
  //   sort: false,
  //   isActive: false,
  // },
  {
    name: "Notice",
    fieldName: "noticePeriod",
    asc: true,
    sort: true,
    isActive: false,
  },

  // {
  //   name: "Date",
  //   fieldName: "latestStatusDate",
  //   asc: true,
  //   sort: true,
  //   isActive: false,
  // },
];
type props = {
  selectedCandidate: CandidateAction[];
  candidates: Candidate[];
  actionButtonsDisabled: boolean[];
  handleSelectedCandidates: (candidates: CandidateAction[]) => void;
  handleSort: (query: string) => void;
  handleBulkActionModal: (open: boolean) => void;
  handleBulkActionIsDisqualify: (open: boolean) => void;
  handleMediaAnswerDownload: () => void;
  handleAddAudioQuestion: () => void;
  selectedColoumns: string[];
  setcolomns: React.Dispatch<React.SetStateAction<number>>;
  colomns: number;
  setagebased: React.Dispatch<React.SetStateAction<boolean>>;
  agebased: boolean;
};

export default function RoleCandidatesHeader({
  selectedCandidate,
  candidates,
  actionButtonsDisabled,
  handleSelectedCandidates,
  handleSort,
  handleBulkActionModal,
  handleBulkActionIsDisqualify,
  handleMediaAnswerDownload,
  handleAddAudioQuestion,
  selectedColoumns,
  setcolomns,
  colomns,
  setagebased,
  agebased,
}: props) {
  const [sort, setSort] = useState<Headers[]>([]);
  const [sortOptions, setSortOptions] = useState(initialHeading);
  const [checked, setChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const checkbox = useRef<HTMLInputElement>(null);

  const additionalcolomns = ["EduGrade", "WorkGrade"];
  useEffect(() => {
    let selectedAdditionalColumns: string[] = [];
    if (colomns === 1) {
      selectedAdditionalColumns = ["EduGrade"];
    } else if (colomns === 2) {
      selectedAdditionalColumns = additionalcolomns;
    }

    const additionalHeadings: Headers[] = selectedAdditionalColumns.map(
      (col) => {
        let fieldName = "";
        // Assign the correct field name based on the column
        if (col === "EduGrade") {
          fieldName = "totalEducationGrade";
        } else if (col === "WorkGrade") {
          fieldName = "totalExperienceGrade";
        }

        return {
          name: col,
          fieldName: fieldName,
          asc: true,
          sort: true,
          isActive: false,
        };
      }
    );

    // Integrate additionalHeadings into initialHeading
    const updatedHeading = [
      ...initialHeading.slice(0, 5), // Include the first 4 headings
      ...additionalHeadings, // Add the additional columns based on `columns`
      ...initialHeading.slice(5), // Include the remaining headings
    ];

    setSortOptions(updatedHeading);
  }, [colomns]);


  const handleOnCVG = () => {
    if (colomns === 2) {
      setcolomns(0);
    } else {
      setcolomns(colomns + 1);
    }
  };

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedCandidate.length > 0 &&
      selectedCandidate.length !== candidates.length;
    setChecked(selectedCandidate.length === candidates.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedCandidate, candidates]);

  useEffect(() => {
    let sortQueryList: string[] = [];
    sort.forEach((s) => {
      if (s.fieldName === "name") {
        sortQueryList.push(`firstName${s.asc ? "" : " desc"}`);
        sortQueryList.push(`lastName${s.asc ? "" : " desc"}`);
      } else sortQueryList.push(`${s.fieldName}${s.asc ? "" : " desc"}`);
    });
    handleSort(sortQueryList.join(","));
  }, [sort, handleSort]);

  const handleSortChange = (sortOption: Headers) => {
    let updatedSort = [...sort];
    const updatedSortedoption = sortOptions.map((so) => {
      if (so.isActive && so.name === sortOption.name) {
        if (so.asc) {
          let changedSort = updatedSort.find((us) => us.name === so.name);
          if (changedSort) changedSort.asc = false;
          return { ...so, asc: false };
        } else {
          const ind = updatedSort.findIndex((us) => us.name === so.name);
          if (ind !== -1) {
            updatedSort.splice(ind, 1);
          }
          return { ...so, asc: true, isActive: false };
        }
      } else if (!so.isActive && so.name === sortOption.name) {
        updatedSort = [...updatedSort, { ...so, isActive: true }];
        return { ...so, isActive: true };
      } else {
        return so;
      }
    });

    setSortOptions(updatedSortedoption);
    setSort(updatedSort);
  };

  function toggleAll() {
    handleSelectedCandidates(checked || indeterminate ? [] : candidates);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <thead className="sticky top-16 bg-white dark:bg-darkbglight z-20 before:border-b before:border-gray-300 before:absolute before:content-[''] before:w-full before:h-full before:-z-10 dark:before:border-gray-500 dark:before:border-t">
      <tr className="">
        <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
            ref={checkbox}
            checked={checked}
            onChange={toggleAll}
          />
        </th>
        {sortOptions.map((s, i) =>
          s.name === "CvGrade" ? (
            <th
              key={s.name}
              scope="col"
              className={` py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 w-fit min-w-14`}
            >
              <div className="flex  items-center">
                <span onClick={handleOnCVG} className="cursor-pointer">
                  {s.name}
                </span>
                {s.sort && (
                  <span
                    className={`cursor-pointer hover:bg-gray-200 rounded-full dark:hover:bg-entntorange py-0.5 px-1.5 flex items-center ${
                      s.isActive && "bg-blue-100 dark:bg-entntblue"
                    }`}
                    onClick={() => {
                      handleSortChange(s);
                    }}
                  >
                    <span
                      className={
                        s.isActive ? "text-entntblue dark:text-gray-300" : ""
                      }
                    >
                      {s.asc ? (
                        <ArrowUpIcon height={15} />
                      ) : (
                        <ArrowDownIcon height={15} />
                      )}
                    </span>
                    <span
                      className={`${
                        s.isActive && "text-entntblue dark:text-gray-300"
                      } text-xs font-medium`}
                    >
                      {s.isActive &&
                        sort.findIndex((st) => st.name === s.name) + 1}
                    </span>
                  </span>
                )}
              </div>
            </th>
          ) : (
            <th
              key={s.name}
              scope="col"
              className={`py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 w-fit min-w-14`}
            >
              {selectedCandidate.length > 0 && (
                <div className="absolute top-0 flex h-10 items-center space-x-3 sm:left-12 z-30 bg-white dark:bg-darkbglight">
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                    onClick={() => {
                      handleBulkActionIsDisqualify(false);
                      handleBulkActionModal(true);
                    }}
                    disabled={
                      !selectedCandidate ||
                      selectedCandidate.length === 0 ||
                      actionButtonsDisabled[0]
                    }
                  >
                    Advance
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                    onClick={() => {
                      handleBulkActionIsDisqualify(true);
                      handleBulkActionModal(true);
                    }}
                    disabled={
                      !selectedCandidate ||
                      selectedCandidate.length === 0 ||
                      actionButtonsDisabled[1]
                    }
                  >
                    Disqualify
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                    onClick={handleMediaAnswerDownload}
                    disabled={
                      candidates.length === 0 ||
                      !selectedCandidate ||
                      selectedCandidate.length === 0 ||
                      actionButtonsDisabled[2]
                    }
                  >
                    Download Video
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-white"
                    onClick={handleAddAudioQuestion}
                    disabled={
                      candidates.length === 0 ||
                      !selectedCandidate ||
                      selectedCandidate.length === 0
                    }
                  >
                    Add Audio Questions
                  </button>
                </div>
              )}
              <div className="flex items-center">
                <span>{s.name}</span>
                {s.sort && (
                  <span
                    className={`cursor-pointer hover:bg-gray-200 rounded-full dark:hover:bg-entntorange py-0.5 px-1.5 flex items-center ${
                      s.isActive && "bg-blue-100 dark:bg-entntblue"
                    }`}
                    onClick={() => {
                      handleSortChange(s);
                    }}
                  >
                    <span
                      className={
                        s.isActive ? "text-entntblue dark:text-gray-300" : ""
                      }
                    >
                      {s.asc ? (
                        <ArrowUpIcon height={15} />
                      ) : (
                        <ArrowDownIcon height={15} />
                      )}
                    </span>
                    <span
                      className={`${
                        s.isActive && "text-entntblue dark:text-gray-300"
                      } text-xs font-medium`}
                    >
                      {s.isActive &&
                        sort.findIndex((st) => st.name === s.name) + 1}
                    </span>
                  </span>
                )}
                {i === 0 && (
                  <Switch
                    checked={agebased}
                    onChange={() => setagebased(!agebased)}
                    className={`group relative ml-2 flex items-center h-4 w-8 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none bg-gray-200 border ${
                      agebased ? "border-entntblue" : "border-entntorange"
                    }`}
                  >
                    <span
                      aria-hidden="true"
                      className={`pointer-events-none inline-block h-3 w-3 transform rounded-full shadow-lg transition duration-200 ease-in-out ${
                        agebased
                          ? "translate-x-4 bg-entntblue"
                          : "translate-x-0.5 bg-entntorange"
                      }`}
                    />

                    <span
                      className={`absolute text-xs font-bold ${
                        agebased
                          ? "text-entntblue left-1 "
                          : "text-entntorange right-2"
                      }`}
                    >
                      {agebased ? "A" : "E"}
                    </span>
                  </Switch>
                )}
              </div>
            </th>
          )
        )}
      </tr>
    </thead>
  );
}
