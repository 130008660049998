import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

export default function useUserData() {
  const { instance } = useMsal();
  const [userId, setUserId] = useState<string | undefined>();
  const [username, setUsername] = useState<string | undefined>();
  useEffect(() => {
    const name = instance.getActiveAccount()?.name;
    const id = instance.getActiveAccount()?.username;
    if (name) setUsername(name);
    if (id) setUserId(id);
  }, [instance]);

  return { userId, username };
}
