import { Fragment, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { GetProfilePhoto } from "../WebCalls";
import Avatar from "./Shared/Avatar";
import { getAvatarName } from "../Helpers";
import useGraphApiToken from "../hooks/useGraphApiToken";
import useUserData from "../hooks/useUserData";
import entntlogo from "../entntlogo.svg"
// import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar({
  setThemeIsDark,
  themeIsDark,
}: {
  setThemeIsDark: (state: boolean) => void;
  themeIsDark: boolean;
}) {
  const [current, setCurrent] = useState("Roles");
  const location = useLocation();
  const { instance } = useMsal();
  const graphToken = useGraphApiToken();
  const [profile, setProfile] = useState<string>();
  const { username, userId } = useUserData();

  useEffect(() => {
    if (!graphToken) return;
    GetProfilePhoto(graphToken)
      .then((photo) => {
        if (photo) setProfile(URL.createObjectURL(photo));
      })
      .catch((e) => {
        console.log(e);
        // if (e instanceof InteractionRequiredAuthError) {
        //     instance.acquireTokenRedirect({
        //         ...loginRequest,
        //         account: instance.getActiveAccount() as AccountInfo
        //     });
        // }
      });
  }, [graphToken]);

  useEffect(() => {
    let p = location.pathname.split("/");
    let option = p.length > 1 ? p[1] : "Roles";
    setCurrent(option);
  }, [location]);

  const navigation = useMemo(
    () => [
      {
        name: "Dashboard",
        href: "/dashboard",
        current: current === "Dashboard",
      },
      {
        name: "Roles",
        href: "/",
        current: ["Roles", "roles"].includes(current),
      },
      {
        name: "Assessments",
        href: "/assessments",
        current: ["Assessments", "assessments", "assessment"].includes(current),
      },
      {
        name: "Questions",
        href: "/questions",
        current: ["Questions", "questions"].includes(current),
      },
      {
        name: "Rank Board",
        href: "/rankboard",
        current: current === "Rank Board",
      },
      {
        name: "Quarantine",
        href: "/quarantine/companies",
        current: ["Quarantine", "quarantine"].includes(current),
      },
      // {
      //   name: "Candidates",
      //   href: "/candidates",
      //   current: current === "Candidates",
      // },
      // { name: "Calendar", href: "/calendar", current: current === "Calendar" },
    ],
    [current]
  );
  useEffect(() => {
    if (location.pathname === "/") {
      setCurrent("Roles");
      return;
    }
    navigation.some((item) => {
      if (item.name !== "Roles" && location.pathname.startsWith(item.href)) {
        setCurrent(item.name);
        return true;
      }
      return false;
    });
  }, [location.pathname, navigation]);

  const handleSignout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Disclosure
      as="nav"
      className="bg-white shadow dark:bg-darkbg sticky top-0 z-40 dark:border-b dark:border-gray-500"
    >
      {({ open }) => (
        <>
          <div className="mx-auto  px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-entntblue">
                  <span className="absolute -inset-0.5" />
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="https://www.entnt.in" className="-m-1.5 p-1.5">
                    <img
                      className="h-10 w-auto"
                      src={entntlogo}
                      alt="ENTNT"
                    />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      to={item.href}
                      key={item.name}
                      onClick={() => {
                        setCurrent(item.name);
                      }}
                      className={`inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium
                        ${
                          item.current
                            ? "border-entntblue text-gray-900 dark:text-entntblue "
                            : "border-transparent  text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:hover:text-white dark:text-gray-400  dark:hover:border-white"
                        }`}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <span className="md:block hidden text-sm text-gray-500 mr-2 dark:text-gray-400">
                  {userId}
                </span>
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none ring-2 ring-entntblue ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <Avatar
                        src={profile}
                        avatarName={username && getAvatarName(username)}
                        height="h-8"
                        width="w-8"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-darkbglight">
                      <Menu.Item>
                        <span className="md:hidden flex px-4 py-2 text-sm text-gray-700 w-full bg-gray-200 dark:bg-darkbg dark:text-gray-400">
                          {userId}
                        </span>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "flex px-4 py-2 text-sm text-gray-700 w-full text-left dark:text-gray-300 dark:hover:text-black"
                            )}
                            onClick={() => {
                              setThemeIsDark(!themeIsDark);
                            }}
                          >
                            <span>Mode :</span>
                            <span className="font-bold ml-2">
                              {themeIsDark ? "Dark" : "Light"}
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 w-full text-left dark:text-gray-300 dark:hover:text-black"
                            )}
                            onClick={() => {
                              handleSignout();
                            }}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {navigation.map((item) => (
                <Link
                  to={item.href}
                  key={item.name}
                  onClick={() => {
                    setCurrent(item.name);
                  }}
                  className={classNames(
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                    item.current
                      ? "border-entntblue bg-indigo-50 text-entntblue dark:bg-darkbglight"
                      : "border-transparent dark:text-gray-400 text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                  )}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
