export const placeHolder = (type: string) => {
  switch (type) {
    case "Job description":
      return `Job Description
Eg: Why ENTNT
Are you ready to launch your career in the dynamic world of tech? Look no further than ENTNT, where we're redefining workforce solutions and software innovation. We understand that the journey to becoming a top-tier programmer can be challenging and is often dependent on having the right mentors and developing tangible skills at the most crucial steps of your career, which is why we're invested in the growth of our colleagues......`;
    case "Email body":
      return `Email Body
Eg: Hello,

I hope this email finds you well. Your application for the below vacant role at ENTNT has been submitted successfully.....`;
    case "Offer email":
      return `Offer Email Body
Eg: Hello,

I hope this email finds you well. I'm excited to welcome you to our team at ENTNT. We are thrilled to have you on board.....`;
    case "Rejection email":
      return `Rejection Email Body
Eg: Thank you for your interest in this position. We greatly appreciate that you looked to us as an employer of choice. Unfortunately, we will not be moving forward with your application......`;
  }
};
export const editorToolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "link",
    "emoji",
    "history",
  ],
  inline: {
    inDropdown: false,
    options: ["bold", "italic", "underline", "strikethrough"],
    bold: {
      icon: "/svg/bold.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    italic: {
      icon: "/svg/italics.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    underline: {
      icon: "/svg/underline.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    strikethrough: {
      icon: "/svg/strike.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
  },
  blockType: {
    inDropdown: true,
    className: "dark:!bg-transparent dark:!border-entntblue",
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
  },
  fontSize: {
    className: "dark:!bg-transparent dark:!border-entntblue",
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered", "indent", "outdent"],
    unordered: {
      icon: "/svg/unordered.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    ordered: {
      icon: "/svg/ordered.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    indent: {
      icon: "/svg/indent.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    outdent: {
      icon: "/svg/outdent.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
  },
  textAlign: {
    inDropdown: false,
    options: ["left", "center", "right", "justify"],
    left: {
      icon: "/svg/left.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    center: {
      icon: "/svg/center.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    right: {
      icon: "/svg/right.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    justify: {
      icon: "/svg/justify.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
  },
  link: {
    inDropdown: false,
    options: ["link"],
    link: {
      icon: "/svg/link.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
  },
  emoji: {
    className: "dark:!bg-transparent dark:!border-entntblue",
    icon: "/svg/emoji.svg",
  },
  history: {
    inDropdown: false,
    options: ["undo", "redo"],
    undo: {
      icon: "/svg/undo.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
    redo: {
      icon: "/svg/redo.svg",
      className: "dark:!bg-transparent dark:!border-entntblue",
    },
  },
};

export const clearLocalStorageExceptDark = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key && key !== "darkTheme") {
      localStorage.removeItem(key);
    }
  });
};
