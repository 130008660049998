import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { NIL } from "uuid";
import { deleteRankType } from "../../../../WebCalls";
import useApiToken from "../../../../hooks/useApiToken";
import ConfirmModal from "../../../Shared/ConfirmModal";
import { CategoryFull } from "../../../../Models";
import {
  filterOption,
  IFilter,
} from "../../../Roles/Sidebar/RoleSidebarCandidate";
import { useLocation, useNavigate } from "react-router-dom";

const unknownFilter = { value: null, label: "Unknown", checked: false };
const initialFilters: IFilter[] = [
  {
    id: "rankingId",
    name: "Ranking Type",
    options: [{ value: NIL, label: "Overall", checked: true }],
  },
  {
    id: "rankingCategory.Title",
    name: "Ranking",
    options: [],
  },
];

const initialCompanyFilter: IFilter[] = [
  {
    id: "hotStartup",
    name: "Hot Startup",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
      unknownFilter,
    ],
  },
  {
    id: "relevance",
    name: "Relevance",
    options: [
      { value: "High", label: "High", checked: false },
      { value: "Low", label: "Low", checked: false },
      unknownFilter,
    ],
  },
  {
    id: "stockMarket",
    name: "Stock Market",
    options: [
      { value: "India", label: "Listed, In India", checked: false },
      { value: "Not India", label: "Listed, Outside India", checked: false },
      { value: "Yes", label: "Listed, Unknown", checked: false },
      { value: "No", label: "No", checked: false },
      unknownFilter,
    ],
  },
];

interface Props {
  setSelectedRoleType: any;
  rankTypes: { id: string; name: string }[];
  rankCategoryLabels: CategoryFull[];
  tab: string;
  fetchAndSetRankType: () => Promise<void>;
  handleFilterQuery: (filter: string) => void;
}

const RankingSideBarBody = ({
  setSelectedRoleType,
  rankTypes,
  rankCategoryLabels,
  tab,
  fetchAndSetRankType,
  handleFilterQuery,
}: Props) => {
  const [filters, setFilters] = useState([
    ...initialFilters,
    ...initialCompanyFilter,
  ]);
  const [deleteRankTypeModal, setDeleteRankTypeModal] = useState(false);
  const [deleteRankTypeId, setDeleteRankTypeId] = useState<string>();
  const token = useApiToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    const allFilter =
      tab === "Companies"
        ? [...initialFilters, ...initialCompanyFilter]
        : [...initialFilters];
    const localFilters: IFilter[] = allFilter.map((filter) => {
      if (filter.id === "rankingId") {
        const updatedOptions = rankTypes.map((role) => {
          return {
            value: role.id,
            label: role.name,
            checked: role.id === (type ?? NIL),
          };
        });
        return { ...filter, options: updatedOptions };
      }
      return filter;
    });
    setFilters(localFilters);
  }, [token, tab, rankTypes]);

  useEffect(() => {
    const updateFilter = (prevFilters: IFilter[]) => {
      const uf = prevFilters.map((f) => {
        if (f.id === "rankingCategory.Title") {
          const updatedOptions: filterOption[] = rankCategoryLabels.map(
            (rc) => {
              return { value: rc.title, label: rc.title, checked: false };
            }
          );
          updatedOptions.push({
            value: null,
            label: "Unranked",
            checked: false,
          });
          return { ...f, options: updatedOptions };
        }
        return f;
      });
      return uf;
    };
    setFilters((prev) => {
      const updatedFilter = updateFilter(prev);
      return updatedFilter;
    });
  }, [rankCategoryLabels]);

  useEffect(() => {
    let query: string[] = [];
    filters.slice(1).forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((fo) => {
        if (fo.value !== "")
          if (fo.checked) {
            const stockCombinedOperation =
              f.id === "stockMarket" &&
              (fo.value === "Not India" || fo.value === "India");
            if (stockCombinedOperation) {
              switch (fo.value) {
                case "Not India":
                  sameFilter.push(
                    `${f.id} ne null and ${f.id} !contains "India" and ${f.id} ne "Yes"`
                  );
                  break;
                case "India":
                  sameFilter.push(`${f.id} contains "India"`);
              }
            } else {
              const isUnranked =
                f.id === "rankingCategory.Title" && fo.value === null;
              sameFilter.push(
                `${isUnranked ? "rankingCategory" : f.id} eq ${
                  typeof fo.value === "string"
                    ? `"${fo.value}"`
                    : fo.value === true
                      ? null
                      : fo.value
                }`
              );
            }
          }
      });

      if (sameFilter.length > 0) {
        if (f.id === "stockMarket") {
          let temp = sameFilter[0];
          sameFilter[0] = sameFilter[sameFilter.length - 1];
          sameFilter[sameFilter.length - 1] = temp;
        }
        let joinedSameFilter = "(" + sameFilter.join(" or ") + ")";
        if (f.id === "stockMarket") {
          const indiaListed = 'stockMarket contains "India"';
          const negateIndiaListed = 'and stockMarket !contains "India"';
          const listedUnknown = 'stockMarket eq "Yes"';
          const negateListedUnknown = 'and stockMarket ne "Yes"';
          const unknown = "stockMarket eq null";
          const negateUnknown = "stockMarket ne null and";
          if (
            joinedSameFilter.includes(unknown) &&
            joinedSameFilter.includes(negateUnknown)
          )
            joinedSameFilter = joinedSameFilter.replace(negateUnknown, "");
          if (
            joinedSameFilter.includes(negateIndiaListed) &&
            joinedSameFilter.includes(indiaListed)
          )
            joinedSameFilter = joinedSameFilter.replace(negateIndiaListed, "");
          if (
            joinedSameFilter.includes(negateListedUnknown) &&
            joinedSameFilter.includes(listedUnknown)
          )
            joinedSameFilter = joinedSameFilter.replace(
              negateListedUnknown,
              ""
            );
        }
        query.push(joinedSameFilter);
      }
    });

    handleFilterQuery(query.join(" and "));
  }, [filters]);

  const handleFilterChange = (
    section: IFilter,
    checkedOption: filterOption
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (section.id === filter.id) {
        const updatedOptions = filter.options.map((option) => {
          if (
            option.value === checkedOption.value &&
            option.label === checkedOption.label
          ) {
            return { ...option, checked: !option.checked };
          }

          if (section.id === "rankingId") return { ...option, checked: false };
          return option;
        });

        return { ...filter, options: updatedOptions };
      }

      return filter;
    });
    if (section.id === "rankingId") {
      const theRole = rankTypes?.find(
        (role: { id: string; name: string }) => role.id === checkedOption.value
      );
      navigate(`${window.location.pathname}?type=${theRole?.id}`);
      setSelectedRoleType(theRole);
    }
    setFilters(updatedFilters);
  };

  const handleDeleteRankType = async () => {
    if (!token || !deleteRankTypeId) return;
    await deleteRankType(deleteRankTypeId, token);
    setDeleteRankTypeModal(false);
    fetchAndSetRankType();
  };

  return (
    <>
      <div className="px-4">
        {filters.map((section, ind) => {
          return (
            <Disclosure
              as="div"
              key={section.id}
              className="border-b border-gray-200 py-4 dark:border-gray-500"
            >
              {({ open, close }) => (
                <div className="relative">
                  <h3 className="-my-4 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200">
                      <span className="font-medium text-gray-900 dark:text-gray-300">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>

                  <Disclosure.Panel
                    className={`py-3 px-4 absolute right-4 bg-white rounded-md z-50 border border-gray-200 shadow-sm dark:bg-darkbglight dark:border-gray-500"
                    } h-fit`}
                  >
                    <div className={`space-y-2`}>
                      {section.options.map((option, optionIdx) => {
                        return (
                          <div key={optionIdx} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type={ind === 0 ? "radio" : "checkbox"}
                              onChange={() => {
                                handleFilterChange(section, option);
                              }}
                              checked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />

                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                            >
                              <span>{option.label}</span>
                            </label>
                            {ind === 0 && option.value !== NIL && (
                              <button>
                                <TrashIcon
                                  className="h-4 text-gray-500 hover:text-gray-600"
                                  onClick={() => {
                                    setDeleteRankTypeId(option.value as string);
                                    setDeleteRankTypeModal(true);
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Disclosure.Panel>
                  {open && (
                    <div
                      className="fixed inset-0 z-20"
                      onClick={() => {
                        close();
                      }}
                    ></div>
                  )}
                </div>
              )}
            </Disclosure>
          );
        })}
      </div>
      <ConfirmModal
        open={deleteRankTypeModal}
        setOpen={setDeleteRankTypeModal}
        onConfirm={handleDeleteRankType}
        type="danger"
        title="Delete Rank Type"
        description="Are you sure? Deleting this will also remove all related rankings."
      />
    </>
  );
};

export default RankingSideBarBody;
