import React, { useEffect, useState } from "react";
import { QuarantineCompany, QuarantineUniversity } from "../../../Models";
import Loading from "../../Shared/Loading";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import {
  Filters,
  companyUpdateAI,
  confirmQuarantineCompany,
  confirmQuarantineUniversity,
  deleteQuarantineCompany,
  deleteQuarantineUniversity,
  universityUpdateAI,
} from "../../../WebCalls";
import ConfirmModal from "../SubComponents/quarantineConfirmModal";
import Notification from "../../Shared/Notification";
import { Redo, Replay } from "@mui/icons-material";
import AbbreviationModal from "../SubComponents/AbbreviationModal";
import { isQuarantineCompany } from "../../../Helpers";
import PaginationComponent from "../../Shared/PaginationComponent";

interface QuarantineProps {
  quarantineData?: (QuarantineUniversity | QuarantineCompany)[];
  confirmedData?: QuarantineUniversity[] | QuarantineCompany[];
  loading?: boolean;
  token: string;
  selected: string;
  setLoading: (loading: boolean) => void;
  setQuarantineData: (
    data: QuarantineUniversity[] | QuarantineCompany[]
  ) => void;
  fetchQuarantineData: (
    sortType?: string,
    sortDesc?: boolean,
    Filters?: Filters[]
  ) => Promise<void>;
  fetchConfirmedData: (name?:string) => Promise<void>;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  maxCount: string;
  minCount: string;
  rejectQuery: string;
  setRejectQuery: (name:string)=>void;
}

const tabs = ["Companies", "Universities"];

const DataGrid: React.FC<QuarantineProps> = ({
  quarantineData,
  loading,
  setLoading,
  setQuarantineData,
  token,
  confirmedData,
  selected,
  fetchConfirmedData,
  fetchQuarantineData,
  totalPages,
  currentPage,
  setCurrentPage,
  maxCount,
  minCount,
  rejectQuery,
  setRejectQuery
}) => {
  const [reject1, setReject1] = useState<string>();
  const [reject2, setReject2] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<QuarantineUniversity[]>([]);
  const [showConfirmBox, setShowConfirmBox] = useState<boolean>(false);
  const [showAbbBox,setShowAbbBox] = useState<boolean>(false);
  const [infoUpdateNotify, setInfoUpdateNotify] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [errorNotification, setErrorNotification] = useState<boolean>(false);
  const [showAbbNotification, setShowAbbNotification] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [confirmNotification, setConfirmNotification] =
    useState<boolean>(false);
  const [rejectNotification, setRejectNotification] = useState<boolean>(false);
  const [headCheckboxState, setHeadCheckboxState] = useState<boolean | "minus">(
    false
  );
  const [showRejectBox, setShowRejectBox] = useState<boolean>(false);

  useEffect(() => {
    setSelectedRows([]);
    setSortOption({ name: "Name", dsc: false });
  }, [selected]);

  useEffect(() => {
    if (quarantineData && selectedRows.length === quarantineData.length) {
      setHeadCheckboxState(true);
    } else if (selectedRows.length > 0) {
      setHeadCheckboxState("minus");
    } else {
      setHeadCheckboxState(false);
    }
  }, [selectedRows, quarantineData]);

  // pagination useEffect
  useEffect(() => {
    const fetchData = async () => {
      if (sortOption) {
        await fetchQuarantineData(sortOption.name, sortOption.dsc, [
          {
            FilterType: "count",
            MinCount: parseInt(minCount),
            MaxCount: parseInt(maxCount),
          },
        ]);
      } else {
        await fetchQuarantineData(undefined, undefined, [
          {
            FilterType: "count",
            MinCount: parseInt(minCount),
            MaxCount: parseInt(maxCount),
          },
        ]);
      }
    };
    fetchData();
  }, [currentPage, selected]);

  type sort = {
    name: string;
    dsc: boolean;
  };

  const initialSortOptions: sort[] = [
    { name: "Name", dsc: true },
    { name: "State", dsc: true },
    { name: "City", dsc: true },
  ];
  const [sortOption, setSortOption] = useState<sort>();

  const handleTableSort = async (sortType: string) => {
    if (!quarantineData) {
      return;
    }

    if (sortOption && sortOption.name === sortType) {
      const newSortOption = { name: sortOption.name, dsc: !sortOption.dsc };
      setSortOption(newSortOption);
    } else {
      const newSortOption = initialSortOptions.find((s) => s.name === sortType);
      setSortOption(newSortOption);
    }
  };

  useEffect(() => {
    if (!sortOption) {
      return;
    }
    setLoading(true);
    fetchQuarantineData(sortOption.name, sortOption.dsc, [
      {
        FilterType: "count",
        MinCount: parseInt(minCount),
        MaxCount: parseInt(maxCount),
      },
    ]).then(() => {
      setLoading(false);
    });
  }, [sortOption]);

  if (loading) {
    return <Loading />;
  }

  if (!quarantineData || quarantineData.length === 0) {
    return (
      <div className="bg-blue-100 rounded-lg py-8 mt-8 dark:bg-darkbglight font-bold items-center text-center dark:text-gray-400">
        <h1>No new {selected}</h1>
      </div>
    );
  }
  const toggleRow = (data: QuarantineUniversity) => {
    setSelectedRows((prevSelectedRows) => {
      const isRowSelected = prevSelectedRows.some((row) => row.id === data.id);
      if (isRowSelected) {
        return prevSelectedRows.filter((row) => row.id !== data.id);
      } else {
        return [...prevSelectedRows, data];
      }
    });
  };

  const toggleAllRows = () => {
    if (headCheckboxState === true || headCheckboxState === "minus") {
      setSelectedRows([]);
    } else {
      setSelectedRows(quarantineData.map((u) => u));
    }
  };

  const handleRejectButton = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    event.stopPropagation();
    setReject1(id);
    setReject2(id);
    setShowRejectBox(true);
  };

  const toggleRowExpand = (index: any) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleConfirm = async (id: string,abbreviation?:string) => {
    const uni = selectedRows.find((data) => data.id === id);
    if (!uni) {
      return;
    }
    const Data = {
      Id: uni.id,
      Name: uni.name,
      State: uni.state,
      City: uni.city,
      URL: uni.url,
      IsConfirmed: true,
      Abbreviation:abbreviation
    };
    try {
      if (selected === tabs[1]) {
        const message = await confirmQuarantineUniversity(token, id, Data);
      } else {
        const message = await confirmQuarantineCompany(token, id, Data);
      }

      const updatedQuarantineData = quarantineData.filter((u) => u.id !== id);
      setQuarantineData(updatedQuarantineData);
      toggleRow(uni);
      await fetchQuarantineData();
      await fetchConfirmedData();
    } catch (error) {
      console.error(`Failed to update ${selected}:, ${error}`);
    }
  };

  const handleAbbrevationBox = async (updatedRows:QuarantineUniversity[]) => {
    for (const obj of updatedRows) {
      if (!obj.abbreviation || obj.abbreviation === "") {
        setShowAbbBox(false);
        setShowAbbNotification(true);
        return;
      }
    }
    for (const obj of updatedRows) {
      if (obj.abbreviation) handleConfirm(obj.id, obj.abbreviation);
    }
    setConfirmNotification(true);
    setShowConfirmBox(false);
    setShowAbbBox(false);
  };

  const handleMultiConfirm = async () => {
    if (selectedRows.length === 0) {
      setErrorNotification(true);
      setShowConfirmBox(false);
      return;
    }
    if(selected === tabs[0]){
      selectedRows.forEach((obj) => {
        handleConfirm(obj.id);
      });
      setConfirmNotification(true);
      setShowConfirmBox(false);
    }
    else setShowAbbBox(true);
  };

  const updateInfo = async (id: string) => {
    setIsUpdating(true);
    try {
      if (selected === tabs[0]) {
        const response = await companyUpdateAI(id);
        if (response) {
          const updatedData = quarantineData.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                linkedIn: response.linkedIn ?? item.linkedIn,
                location: response.location ?? item.location,
                revenue: response.revenue ?? item.revenue,
                stockMarket: response.stockMarket ?? item.stockMarket,
                relevance: response.relevance ?? item.relevance,
                hotStartup: response.hotStartup ?? item.hotStartup,
              };
            }
            return item;
          });
          setQuarantineData(updatedData);
          setInfoUpdateNotify(true);
        } else {
          const message = `Failed to update company AI: ${response}`;
          console.error(message);
        }
      } else {
        const response = await universityUpdateAI(id);
        if (response) {
          const updatedData = quarantineData.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                linkedIn: response.linkedIn ?? item.linkedIn,
                location: response.location ?? item.location,
                alternateNames: response.alternateNames ?? item.alternateNames,
                package: response.package ?? item.package,
                recruiters: response.recruiters ?? item.recruiters,
                degrees: response.degrees ?? item.degrees,
              };
            }
            return item;
          });
          setQuarantineData(updatedData);
        } else {
          const message = `Failed to update university AI: ${response}`;
          console.error(message);
        }
      }
    } catch (error) {
      console.error("Error updating AI:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteUni = async () => {
    const oldId = reject1 ? reject1.toString() : "";
    const newId = reject2 ==='00000000-0000-0000-0000-000000000000' ? reject2 : reject2.id.toString();
    try {
      if (selected === tabs[1]) {
        const message = await deleteQuarantineUniversity(token, oldId, newId);
        if (message) setRejectNotification(true);
      } else {
        const message = await deleteQuarantineCompany(token, oldId, newId);
        if (message) setRejectNotification(true);
      }
      const updatedquarantineData = quarantineData.filter(
        (u) => u.id !== oldId
      );
      const updatedselectedRows = selectedRows.filter((u) => u.id !== oldId);
      setSelectedRows(updatedselectedRows);
      setQuarantineData(updatedquarantineData);
      await fetchQuarantineData();
      await fetchConfirmedData();
    } catch (error) {
      console.error("Failed to delete university:", error);
    }
    setShowRejectBox(false);
  };

  return (
    <>
      <ConfirmModal
        type="danger"
        open={showRejectBox}
        setOpen={setShowRejectBox}
        onConfirm={deleteUni}
        title={`Reject ${selected === "Companies" ? "Company" : "University"}`}
        description={`Choose a ${
          selected === "Companies" ? "Company" : "University"
        }`}
        dropDownPlaceHolder={
          selected === "Companies" ? "Companies" : "Universities"
        }
        dropDownCurrentValue={reject2}
        dropDownSetCurrentValue={setReject2}
        dropDownValues={confirmedData ? confirmedData : []}
        hideOthers
        selected={selected}
        rejectQuery={rejectQuery}
        setRejectQuery={setRejectQuery}
      />

     <AbbreviationModal
        type="info"
        open={showAbbBox}
        setOpen={setShowAbbBox}
        onConfirm={handleAbbrevationBox}
        title="Confirm Universities"
        description="Set Abbreviations for Universities and Confirm."
        selectedRows = {selectedRows}
      />

      <div className="overflow-x-scroll">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-800 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 ">
            <tr>
              <th scope="col" className="w-[3rem]">
                <div className="py-2 px-5 flex">
                  <input
                    type="checkbox"
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                    checked={headCheckboxState === true}
                    ref={(input) => {
                      if (input)
                        input.indeterminate = headCheckboxState === "minus";
                    }}
                    onChange={toggleAllRows}
                  />
                  <EllipsisVerticalIcon
                    className="w-4 ml-2 cursor-pointer"
                    onClick={() => setShowConfirmBox((prev) => !prev)}
                  />
                  {showConfirmBox && (
                    <button
                      onClick={handleMultiConfirm}
                      className="absolute top-[0.1rem] left-[5rem] py-2 px-3 rounded-md text-white bg-entntblue"
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </th>
              <th className="py-2 px-3">
                <div className="flex items-center">
                  Name
                  {sortOption?.name === "Name" && sortOption.dsc ? (
                    <ArrowDownIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("Name")}
                    />
                  ) : (
                    <ArrowUpIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("Name")}
                    />
                  )}
                </div>
              </th>
              <th className="py-2 px-3">Count</th>
              <th className="py-2 px-3">
                <div className="flex items-center">
                  State
                  {sortOption?.name === "State" && sortOption.dsc ? (
                    <ArrowDownIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("State")}
                    />
                  ) : (
                    <ArrowUpIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("State")}
                    />
                  )}
                </div>
              </th>
              <th className="py-2 px-3">
                <div className="flex items-center">
                  City
                  {sortOption?.name === "City" && sortOption.dsc ? (
                    <ArrowDownIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("City")}
                    />
                  ) : (
                    <ArrowUpIcon
                      className="w-4 cursor-pointer ml-1"
                      onClick={() => handleTableSort("City")}
                    />
                  )}
                </div>
              </th>
              <th className="py-2 px-3">URL</th>
              <th className="py-2 px-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {quarantineData.map((data, index) => (
              <React.Fragment key={data.id}>
                <tr
                  onClick={() => toggleRowExpand(index)}
                  className="cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229] border-t border-gray-200"
                >
                  <td className="py-2 px-5">
                    <input
                      type="checkbox"
                      className="rounded z-10 border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
                      checked={selectedRows.includes(data)}
                      onChange={() => {
                        toggleRow(data);
                      }}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </td>
                  <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                    {data.name}
                  </td>
                  <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                    {data.count}
                  </td>
                  <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                    {data.state?.name}
                  </td>
                  <td className="px-4 py-1 text-gray-700  dark:text-white font-semibold">
                    {data.city?.name}
                  </td>
                  <td
                    className={`px-4 py-1 font-semibold ${
                      data.flag
                        ? "text-red-500"
                        : "text-gray-700 dark:text-white"
                    }`}
                  >
                    <a
                      href={
                        data.url.startsWith("http")
                          ? data.url
                          : `https:\\${data.url}`
                      }
                      className="hover:text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.url}
                    </a>
                  </td>
                  <td className="flex gap-x-[1.5rem] py-2 px-3">
                    <button
                      onClick={(event) => handleRejectButton(event, data.id)}
                      className="py-1 px-2 bg-[#ffdfdf;] text-[#8c0909;] rounded-[0.4rem] w-[4rem]"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr className="border-b border-gray-200">
                    <td colSpan={6} className="p-3">
                      <div className="overflow-hidden px-6">
                        <div className="flex justify-around gap-x-4">
                          <div className="w-1/3">
                            <p>
                              <strong>LinkedIn: </strong>
                              <a
                                href={data.linkedIn}
                                className="hover:text-blue-500"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {data.linkedIn}
                              </a>
                            </p>
                            <p>
                              <strong>Location:</strong> {data.location}
                            </p>
                            {selected === tabs[0] ? (
                              <p>
                                <strong>Revenue:</strong> {data.revenue}
                              </p>
                            ) : (
                              <p>
                                <strong>AlternateNames:</strong>{" "}
                                {data.alternateNames}
                              </p>
                            )}
                          </div>

                          {selected === tabs[0] ? (
                            <div className="w-1/3">
                              <p>
                                <strong>Stock Market:</strong>{" "}
                                {data.stockMarket}
                              </p>
                              <p>
                                <strong>Relevance:</strong> {data.relevance}
                              </p>
                              <p>
                                <strong>Hot Startup:</strong> {data.hotStartup}
                              </p>
                            </div>
                          ) : (
                            <div className="w-1/3">
                              <p>
                                <strong>Package:</strong> {data.package}
                              </p>
                              <p>
                                <strong>Recruiters:</strong> {data.recruiters}
                              </p>
                              <p>
                                <strong>Degrees:</strong> {data.degrees}
                              </p>
                            </div>
                          )}
                          {isUpdating ? (
                            <div className="loader">
                              {" "}
                              <svg
                                className="animate-spin h-5 w-5 text-blue-500"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v8H4z"
                                ></path>
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <Replay
                                className="w-4 cursor-pointer"
                                onClick={() =>
                                  updateInfo(
                                    isQuarantineCompany(data)
                                      ? data.companyId
                                      : data.id
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={(page: number) => setCurrentPage(page)}
        />
      </div>

      <Notification
        title="Confirmation Error"
        description={`Please Select a University.`}
        type="error"
        show={errorNotification}
        setShow={setErrorNotification}
      />
      <Notification
        title="Set Abbreviation"
        description={`Please Select an Abbreviation for all Universities`}
        type="error"
        show={showAbbNotification}
        setShow={setShowAbbNotification}
      />
      <Notification
        title="Confirmation Successful"
        description={`${selected} Confirmed Successfully.`}
        type="success"
        show={confirmNotification}
        setShow={setConfirmNotification}
      />
      <Notification
        title="Updation Successful"
        description={`${selected} Updated Successfully.`}
        type="success"
        show={infoUpdateNotify}
        setShow={setInfoUpdateNotify}
      />
      <Notification
        title="Rejection Successful"
        description={`${
          selected === "Companies" ? "Company" : "University"
        } Rejected Successfully.`}
        type="success"
        show={rejectNotification}
        setShow={setRejectNotification}
      />
    </>
  );
};

export default DataGrid;
