import React, { useState } from "react";
import Notification from "../../Shared/Notification";

const RangeSelectionComponent = ({
  isVisible,
  onClose,
  onConfirm,
  lowerLimitOfScore,
  upperLimitofScore,
}: {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (min: number, max: number) => void;
  lowerLimitOfScore: number;
  upperLimitofScore: number;
}) => {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [error, setError] = useState(false);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinValue(e.target.value);
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxValue(e.target.value);
  };

  const handleConfirm = () => {
    
    const min = parseFloat(minValue);
    const max = parseFloat(maxValue);

    
    if (
      !isNaN(min) &&
      !isNaN(max) &&
      !isNaN(upperLimitofScore) &&
      lowerLimitOfScore <= min &&
      upperLimitofScore >= max &&
      min <= max
    ) {
      onConfirm(min, max);
    } else {
      // Handle invalid input values
      setError(true);
    }
  };

  return (
    <div>
      <div className="w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {error && (
              <Notification
                show={error}
                setShow={setError}
                title="Error"
                description="Please enter valid numbers for minimum and maximum values."
                type="error"
              />
            )}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 ">
              <div className="text-xl font-medium text-gray-900 dark:text-white">
                Range Selection
              </div>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="p-4 md:p-5 space-y-4 flex flex-row">
              <div className=" space-y-2 ">
                <div className=" text-blue-700">
                  {"Score Range: " +
                    lowerLimitOfScore +
                    " - " +
                    upperLimitofScore}
                </div>
                <div className="flex flex-row space-x-1">
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="minInput"
                      className="text-sm text-gray-600 dark:text-gray-400"
                    >
                      Minimum Value:
                    </label>
                    <input
                      id="minInput"
                      type="number"
                      className="border rounded-lg px-3 py-2 dark:bg-gray-600 dark:text-white"
                      placeholder={lowerLimitOfScore.toString()}
                      value={minValue}
                      onChange={handleMinChange}
                    />
                  </div>
                  <div className="flex flex-col space-y-2 ">
                    <label
                      htmlFor="maxInput"
                      className="text-sm text-gray-600 dark:text-gray-400"
                    >
                      Maximum Value:
                    </label>
                    <input
                      id="maxInput"
                      type="number"
                      className="border rounded-lg px-3 py-2 dark:bg-gray-600 dark:text-white"
                      value={maxValue}
                      onChange={handleMaxChange}
                      placeholder={upperLimitofScore.toString()}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-centre p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 ">
              <button
                type="button"
                className="text-white bg-blue-700  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 hover:bg-entntorange"
                onClick={handleConfirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSelectionComponent;
