import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartOptions,
  ChartData,
} from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);


interface GradesChartProps {
  educationGrades: number[];
  experienceGrades: number[];
}

const GradesChart: React.FC<GradesChartProps> = ({ educationGrades, experienceGrades }) => {
  


  const combinedGrades = [...educationGrades, ...experienceGrades];
  
  if(combinedGrades.length===0) combinedGrades.push(0);
  
  const processedGrades =
    combinedGrades.length === 1 ? [combinedGrades[0], combinedGrades[0]] : combinedGrades;
  

  const labels: string[] = processedGrades.map((_, index) => `Point ${index + 1}`);


  let graphColor;
if (processedGrades[0] > processedGrades[processedGrades.length - 1]) {
  graphColor = '#FF0000';
} else if (processedGrades[0] < processedGrades[processedGrades.length - 1]) {
  graphColor = '#00FF00'; 
} else {
  graphColor = '#808080'; 
}
  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        label: '',
        data: processedGrades,
        borderColor: graphColor,
        backgroundColor: graphColor,
        fill: true,
        tension: 0.5,
      },
    ],
  };


  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false, 
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        spanGaps: true, 
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default GradesChart;
