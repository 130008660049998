import React from "react";
import { EditorState, Modifier } from "draft-js";

import { CheckCircleIcon } from "@heroicons/react/20/solid";

interface CustomCheckIconProps {
  onChange: (newEditorState: EditorState) => void;
  editorContent: EditorState;
}

const CustomCheckIcon = ({ onChange, editorContent }: CustomCheckIconProps) => {
  const addCheckMark = () => {
    const newContentState = Modifier.insertText(
      editorContent.getCurrentContent(),
      editorContent.getSelection(),
      "✔ ",
      editorContent.getCurrentInlineStyle()
    );
    onChange(
      EditorState.push(editorContent, newContentState, "insert-characters")
    );
  };

  return (
    <div
      onClick={addCheckMark}
      className="cursor-pointer border rounded py-0.5 px-1 bg-white dark:bg-transparent dark:border-entntblue"
    >
      <CheckCircleIcon className="h-5 w-5 text-entntblue" />
    </div>
  );
};

export default CustomCheckIcon;
