import { useState, useEffect, Fragment } from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { Role } from "../../../Models";
import useApiToken from "../../../hooks/useApiToken";
import { DeleteRole, UpdateRole } from "../../../WebCalls";
import ConfirmModal from "../../Shared/ConfirmModal";

const publishingOptions = [
  {
    name: "Published",
    description: "This job posting can be viewed by anyone who has the link.",
  },
  {
    name: "Draft",
    description: "This job posting will no longer be publicly accessible.",
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
export default function RoleSideBarEdit({ role }: { role: Role | undefined }) {
  const [selected, setSelected] = useState(publishingOptions[0]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const token = useApiToken();
  const { roleId } = useParams<{ roleId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (role)
      setSelected(role.isActive ? publishingOptions[0] : publishingOptions[1]);
  }, [role]);

  const handleChangePublishOption = (option: {
    name: string;
    description: string;
  }) => {
    if (!token) return;
    if (option !== selected) {
      const body = [
        {
          propertyName: "IsActive",
          propertyValue: String(option === publishingOptions[0]),
        },
      ];
      if (roleId === undefined) return;
      UpdateRole(roleId, body, token);
    }
  };

  const deleteOnConfirm = async () => {
    if (role?.id === undefined || !token) return;
    await DeleteRole(role.id, token);
    navigate("/roles");
    setDeleteModalIsOpen(false);
  };

  return (
    <div className="px-4 pt-4">
      <span>
        <button
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 text-white px-3 py-2 text-sm font-semibol shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-600"
          onClick={() => {
            setDeleteModalIsOpen(true);
          }}
        >
          <TrashIcon
            className="-ml-0.5 h-5 w-5 text-white"
            aria-hidden="true"
          />
          Delete Role
        </button>
      </span>

      <Listbox
        as="div"
        value={selected}
        onChange={setSelected}
        className="mt-3"
      >
        {({ open }) => (
          <>
            <div className="relative">
              <div className="inline-flex divide-x divide-entntblue rounded-md shadow-sm">
                <div className="inline-flex divide-x rounded-md shadow-sm">
                  <div className="inline-flex items-center gap-x-1.5 rounded-l-md min-w-[7rem] bg-entntblue px-3 py-2 text-white shadow-sm">
                    <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    <p className="text-sm font-semibold">{selected.name}</p>
                  </div>
                  <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-entntblue p-2 hover:bg-entntorange focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-entntblue focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                    <ChevronDownIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute left-0 z-20 -mr-1 mt-2 w-60 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                  {publishingOptions.map((option) => (
                    <Listbox.Option
                      key={option.name}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-entntorange text-white"
                            : "text-gray-900",
                          "cursor-default select-none p-4 text-sm"
                        )
                      }
                      value={option}
                      onClick={() => {
                        handleChangePublishOption(option);
                      }}
                    >
                      {({ selected, active }) => (
                        <div className="flex flex-col">
                          <div className="flex justify-between">
                            <p
                              className={
                                selected ? "font-semibold" : "font-normal"
                              }
                            >
                              {option.name}
                            </p>
                            {selected ? (
                              <span
                                className={
                                  active ? "text-white" : "text-entntblue"
                                }
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                          <p
                            className={classNames(
                              active ? "text-white" : "text-gray-500",
                              "mt-2"
                            )}
                          >
                            {option.description}
                          </p>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      <ConfirmModal
        open={deleteModalIsOpen}
        setOpen={setDeleteModalIsOpen}
        onConfirm={deleteOnConfirm}
        type="danger"
        title="Delete job"
        description=" Are you sure you want to delete this job? All of the
    data will be permanently removed. This action cannot
    be undone."
      />
    </div>
  );
}
