import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Dispatch, SetStateAction, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { Popover } from "@headlessui/react";
import { recruitmentProcess } from "./CreateRoleForm";
import { RoleStatusEditPopup } from "./RoleStatusEditPopup";
import { useLocation } from "react-router-dom";

export const reorder = (
  list: recruitmentProcess[],
  startIndex: number,
  endIndex: number
) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

const draggableCallback = (
  processes: recruitmentProcess[],
  isDraggable: boolean,
  handleRemoveProcess: (id: string) => void,
  setProcesses: Dispatch<SetStateAction<recruitmentProcess[]>>,
  handleTextEditor: (type: string) => void,
  setRecruitmentProcessId: React.Dispatch<React.SetStateAction<string>>
) => {
  return (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
    <ul
      className="lg:grid mt-10 mb-6 flex-col space-y-4 lg:space-y-0 lg:gap-6 lg:grid-cols-6"
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {processes.slice(0, processes.length - 2).map((process, index) => (
        <Draggable
          index={index}
          draggableId={process.id}
          key={process.id}
          isDragDisabled={!isDraggable}
        >
          {(provided, snapshot) => (
            <li
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`${!isDraggable ? "cursor-pointer" : ""}`}
            >
              <Popover className="relative flex justify-center">
                {({ open }) => (
                  <>
                    <Popover.Button
                      as="div"
                      className=" w-[12rem] lg:col-span-1 flex rounded-md shadow-sm"
                    >
                      <div className="flex w-12  bg-entntblue flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white border-gray-400 border dark:text-gray-200">
                        {index + 1}
                      </div>
                      <div
                        className={
                          "flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t dark:border-gray-400 bg-white dark:bg-darkbglight "
                        }
                      >
                        <div className="flex-1 truncate px-2 py-2 text-sm">
                          <span className="font-medium text-gray-900 dark:text-gray-400">
                            {process.title}
                          </span>
                        </div>
                        {isDraggable && (
                          <div className="flex-shrink-0">
                            <span
                              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                handleRemoveProcess(process.id);
                                localStorage.removeItem(process.id);
                              }}
                            >
                              <XMarkIcon
                                className="h-5 w-5 cursor-pointer"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    </Popover.Button>
                    <RoleStatusEditPopup
                      open={open}
                      setProcesses={setProcesses}
                      processes={processes}
                      process={process}
                      handleTextEditor={handleTextEditor}
                      setRecruitmentProcessId={setRecruitmentProcessId}
                    />
                  </>
                )}
              </Popover>
            </li>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
      <li className="  relative flex justify-center  ">
        <div className=" flex w-80">
          <div className=" flex w-12  bg-entntorange flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white border-gray-400 border dark:text-gray-200">
            {processes.length - 1}
          </div>
          <div
            className={
              "flex flex-1 truncate items-center justify-between  rounded-r-md border-b border-r border-t dark:border-gray-400 bg-white dark:bg-darkbglight "
            }
          >
            <div className="flex-1  truncate px-2 py-2 text-sm">
              <span className="font-medium text-gray-900 dark:text-gray-400">
                Offered/Disqualified
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};
export default function CreateRoleStatus({
  processes,
  setProcesses,
  handleTextEditor,
  setRecruitmentProcessId,
}: {
  processes: recruitmentProcess[];
  setProcesses: Dispatch<SetStateAction<recruitmentProcess[]>>;
  handleTextEditor: (type: string) => void;
  setRecruitmentProcessId: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [currentProcessValue, setCurrentProcessValue] = useState("");
  const location = useLocation();

  const isEditRole = location.pathname.includes("edit");

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newProcesses = reorder(
      processes,
      result.source.index,
      result.destination.index
    );

    setProcesses([...newProcesses]);
  };

  const handleAddProcess = () => {
    let lastIndex = processes.length - 2;
    setProcesses([
      // ...processes,
      // { id: uuidv4(), title: currentProcessValue, email: "" },
      ...processes.slice(0, lastIndex),
      { id: uuidv4(), title: currentProcessValue, email: "" },
      ...processes.slice(lastIndex),
    ]);
    setCurrentProcessValue("");
  };

  const handleRemoveProcess = (id: string) => {
    const newProcesses = processes.filter((el) => el.id !== id);
    setProcesses(newProcesses);
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 sm:pt-12">
      <label
        htmlFor="status-title"
        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 dark:text-gray-400"
      >
        Recruitment Process
        <span className="text-red-500"> *</span>
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        {!isEditRole && (
          <div className="flex space-x-8">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-indigo-600  col-span-2 w-full  dark:ring-gray-500 ">
              <input
                type="text"
                name="status-title"
                id="status-title"
                className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-600 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder={"Enter the process title here and add the process"}
                onChange={(e) => {
                  setCurrentProcessValue(e.target.value);
                }}
                value={currentProcessValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && currentProcessValue.trim() !== "") {
                    e.preventDefault();
                    handleAddProcess();
                  }
                }}
              />
            </div>
            <button
              onClick={handleAddProcess}
              type="button"
              className="text-white cursor-pointer bg-entntblue rounded-md hover:bg-entntorange disabled:bg-gray-300 disabled:cursor-not-allowed"
              disabled={currentProcessValue.trim() === ""}
            >
              <PlusIcon height={34} />
            </button>
          </div>
        )}
      </div>
      <div className="hidden lg:block col-span-3">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="recruitmentProcess" direction="horizontal">
            {draggableCallback(
              processes,
              !isEditRole,
              handleRemoveProcess,
              setProcesses,
              handleTextEditor,
              setRecruitmentProcessId
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="lg:hidden col-span-3">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="recruitmentProcess" direction="vertical">
            {draggableCallback(
              processes,
              !isEditRole,
              handleRemoveProcess,
              setProcesses,
              handleTextEditor,
              setRecruitmentProcessId
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}
