import React from "react";

const TimelineLoader = () => {
  return (
    <div className="relative flex w-full animate-pulse gap-2 p-4">
      <div className="flex flex-col items-center w-full gap-4">
        <div className="h-12 w-12 rounded-full bg-slate-300"></div>
        <div className="h-5 w-full rounded-lg bg-slate-300 text-sm"></div>
      </div>
      <div className="flex flex-col items-center w-3/5 gap-4">
        <div className="h-12 w-12 rounded-full bg-slate-300"></div>
        <div className="h-5 w-full rounded-lg bg-slate-300 text-sm"></div>
        <div className="w-full -mt-2">
          <div className="flex flex-col items-center w-1/3 gap-4">
            <div className="h-4 w-full rounded-lg bg-slate-300 text-sm"></div>
            <div className="h-12 w-12 rounded-full bg-slate-300"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineLoader;
