export default function Candidates() {
  return (
    <div>
      <header className="bg-gray-50 dark:bg-darkbglight py-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap">
          <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight dark:text-gray-300">
            Candidates
          </h1>
        </div>
      </header>
    </div>
  );
}
