import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  GetCities,
  GetRole,
  GetStatusForRole,
  getRankType,
} from "../../WebCalls";
import { City, Role, RoleStatusCandidateCount } from "../../Models";
import RoleCandidates from "./RoleCandidates/RoleCandidates";
import Notification from "../Shared/Notification";
import useApiToken from "../../hooks/useApiToken";
import "react-datepicker/dist/react-datepicker.css";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/20/solid";
import RoleSidebarCandidate from "./Sidebar/RoleSidebarCandidate";
import RightSidebar from "./Sidebar/RightSidebar";

import RoleSideBarEdit from "./Sidebar/RoleSidebarEdit";
import RoleSideBarHeader from "./Sidebar/RoleSidebarHeader";
import { NIL } from "uuid";
import CreateRole from "./CreateRole/CreateRole";

const tabs = ["Candidates", "Edit"];
export const initialRankType = { id: NIL, name: "Overall" };

export default function RolePage() {
  const [status, setStatus] = useState<RoleStatusCandidateCount>({
    roleStatuses: [],
    totalCandidateCount: 0,
  });
  const [role, setRole] = useState<Role>();
  const [cities, setCities] = useState<City[]>([]);

  const [filterQuery, setFilterQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rightfilter, setrightfilter] = useState("");

  const [leftfilter, setleftfilter] = useState("");

  const [isSidebarOpen, setIsSideBarOpen] = useState(true);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [currentTab, setCurrentTab] = useState("Candidates");
  const [statusFilterIsAll, setStatusFilterIsAll] = useState(true);

  const [rankType, setRankType] = useState(initialRankType);

  const [selectedColumns, setSelectedColumns] = useState<any>([]);
  const [isRightSidebarOpen, setIsRightSideBarOpen] = useState(false);

  const token = useApiToken();
  const { roleId } = useParams<{ roleId: string }>();
  const location = useLocation();

  useEffect(() => {
    var addd = leftfilter + rightfilter;
    setFilterQuery(addd);
  }, [rightfilter, leftfilter]);

  useEffect(() => {
    if (location.pathname.includes("/candidates")) setCurrentTab(tabs[0]);
    if (location.pathname.includes("/edit")) setCurrentTab(tabs[1]);
  }, [location.pathname]);

  useEffect(() => {
    if (!token) return;
    getRankType(token).then((res) => {
      const rts = [initialRankType, ...res];
      const rankType = rts.find((r) => r.id === (role?.rankTypeId ?? NIL));
      if (rankType) setRankType(rankType);
    });
  }, [token, role?.rankTypeId]);

  useEffect(() => {
    if (!roleId || !token) return;
    GetRole(roleId, token).then((rl) => {
      setRole(rl);
    });
    GetCities().then((c) => {
      setCities(c);
    });
  }, [roleId, showNotification, token]);

  useEffect(() => {
    if (!roleId || !token) return;
    GetStatusForRole(roleId, token).then((rs) => {
      setStatus(rs);
    });
  }, [roleId, token]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isScrollPresent = documentHeight > windowHeight;
      const isAtTop = window.scrollY === 0;
      setScrollToTop(isScrollPresent && !isAtTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="bg-transparent">
        <div className="flex relative">
          <aside
            className={`dark:border-gray-500 fixed z-10 top-[4.1rem] block h-[calc(100vh-4rem)] left-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[18rem] ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10 z-20 ${
                isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
              } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsSideBarOpen(!isSidebarOpen);
              }}
            >
              {isSidebarOpen ? (
                <ChevronDoubleLeftIcon width={16} />
              ) : (
                <ChevronDoubleRightIcon width={16} />
              )}
            </div>
            <div
              className={`h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg`}
            >
              <RoleSideBarHeader role={role} currentTab={currentTab} />
              {currentTab === "Candidates" ? (
                <RoleSidebarCandidate
                  cities={cities}
                  status={status}
                  rankType={rankType}
                  handleStatusFilterIsAll={(isAll: boolean) => {
                    setStatusFilterIsAll(isAll);
                  }}
                  handleFilterQuery={(query) => {
                    setleftfilter(query);
                  }}
                  handleSearchQuery={(query) => setSearchQuery(query)}
                  setSelectedCol={setSelectedColumns}
                />
              ) : (
                role && <RoleSideBarEdit role={role} />
              )}
            </div>
          </aside>

          <main
            className={`${
              !isSidebarOpen && !isRightSidebarOpen
                ? "w-full mx-8"
                : isSidebarOpen
                  ? "relative sm:transform sm:translate-x-[18rem] sm:w-[calc(100%-18rem)]"
                  : "w-full mx-8"
            } transition-all duration-200 ease-out`}
          >
            {currentTab === "Edit" ? (
              role && <CreateRole />
            ) : (
              <RoleCandidates
                filterQuery={filterQuery}
                searchQuery={searchQuery}
                status={status}
                rankTypeId={rankType.id}
                statusFilterIsAll={statusFilterIsAll}
                handleStatusUpdate={(s) => {
                  setStatus(s);
                }}
                selectedColumns={selectedColumns}
              />
            )}
          </main>
          <aside
            className={`dark:border-gray-500 fixed top-[4.1rem] block h-[calc(100vh-4rem)] right-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[18rem] ${
              isRightSidebarOpen ? "translate-x-0" : "translate-x-[100%]"
            }`}
          >
            <div
              className={`absolute top-10  ${
                isRightSidebarOpen ? "-left-2 shadow-md" : "-left-4 shadow-xl"
              } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
              onClick={() => {
                setIsRightSideBarOpen(!isRightSidebarOpen);
              }}
            >
              {isRightSidebarOpen ? (
                <ChevronDoubleRightIcon width={16} />
              ) : (
                <ChevronDoubleLeftIcon width={16} />
              )}
            </div>

            <div className="h-full bg-white thin-scroll overflow-y-auto dark:bg-darkbg">
              <RightSidebar
                handleStatusFilterIsAll={(isAll: boolean) => {
                  setStatusFilterIsAll(isAll);
                }}
                handleFilterQuery={(query) => {
                  setrightfilter(query);
                }}
              />
            </div>
          </aside>
        </div>

        {currentTab === tabs[0] && scrollToTop && (
          <button
            className="bg-entntblue h-6 w-6 rounded-full sticky left-[95%] bottom-14 text-white transform transition-all hover:-translate-y-1"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ChevronDoubleUpIcon />
          </button>
        )}
      </div>
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title="Updated Successfully"
        description="Role has been updated successfully"
        type="info"
      />

      {currentTab === tabs[0] && scrollToTop && (
        <button
          className="bg-entntblue h-6 w-6 rounded-full sticky left-[95%] bottom-14 text-white transform transition-all hover:-translate-y-1"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <ChevronDoubleUpIcon />
        </button>
      )}
    </>
  );
}
