import {
  Candidate,
  CandidateStatus,
  CareerTimelineType,
  Education,
  Experience,
  Role,
  RoleStatusCandidateCount,
} from "../../../Models";
import { useNavigate, useParams } from "react-router-dom";
import GradesChart from "./GradeCharts";
import { RoleStatusType, dateConverter } from "../../../Helpers";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { CandidateAction } from "./RoleCandidates";
import { useEffect, useRef, useState } from "react";
import TimelineModal from "../../Shared/TimelineModal";
import CandidateDetailModal from "./Candidatedetailsmodel";
import {
  GetCandidateStatus,
  GetCandidateTimeline,
  GetRole,
  GetRoleStatus,
} from "../../../WebCalls";
import useApiToken from "../../../hooks/useApiToken";
import RecruitmentTimelineModal from "../../Shared/RecruitmentTimelineModal/RecruitmentTimelineModal";
import CareerTimeline from "../../Candidates/CareerTimeline";
import Grade from "./Grade";
import { NIL } from "uuid";
import StraightTimeline from "../../Candidates/StraightTimerline";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export default function RoleCandidatesList({
  candidates,
  selectedCandidate,
  handleSelectedCandidate,
  handleUpdateCandidates,
  status,
  colomns,
  agebased,
  expbased,
}: {
  candidates: Candidate[];
  selectedCandidate: Candidate[];
  handleSelectedCandidate: (candidate: CandidateAction[]) => void;
  handleUpdateCandidates: (
    candidatesUpdate: CandidateStatus[],
    gradeStatusUpdate?: boolean
  ) => void;
  status: RoleStatusCandidateCount;
  colomns: number;
  agebased: boolean;
  expbased: boolean;
}) {
  const [role, setRole] = useState<Role>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timelineLoading, setTimelineLoading] = useState(false);
  const [timelineError, setTimelineError] = useState(false);
  const [timelineData, setTimelineData] = useState<CareerTimelineType | null>(
    null
  );

  const [openTimeline, setOpenTimeline] = useState(false);
  const [hoveredCandidate, setHoveredCandidate] = useState<any>();
  const [timelineData2, setTimelineData2] = useState<any[]>([]);
  const [candidateStatus, setCandidateStatus] = useState<any[]>([]);

  const [hoverdetails, sethoverdetails] = useState<any>();

  const timeoutIdRef = useRef<number | null>(null);
  const navigate = useNavigate();
  const token = useApiToken();
  const { roleId } = useParams();

  useEffect(() => {
    if (!roleId || !token) return;
    GetRole(roleId, token).then((rl) => {
      setRole(rl);
    });
  }, [roleId, token]);

  useEffect(() => {
    if (!role || !token) return;
    setTimelineData2((prevState) => [
      ...prevState.map((item) => {
        if (Object.keys(item)[0] === "roleCreated") {
          return {
            roleCreated: {
              date: role?.date,
              color: "blue",
              radius: 50,
              text: "Job Posted",
            },
          };
        }
        return item;
      }),
    ]);

    if (hoveredCandidate) {
      GetCandidateStatus(hoveredCandidate.id, token).then((cs) => {
        const sortedStatuses = cs?.sort((a: any, b: any) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          }
          return a.roleStatus.order - b.roleStatus.order;
        });
        Promise.all(
          sortedStatuses
            ?.filter((s) => s.roleId === roleId)
            ?.map(async (status: CandidateStatus) => {
              const rs = await GetRoleStatus(status.roleStatusId, token);
              return {
                name: rs.name,
                date: status.date,
              };
            })
        ).then((si) => {
          si?.unshift({
            name: "roleCreated",
            date: role.date,
          });
          setCandidateStatus(si);
        });
      });
    }
  }, [role, hoveredCandidate, token]);

  const containsSelectedCandidates = (sc: CandidateAction) => {
    for (var i = 0; i < selectedCandidate.length; i++) {
      if (selectedCandidate[i].id === sc.id) {
        return true;
      }
    }
    return false;
  };

  const getExperienceRanking = (exp: Experience) => {
    let ranking = 0;
    if (role?.rankTypeId === null) role.rankTypeId = NIL;
    exp.companyLocation.company.companyRankings?.forEach((cr) => {
      if (cr.rankTypeId === role?.rankTypeId)
        ranking = cr.rankCategory.rankNumber;
    });
    return ranking;
  };

  const getEducationRanking = (edu: Education) => {
    let ranking = 0;
    if (role?.rankTypeId === null) role.rankTypeId = NIL;
    edu.university.universityRankings?.forEach((ur) => {
      if (ur.rankTypeId === role?.rankTypeId)
        ranking = ur.rankCategory.rankNumber;
    });
    return ranking;
  };
  const getLastKnownPos = (candidate: Candidate) => {
    const lastExperience =
      candidate.experience[candidate.experience.length - 1];
    const lastEducation = candidate.education[candidate.education.length - 1];

    if (candidate.current === null) {
      if (candidate.experience.length !== 0 && lastExperience.relevant) {
        return {
          ranking: getExperienceRanking(lastExperience),
          type: "exp",
          value: lastExperience,
        };
      } else if (candidate.education.length !== 0) {
        return {
          ranking: getEducationRanking(lastEducation),
          type: "edu",
          value: lastEducation,
        };
      }
    } else if (candidate.current === "working") {
      return {
        ranking: getExperienceRanking(lastExperience),
        type: "exp",
        value: lastExperience,
      };
    } else if (candidate.current === "studying") {
      return {
        ranking: getEducationRanking(lastEducation),
        type: "edu",
        value: lastEducation,
      };
    }
  };

  const getLatestStatus = (latestStatusId: string) => {
    const latestStatus = status.roleStatuses.find(
      (rs) => rs.id === latestStatusId
    );
    if (latestStatus) return latestStatus;
  };

  const getColorClassByPercentile = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#1E3F66]";
    } else if (percentile <= 25) {
      return "bg-[#2E5984]";
    } else if (percentile <= 50) {
      return "bg-[#528AAE]";
    } else if (percentile <= 75) {
      return "bg-[#73A5C6]";
    } else if (percentile <= 90) {
      return "bg-[#91BAD6]";
    } else {
      return "bg-[#BCD2E8]";
    }
  };

  const getColorClassBySkillScore = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#bf8300]";
    } else if (percentile <= 25) {
      return "bg-[#ea9f00]";
    } else if (percentile <= 50) {
      return "bg-[#ffb515]";
    } else if (percentile <= 75) {
      return "bg-[#ffc240]";
    } else if (percentile <= 90) {
      return "bg-[#ffd06a]";
    } else {
      return "bg-[#ffdd95]";
    }
  };

  const handleTimelineClick = async (
    e: React.MouseEvent,
    candidate: Candidate
  ) => {
    e.preventDefault();
    setTimelineLoading(true);
    try {
      if (roleId !== undefined && token !== undefined) {
        const candidateTimeline = await GetCandidateTimeline(
          roleId,
          candidate.id,
          token
        );
        setTimelineData(candidateTimeline);
        setTimelineError(false);
        setTimelineLoading(false);
      }
    } catch (error) {
      console.error("Error fetching candidate timeline:", error);
      setTimelineError(true);
    }
    setIsModalOpen(true);
  };
  const onConfirmTimeline = () => {
    setOpenTimeline(false);
  };

  const showGrades = (candidate: Candidate) => {
    if (candidate.latestStatusId) {
      const isOfferedOrHired =
        getLatestStatus(candidate.latestStatusId)?.name ===
          RoleStatusType.hired ||
        getLatestStatus(candidate.latestStatusId)?.name ===
          RoleStatusType.rejected;

      return isOfferedOrHired
        ? candidate.candidateStatuses.length > 2
        : candidate.candidateStatuses.length > 1;
    } else return false;
  };
  return (
    <>
      <tbody className="divide-y divide-gray-200 bg-white dark:bg-darkbg dark:divide-gray-600">
        {candidates.map((candidate, index) => (
          <tr
            key={candidate.id}
            className={`
            ${
              containsSelectedCandidates(candidate)
                ? "bg-gray-50 dark:bg-[#1c2229]"
                : ""
            } cursor-pointer hover:bg-gray-50 dark:hover:bg-[#1c2229]`}
            onClick={() => {
              navigate(`/roles/${roleId}/candidates/${candidate.id}`);
            }}
          >
            <td
              className="relative px-7 sm:w-12 sm:px-6 cursor-default"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {containsSelectedCandidates(candidate) ? (
                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
              ) : (
                <>
                  {candidate.latestStatusId &&
                    getLatestStatus(candidate.latestStatusId)?.name ===
                      RoleStatusType.hired && (
                      <div className="rounded-sm absolute left-4 top-1/2 -mt-2 h-4 w-4 flex justify-center items-center bg-green-600 z-10 text-white">
                        <CheckIcon />
                      </div>
                    )}
                  {candidate.latestStatusId &&
                    getLatestStatus(candidate.latestStatusId)?.name ===
                      RoleStatusType.rejected && (
                      <div className="rounded-sm absolute left-4 top-1/2 -mt-2 h-4 w-4 flex justify-center items-center bg-red-600 z-10 text-white">
                        {" "}
                        <XMarkIcon />
                      </div>
                    )}
                </>
              )}

              <input
                type="checkbox"
                className={`z-[11] bg-transparent absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 `}
                value={candidate.email}
                checked={containsSelectedCandidates(candidate)}
                onChange={(e) =>
                  handleSelectedCandidate(
                    e.target.checked
                      ? [...selectedCandidate, candidate]
                      : selectedCandidate.filter((p) => p !== candidate)
                  )
                }
              />
            </td>
            <td
              className={classNames(
                "whitespace-nowrap text-sm font-medium",
                containsSelectedCandidates(candidate)
                  ? "text-indigo-600"
                  : "text-gray-900 dark:text-gray-200"
              )}
            >
              <div
                className="relative"
                onMouseEnter={(e) => {
                  timeoutIdRef.current = window.setTimeout(() => {
                    handleTimelineClick(e, candidate);
                  }, 500);
                }}
                onMouseLeave={(e) => {
                  if (timeoutIdRef.current) {
                    window.clearTimeout(timeoutIdRef.current);
                  }
                  setTimelineData(null);
                  setIsModalOpen(false);
                }}
              >
                {candidate.firstName} {candidate.lastName}
                {timelineData?.candidateId === candidate.id && (
                  <TimelineModal
                    open={isModalOpen}
                    setOpen={setIsModalOpen}
                    content={timelineData}
                    loading={timelineLoading}
                    timelineError={timelineError}
                    timeline={
                      timelineData?.timeline.length <= 3 ? (
                        <StraightTimeline timeline={timelineData?.timeline} />
                      ) : (
                        <CareerTimeline
                          timeline={timelineData?.timeline || []}
                        />
                      )
                    }
                  />
                )}
              </div>
            </td>
            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <div className="flex">
                <span
                  className={`${
                    candidate.workLocation2City ? "max-w-20 truncate" : ""
                  }`}
                  title={
                    candidate.workLocation1City.name +
                    (candidate.workLocation2City
                      ? ", " + candidate.workLocation2City.name
                      : "")
                  }
                >
                  {candidate.workLocation1City.name}
                </span>
              </div>
            </td>

            <td
              className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
              onMouseEnter={(e) => {
                timeoutIdRef.current = window.setTimeout(() => {
                  setOpenTimeline(true);
                  setHoveredCandidate(candidate);
                }, 500);
              }}
              onMouseLeave={(e) => {
                if (timeoutIdRef.current) {
                  window.clearTimeout(timeoutIdRef.current);
                }
                setHoveredCandidate(null);
                setOpenTimeline(false);
              }}
            >
              <span
                className={`relative items-center justify-center h-11 w-11  inline-flex p-2 text-white text-xs font-bold rounded-full ${getColorClassByPercentile(candidate.agePercentile)}`}
              >
                {Math.floor(candidate.age)}
                {hoveredCandidate && hoveredCandidate.id === candidate.id && (
                  <RecruitmentTimelineModal
                    openTimeline={openTimeline}
                    setOpenTimeline={setOpenTimeline}
                    onConfirmTimeline={onConfirmTimeline}
                    title=""
                    description=""
                    timelineData={timelineData2}
                    role={role}
                    status={status}
                    candidateStatus={candidateStatus}
                    hoveredCandidate={hoveredCandidate}
                  />
                )}
              </span>
            </td>
            <td className="whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
              <div
                className={`flex items-center justify-center h-12 w-12 rounded-full border border-white ${getColorClassByPercentile(100 - candidate.expPercentileforsameage)}`}
              >
                <div
                  className={`flex items-center justify-center h-8 w-8 text-xs font-bold rounded-full border border-white 
    ${candidate.experiencePercentileMonths < 25 ? "text-gray-700" : "text-white"} 
    ${getColorClassByPercentile(100 - candidate.experiencePercentileMonths)}`}
                >
                  {candidate.experienceInMonths}
                </div>
              </div>
            </td>

            <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
              <div className="text-sm text-gray-700 dark:text-gray-300">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.combinePercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.combinedPercentileBasedAge)}`
                        : ""
                    }`}
                  >
                    <span
                      className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(100 - candidate.combinedGradePercentile)}`}
                      onMouseEnter={() => sethoverdetails(candidate)}
                      onMouseLeave={() => sethoverdetails(null)}
                    >
                      {candidate.combinedGrade.toFixed(1)}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {hoverdetails && hoverdetails.id === candidate.id && (
                  <CandidateDetailModal
                    isOpen={true}
                    candidate={hoverdetails}
                  />
                )}
              </div>
            </td>
            {(colomns === 1 || colomns === 2) && (
              <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.educationPercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.educationPercentileBasedAge)}`
                        : ""
                    }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.educationPercentileSameRole)}`}
                      >
                        {candidate.totalEducationGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )}

            {colomns === 2 && (
              <td className=" whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 ">
                <div
                  className={`${
                    expbased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedEXP)}`
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      agebased
                        ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedAge)}`
                        : ""
                    }`}
                  >
                    <div className="">
                      <span
                        className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileSameRole)}`}
                      >
                        {candidate.totalExperienceGrade.toFixed(1)}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            )}

            <td className="text-sm text-gray-700 dark:text-gray-300">
              <div
                className={`${
                  expbased
                    ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.gapPercentileBasedEXP)}`
                    : ""
                }`}
              >
                <div
                  className={`${
                    agebased
                      ? `flex items-center justify-center h-12 w-12 rounded-full border border-gray-200  ${getColorClassByPercentile(candidate.gapmonthspercentileAge)}`
                      : ""
                  }`}
                >
                  <div
                    className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-white ${getColorClassByPercentile(candidate.gappercentile)}`}
                  >
                    {candidate.totalGapMonths}
                  </div>
                </div>
              </div>
            </td>

            <td className=" whitespace-nowrap  dark:text-gray-300 flex items-center justify-center h-11 w-11  text-white text-xs font-bold rounded-full border-2 border-black my-[5.5px]">
              <GradesChart
                educationGrades={candidate.educationGrades}
                experienceGrades={candidate.experienceGrades}
              />
            </td>
            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <span
                className={`  items-center justify-center h-11 w-11  inline-flex p-2 text-white text-xs font-bold rounded-full ${getColorClassBySkillScore(candidate.scorePercentile)}`}
              >
                {candidate.score !== null ? candidate.score : "-"}
              </span>
            </td>

            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {showGrades(candidate) ? (
                <Grade
                  handleUpdateCandidates={handleUpdateCandidates}
                  roleStatuses={status.roleStatuses}
                  candidate={candidate}
                />
              ) : (
                <span className="text-xs">N/A</span>
              )}
            </td>
            {/* <td
              className={`pl-4 whitespace-nowrap py-2 text-sm text-gray-500 dark:text-gray-300`}
            >
              {candidate.assignmentSubmission ? (
                <a
                  href={candidate.assignmentSubmission.assignmentLink}
                  target="blank"
                  className="font-medium text-entntblue hover:text-entntorange"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  GitHub
                </a>
              ) : (
                "-"
              )}
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {candidate.yearsOfExperience === 0
                ? candidate.expectedGraduationDate
                  ? dateConverter(candidate.expectedGraduationDate)
                  : 0 + " years"
                : candidate.yearsOfExperience + " years"}
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <div className="flex relative">
                <span
                  style={
                    getLastKnownPos(candidate) !== undefined
                      ? getLastKnownPos(candidate)?.type === "edu"
                        ? getStyleForUniversity(
                            getLastKnownPos(candidate)?.ranking ?? 0,
                            (getLastKnownPos(candidate)?.value as Education)
                              .postGrad
                          )
                        : getStyleForCompany(
                            getLastKnownPos(candidate)?.ranking ?? 0
                          )
                      : undefined
                  }
                  className={`px-2 py-[2px] rounded-md text-xs truncate max-w-40`}
                >
                  {getLastKnownPos(candidate) !== undefined &&
                    (getLastKnownPos(candidate)?.type === "edu"
                      ? (getLastKnownPos(candidate)?.value as Education)
                          .university.name
                      : (getLastKnownPos(candidate)?.value as Experience)
                          .companyLocation.company.name)}
                </span>
              </div>
            </td> */}
            {/* <td className="whitespace-nowrap text-sm text-gray-500">
              {getLastKnownPos(candidate) !== undefined &&
              getLastKnownPos(candidate)?.type === "edu"
                ? (getLastKnownPos(candidate)?.value as Education).period
                : getExpTime(getLastKnownPos(candidate)?.value as Experience)}
            </td> */}
            <td className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {candidate.expectedGraduationDate
                ? dateConverter(candidate.expectedGraduationDate)
                : (candidate.noticePeriod ?? 0) + " mon"}
            </td>
            {/* <td className="whitespace-nowrap text-sm text-gray-500">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {candidate.candidateStatuses[
                  candidate.candidateStatuses.length - 1
                ]?.date &&
                  dateConverter(
                    candidate.candidateStatuses[
                      candidate.candidateStatuses.length - 1
                    ].date
                  )}
              </p>
            </td> */}
          </tr>
        ))}
      </tbody>
    </>
  );
}
