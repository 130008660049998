import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { Fragment, memo } from "react";
import { CategoryFull } from "../../../Models";

  const UpdateRanking = ({
    rankCategoryLabels,
    handleEditRankLabel,
    selectedColumn,
    selected
  }: {
    selectedColumn?: string;
    rankCategoryLabels: CategoryFull[];
    handleEditRankLabel: (category: CategoryFull, selected?: string) => void;
    selected?:string
  }) => {

  return (
    <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          className="inline-flex w-full justify-center rounded-md text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 mt-1"
        >
            <ChevronDownIcon
              className={`h-5 w-5 ${selectedColumn ? 'text-white': 'text-gray-500'} dark:text-gray-100 hover:text-violet-100`}
              aria-hidden="true"
            />
        </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {rankCategoryLabels && rankCategoryLabels.length > 0 ? (
          <Menu.Items
            className={`z-50 absolute ${selectedColumn ? "right-0" : "left-0"} w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg border border-gray-200 focus:outline-none`}
          >
            
            {rankCategoryLabels.map((category) => {
              return (
                <div className="px-1 py-1 " key={category.id}>
                  <Menu.Item key={category.id}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-entntblue !text-white" : "text-gray-700"
                        } group flex w-full items-center justify-between rounded-md px-2 py-1 text-sm ${selected === category.id ? "!text-entntblue font-medium": ""} font-normal`}
                        onClick={() =>
                          handleEditRankLabel(category, selectedColumn)
                        }
                      >
                        {category.title}
                        {selected === category.id && (
                            <CheckIcon className="h-5" />
                          )}
                        </button>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        ) : (
          <div className={`z-50 absolute ${selectedColumn ? "right-0" : "left-0"} bg-white text-gray-700 font-normal text-sm w-40 p-2 border border-gray-200 shadow-md rounded-md`}>
            No ranking is present. Please define some ranking categories.{" "}
          </div>
        )}
      </Transition>
    </Menu>
  );
}

export default memo(UpdateRanking);
