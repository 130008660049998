import React, { useState } from "react";
import { Candidate } from "../../../Models";

interface CandidateDetailModalProps {
  isOpen: boolean;
  candidate: Candidate;
}

const CandidateDetailModal: React.FC<CandidateDetailModalProps> = ({
  isOpen,
  candidate,
}) => {
  if (!candidate) return null;

  const getColorClassByPercentile = (percentile: any) => {
    if (percentile <= 10) {
      return "bg-[#1E3F66]"; 
    } else if (percentile <= 25) {
      return "bg-[#2E5984]"; 
    } else if (percentile <= 50) {
      return "bg-[#528AAE]"; 
    } else if (percentile <= 75) {
      return "bg-[#73A5C6]";
    } else if (percentile <= 90) {
      return "bg-[#91BAD6]";
    } else {
      return "bg-[#BCD2E8]"; 
    }
  };

  return (
    <div className="fixed bg-white rounded-lg p-4 shadow-md border-2 border-color-black z-10" >
      <table className="table-auto border-collapse w-full text-center ">
        <thead>
          <tr>
            <th className="px-4 font-medium">EduGrade</th>
            <th className="px-4 font-medium">WorkGrade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4">
              <div className="">
                <div
                  className={`flex items-center justify-center h-16 w-16 rounded-full ${getColorClassByPercentile(100 - candidate.educationPercentileBasedEXP)}`}
                >
                  <div
                    className={`flex items-center justify-center h-12 w-12 p-1 rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.educationPercentileBasedAge)}`}
                  >
                    <span
                      className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(candidate.educationPercentileSameRole)}`}
                    >
                      {candidate.totalEducationGrade.toFixed(1)}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td className="px-4 ">
              <div className="">
                <div
                  className={`flex items-center justify-center h-16 w-16 rounded-full ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedEXP)}`}
                >
                  <div
                    className={`flex items-center justify-center h-12 w-12 p-1 rounded-full  border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileBasedAge)}`}
                  >
                    <span
                      className={`flex items-center justify-center h-8 w-8 text-white text-xs font-bold rounded-full border border-gray-200 ${getColorClassByPercentile(100 - candidate.experiencePercentileSameRole)}`}
                    >
                      {candidate.totalExperienceGrade.toFixed(1)}
                    </span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CandidateDetailModal;
