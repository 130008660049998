export default function Dashboard() {
  return (
    <div className="border">
      <div>
        <iframe
          title="ENTNT.careers.report"
          className="w-full h-screen"
          src="https://app.powerbi.com/reportEmbed?reportId=167f3695-e4ab-4370-8814-42a059e146a5&autoAuth=true&ctid=246d1166-294d-4f52-83df-388a351a6359"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  );
}
