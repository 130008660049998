import React from "react";
import { useState,useEffect } from "react";
import { fetchComments } from "../../WebCalls";

interface ReviewFormProps {
    onCommentSubmit: (comment: string) => void;
    previousReview:string
    candidateId:any
    roleId:any
  }

const Review: React.FC<ReviewFormProps> = ({ onCommentSubmit ,previousReview}) => {

    const [comment, setComment] = useState<string>(previousReview);
     

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.target.value);
    };
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onCommentSubmit(comment);
      setComment('');
    };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
          <label htmlFor="comment" className="sr-only">
            Your comment
          </label>
          <textarea
            id="comment"
            rows={4}
            value={comment} 
            onChange={handleCommentChange} 
            className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
            placeholder='write your thoughts'
            required
          />
        </div>
        <div className="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
          <button
            type="submit"
            disabled={comment.trim() === ""}
            className="inline-flex cursor-pointer items-center justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-slate-200 disabled:cursor-not-allowed"          >
            Post
          </button>
      
          <div className="flex ps-0 space-x-1 rtl:space-x-reverse sm:ps-2"></div>
        </div>
      </div>
    </form>
  );
};

export default Review;
