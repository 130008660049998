import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequest } from "../authProvider";

export default function useGraphApiToken() {
  const { instance } = useMsal();
  const [graphToken, setGraphToken] = useState<string>();
  useEffect(() => {
    const getToken = async () => {
      const currentAccount = instance.getActiveAccount();
      if (!currentAccount) return;
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: currentAccount,
      });
      setGraphToken(response.accessToken);
    };

    getToken();
  }, [instance]);
  return graphToken;
}
