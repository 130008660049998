import {
  ForwardRefExoticComponent,
  Fragment,
  RefAttributes,
  SVGProps,
  useEffect,
  useState,
} from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Candidate, CandidateStatus, Role, RoleStatus } from "../../Models";
import {
  CreateCandidateStatus,
  GetCandidate,
  GetCandidateStatus,
  GetRoleForCandidate,
  GetStatusForRole,
} from "../../WebCalls";
import { Link, useLocation, useParams } from "react-router-dom";
import { RoleStatusType, dateConverter } from "../../Helpers";
import Avatar from "../Shared/Avatar";
import useApiToken from "../../hooks/useApiToken";
import { useMsal } from "@azure/msal-react";
import ConfirmModal from "../Shared/ConfirmModal";
import { Listbox, Transition } from "@headlessui/react";
import CandidateNotes from "./CandidateNotes";
import CandidateDetails from "./CandidateDetails";
import { NIL } from "uuid";
import { Spinner } from "../Shared/Spinner";
import Notification from "../Shared/Notification";

type eventType = {
  icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
  >;
  bgColorClass: string;
};
type timeline = {
  id: string;
  content: string;
  type: eventType;
  target: string;
  date: string;
};
type advanceWithEmailOption = { name: string; description: string };

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: "bg-entntblue" },
  completed: { icon: CheckIcon, bgColorClass: "bg-entntorange" },
  notCompleted: { icon: XMarkIcon, bgColorClass: "bg-gray-400" },
};

const advanceWithEmailOptions: advanceWithEmailOption[] = [
  {
    name: "Advance with Email Notification",
    description:
      "Candidate will be advanced to the next steps, and an email notification will be sent to them regarding the next steps.",
  },
  {
    name: "Advance without Email Notification",
    description:
      "Candidate will be advanced to the next round without sending any email notification.",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function CandidatePage() {
  const [candidate, setCandidate] = useState<Candidate>();
  const [candidateRole, setCandidateRole] = useState<Role[]>([]);
  const { candidateId, roleId } = useParams();
  const [disqualifyConfirmModal, setDisqualifyConfirmModal] = useState(false);
  const [advanceStatusConfirmModal, setAdvanceStatusConfirmModal] =
    useState(false);
  const [candidateStatus, setCandidateStatus] = useState<CandidateStatus[]>([]);
  const [roleStatus, setRoleStatus] = useState<RoleStatus[]>([]);
  const [timeline, setTimeline] = useState<timeline[]>([]);
  const [latestRoleStatus, setLatestRoleStatus] = useState<RoleStatus>();
  const [disqualifiedOrHired, setDisqualifiedOrHired] =
    useState<RoleStatus | null>(null);
  const [advanceWithEmail, setAdvanceWithEmail] = useState(
    advanceWithEmailOptions[0]
  );
  const [advanceStatusSpinner, setAdvanceStatusSpinner] = useState(false);
  const [advanceStatusNotification, setAdvanceStatusNotification] =
    useState(false);
  const { instance } = useMsal();
  const location = useLocation();
  const token = useApiToken();

  useEffect(() => {
    if (candidateId === undefined || token === undefined) return;
    GetCandidate(candidateId, token).then((cd) => setCandidate(cd));
    GetRoleForCandidate(candidateId, token).then((rl) => {
      const filteredRoles = rl.filter((role) => role.id === roleId);
      setCandidateRole(filteredRoles);
    });
    GetCandidateStatus(candidateId, token).then((cs) => setCandidateStatus(cs));

    if (roleId !== undefined)
      GetStatusForRole(roleId, token).then((sr) =>
        setRoleStatus(sr.roleStatuses.sort((a, b) => a.order - b.order))
      );
  }, [candidateId, roleId, token, instance]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timeline: timeline[] = [];
    let latestStatusId = NIL;

    timeline.push({
      id: NIL,
      type: eventTypes.applied,
      content: RoleStatusType.applied,
      target: "",
      date: "",
    });

    roleStatus.forEach((rs) => {
      const correspondingCandidateStatus = candidateStatus.find((cs) => {
        return cs.roleStatusId === rs.id;
      });
      const isDisqualifiedOrHired = rs.order === roleStatus.length - 1;
      if (correspondingCandidateStatus) {
        if (isDisqualifiedOrHired) setDisqualifiedOrHired(rs);
        const formattedDate = dateConverter(
          correspondingCandidateStatus.date,
          true
        );
        timeline.push({
          id: rs.id,
          type: eventTypes.completed,
          content: rs.name,
          target: "",
          date: formattedDate,
        });
        latestStatusId = rs.id;
      } else {
        if (!isDisqualifiedOrHired)
          timeline.push({
            id: rs.id,
            type: eventTypes.notCompleted,
            content: rs.name,
            target: "",
            date: "",
          });
      }
    });
    if (timeline.length < roleStatus.length) {
      timeline.push({
        id: "0",
        type: eventTypes.notCompleted,
        content: RoleStatusType.hired + " / " + RoleStatusType.rejected,
        target: "",
        date: "",
      });
    }
    setLatestRoleStatus(roleStatus.find((rs) => rs.id === latestStatusId));
    setTimeline(timeline);
  }, [candidateStatus, roleStatus]);

  const handleDisqualify = () => {
    const disqualified = roleStatus.find(
      (el) => el.name === RoleStatusType.rejected
    );
    if (!roleId || !candidateId || !disqualified || !token) return;
    const newCandidateStatus: CandidateStatus = {
      roleId: roleId,
      candidateId: candidateId,
      roleStatusId: disqualified.id,
      date: new Date().toISOString(),
      email: advanceWithEmail === advanceWithEmailOptions[0],
    };

    CreateCandidateStatus(token, newCandidateStatus).then((cs) => {
      setCandidateStatus([...candidateStatus, cs]);
      setDisqualifyConfirmModal(false);
    });
  };

  let nextStatusIndex = 0;
  if (latestRoleStatus)
    nextStatusIndex = roleStatus.indexOf(latestRoleStatus) + 1;

  //if next status is either disqualied or offered
  if (nextStatusIndex >= roleStatus.length - 2) nextStatusIndex = -1;

  const handleAdvanceToNextStatus = () => {
    if (!roleId || !candidateId || !token) return;
    const offerStatus = roleStatus.find(
      (el) => el.name === RoleStatusType.hired
    );
    const nextStatus: CandidateStatus = {
      roleId: roleId,
      candidateId: candidateId,
      roleStatusId:
        nextStatusIndex === -1 && offerStatus
          ? offerStatus.id
          : roleStatus[nextStatusIndex].id,
      date: new Date().toISOString(),
      email: advanceWithEmail === advanceWithEmailOptions[0]
    };
    setAdvanceStatusSpinner(true);
    setAdvanceStatusConfirmModal(false);
    CreateCandidateStatus(token, nextStatus).then((cs) => {
      setCandidateStatus([...candidateStatus, cs]);
      setAdvanceStatusSpinner(false);
      setAdvanceStatusNotification(true);
    });
  };
  return (
    <>
      <div className="min-h-full">
        <header className="bg-gray-50 py-8 dark:bg-darkbglight">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="min-w-0 flex-1 ">
              <nav
                className="flex mx-auto max-w-3xl  md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl"
                aria-label="Breadcrumb"
              >
                <ol className="flex items-center sm:space-x-4">
                  {location.pathname.includes("/roles") ? (
                    <>
                      <li>
                        <div>
                          <Link
                            to={"/roles"}
                            className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                            Roles
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <Link
                            to={`/roles/${candidateRole[0]?.id}`}
                            className="sm:ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                            {candidateRole[0]?.title}
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <Link
                            to={`/roles/${candidateRole[0]?.id}/candidates`}
                            className="sm:ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                            Candidates
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="sm:ml-4 text-sm font-medium text-gray-500 dark:text-gray-400">
                            info
                          </span>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <div>
                          <Link
                            to={"/candidates"}
                            className="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400"
                          >
                            Candidates
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400">
                            info
                          </button>
                        </div>
                      </li>
                    </>
                  )}
                </ol>
              </nav>
              <div className="mx-auto max-w-3xl pt-4 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl ">
                <div className="flex items-center space-x-5">
                  <div className="flex-shrink-0">
                    <div className="relative">
                      <Avatar />
                    </div>
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-300 ">
                      {candidate?.firstName} {candidate?.lastName}
                    </h1>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Applied for{" "}
                      <Link
                        to={`/roles/${candidateRole[0]?.id}`}
                        className="text-gray-900 dark:text-gray-300"
                      >
                        {candidateRole[0]?.title}
                      </Link>{" "}
                      {/* on <span>{dateConverter(timeline[0]?.date)}</span> */}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  {disqualifiedOrHired === null ? (
                    advanceStatusSpinner ? (
                      <span className="inline-flex items-center justify-center min-w-[10rem] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <Spinner />
                        <span className="ml-2">Advancing</span>
                      </span>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="inline-flex items-center justify-center max-w-[10rem] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => {
                            setDisqualifyConfirmModal(true);
                          }}
                        >
                          Disqualify
                        </button>
                        <Listbox
                          as="div"
                          value={advanceWithEmail}
                          onChange={setAdvanceWithEmail}
                          className="sm:ml-3"
                        >
                          {({ open }) => (
                            <>
                              <div className="relative">
                                <div className="inline-flex divide-x divide-entntblue rounded-md shadow-sm">
                                  <div className="inline-flex divide-x rounded-md shadow-sm">
                                    <div
                                      className="group inline-flex items-center gap-x-1.5 rounded-l-md  bg-entntblue px-3 text-white shadow-sm  hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer"
                                      onClick={() =>
                                        setAdvanceStatusConfirmModal(true)
                                      }
                                    >
                                      {advanceWithEmail ===
                                        advanceWithEmailOptions[0] && (
                                          <EnvelopeIcon
                                            className="-ml-0.5 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        )}
                                      <span className="inline-flex items-center justify-center  rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm group-hover:bg-entntorange">
                                        Advance to{" "}
                                        {nextStatusIndex !== -1
                                          ? roleStatus[nextStatusIndex].name
                                          : "offer"}
                                      </span>
                                    </div>
                                    <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-entntblue p-2 hover:bg-entntorange focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-entntblue focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                                      <ChevronDownIcon
                                        className="h-5 w-5 text-white"
                                        aria-hidden="true"
                                      />
                                    </Listbox.Button>
                                  </div>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute left-0 z-10 -mr-1 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                                    {advanceWithEmailOptions.map((option) => (
                                      <Listbox.Option
                                        key={option.name}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-entntorange text-white"
                                              : "text-gray-900",
                                            "cursor-default select-none p-4 text-sm"
                                          )
                                        }
                                        value={option}
                                      >
                                        {({ selected, active }) => (
                                          <div className="flex flex-col">
                                            <div className="flex justify-between">
                                              <p
                                                className={
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal"
                                                }
                                              >
                                                {option.name}
                                              </p>
                                              {selected ? (
                                                <span
                                                  className={
                                                    active
                                                      ? "text-white"
                                                      : "text-entntblue"
                                                  }
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </div>
                                            <p
                                              className={classNames(
                                                active
                                                  ? "text-white"
                                                  : "text-gray-500",
                                                "mt-2"
                                              )}
                                            >
                                              {option.description}
                                            </p>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </>
                    )
                  ) : (
                    <span className="inline-flex items-center justify-center min-w-[10rem] rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                      {disqualifiedOrHired.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="py-10">
          <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 px-2 sm:px-6 lg:max-w-7xl lg:grid-flow-row-dense lg:grid-cols-3">
            <div className="lg:col-span-3 lg:grid lg:grid-cols-3 lg:col-start-1 lg:space-x-6 lg:space-y-0 space-y-6">
              <CandidateDetails
                candidate={candidate}
                candidateRole={candidateRole}
              />
              <section
                aria-labelledby="timeline-title"
                className="lg:col-span-1 lg:col-start-3"
              >
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6  dark:bg-darkbglight">
                  <h2
                    id="timeline-title"
                    className="text-lg font-medium text-gray-900 dark:text-gray-300"
                  >
                    Timeline
                  </h2>

                  <div className="mt-6 flow-root">
                    <ul className="-mb-8">
                      {timeline.map((item, itemIdx) => (
                        <li key={item.id}>
                          <div className="relative pb-8">
                            {itemIdx !== timeline.length - 1 ? (
                              <span
                                className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-500"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    item.type?.bgColorClass,
                                    "h-8 w-8 rounded-full flex items-center justify-center ring-8 dark:ring-4 ring-white dark:ring-gray-500"
                                  )}
                                >
                                  <item.type.icon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                <div>
                                  <p className="text-sm text-gray-600 dark:text-gray-200">
                                    {item.content}{" "}
                                    <span className="font-medium text-gray-900">
                                      {item.target}
                                    </span>
                                  </p>
                                </div>
                                <div className="whitespace-nowrap text-right text-sm text-gray-500 dark:text-gray-400">
                                  <span>{item.date}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-6 flex flex-col justify-stretch">
                    {disqualifiedOrHired ? (
                      <span className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300">
                        {disqualifiedOrHired.name}
                      </span>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed disabled:bg-entntblue"
                        onClick={() => setAdvanceStatusConfirmModal(true)}
                        disabled={advanceStatusSpinner}
                      >
                        {advanceWithEmail === advanceWithEmailOptions[0] && (
                          <EnvelopeIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        <span className="ml-4">
                          Advance to{" "}
                          {nextStatusIndex !== -1
                            ? roleStatus[nextStatusIndex].name
                            : "offer"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </section>
            </div>
            <CandidateNotes />
          </div>
        </main>
      </div>
      <ConfirmModal
        type="danger"
        open={disqualifyConfirmModal}
        setOpen={setDisqualifyConfirmModal}
        onConfirm={handleDisqualify}
        title="Disqualify"
        description="Are you sure ? Candidate will be disqualified. This action can not be reverted"
      />
      <ConfirmModal
        type="info"
        open={advanceStatusConfirmModal}
        setOpen={setAdvanceStatusConfirmModal}
        onConfirm={handleAdvanceToNextStatus}
        title={`Advance to next round `}
        description="Candidate will be advanced to next round."
      />
      <Notification
        show={advanceStatusNotification}
        setShow={setAdvanceStatusNotification}
        title="Candidate Status"
        description="Candidate status has changed successfully"
        type="info"
      />
    </>
  );
}
