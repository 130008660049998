import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EyeIcon, XCircleIcon } from "@heroicons/react/20/solid";
import parse from "html-react-parser";

type props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: string;
};

export default function TextEditorPreviewModal(props: props) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400  bg-opacity-50 dark:bg-opacity-20 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto">
          <div className="flex h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg overflow-hidden bg-white text-left shadow-xl transition-all sm:w-5/6 sm:h-5/6">
                <div className="bg-white h-full overflow-y-scroll dark:bg-darkbglight dark:text-white">
                  <div className="sm:flex sm:items-start sticky top-0 bg-white px-6 py-4 shadow-sm dark:bg-darkbglight dark:shadow-white">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <EyeIcon
                        className="h-6 w-6 text-blue-600 "
                        aria-hidden="true"
                      />
                      <XCircleIcon
                        className="absolute w-10 h-10 top-4 right-4 cursor-pointer text-red-500"
                        onClick={() => props.setOpen(false)}
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        {props.title}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="render-container p-6">
                    {parse(props.content)}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
