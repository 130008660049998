import React, { useEffect, useState } from "react";
import {
  CreateCandidateComment,
  DeleteComment,
  GetCandidateComments,
  GetProfilePhoto,
  GetUserName,
} from "../../WebCalls";
import Notification from "../Shared/Notification";
import { dateConverter, getAvatarName } from "../../Helpers";
import { CandidateComment } from "../../Models";
import useApiToken from "../../hooks/useApiToken";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Avatar from "../Shared/Avatar";
import { Spinner } from "../Shared/Spinner";
import useGraphApiToken from "../../hooks/useGraphApiToken";
import useUserData from "../../hooks/useUserData";

type comment = {
  personId: string;
  id: string;
  name: string;
  date: string;
  imageSrc: string | undefined;
  body: string;
};

export default function CandidateNotes() {
  const [note, setNote] = useState<string>("");
  const [candidateComments, setCandidateComments] = useState<
    CandidateComment[]
  >([]);
  const [showNotification, setShowNotification] = useState(false);
  const [deleteCommentConfirm, setDeleteCommentConfirm] = useState("");
  const [commentProfile, setCommentProfile] = useState<string>();
  const [comments, setComments] = useState<comment[]>([]);
  const token = useApiToken();
  const { roleId, candidateId } = useParams();
  const [loadingComments, setLoadingComments] = useState(true);
  const graphToken = useGraphApiToken();
  const { username, userId } = useUserData();

  useEffect(() => {
    if (!candidateId || !token) return;
    GetCandidateComments(candidateId, token).then((cc) => {
      setCandidateComments(cc);
    });
    if (!graphToken) return;
    GetProfilePhoto(graphToken).then((pr) => {
      if (pr) setCommentProfile(URL.createObjectURL(pr));
    });
  }, [candidateId, token, graphToken]);

  useEffect(() => {
    setLoadingComments(true);
    const setCommentWithProfile = async () => {
      //sorting comments based on date
      candidateComments.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      const commentMappedWithImage = await Promise.all(
        candidateComments.map(async (el) => {
          let name: any;
          let profile: Blob | undefined;
          if (graphToken) {
            name = await GetUserName(el.personId, graphToken);
            profile = await GetProfilePhoto(graphToken, el.personId);
          }
          return {
            id: el.id,
            name: name.displayName,
            date: dateConverter(el.date, true),
            personId: el.personId,
            imageSrc: profile && URL.createObjectURL(profile),
            body: el.comment,
          };
        })
      );

      setComments(commentMappedWithImage);
      setLoadingComments(false);
    };
    setCommentWithProfile();
  }, [candidateComments, graphToken]);

  const handleCreateComment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const personId = userId;
    if (
      candidateId !== undefined &&
      roleId !== undefined &&
      note !== undefined &&
      personId !== undefined &&
      token !== undefined
    ) {
      const body: CandidateComment = {
        id: uuidv4(),
        candidateId: candidateId,
        roleId: roleId,
        comment: note,
        personId: personId,
        date: new Date().toISOString(),
      };
      CreateCandidateComment(body, token).then((ncc) => {
        const newComment: comment = {
          id: ncc.id,
          name: username ?? "",
          date: dateConverter(ncc.date, true),
          imageSrc: commentProfile,
          body: ncc.comment,
          personId: ncc.personId,
        };
        setShowNotification(true);
        setComments((prev) => [...prev, newComment]);
        setNote("");
      });
    }
  };
  const handleDeleteComment = (id: string) => {
    if (!token) return;
    DeleteComment(id, token).then(() => {
      const newCC = candidateComments.filter((cc) => cc.id !== id);
      setCandidateComments(newCC);
    });
  };

  return (
    <>
      <section aria-labelledby="notes-title" className="lg:col-span-2">
        <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg dark:bg-darkbglight">
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="notes-title"
                className="text-lg font-medium text-gray-900 dark:text-gray-300"
              >
                Notes
              </h2>
            </div>
            <div className="px-4 py-6 sm:px-6">
              {loadingComments ? (
                <Spinner />
              ) : (
                <ul className="space-y-8 relative">
                  {comments.map((comment) => (
                    <li key={comment.id}>
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0 flex-2">
                          <Avatar
                            height="h-10"
                            width="w-10"
                            src={comment.imageSrc}
                            avatarName={getAvatarName(comment.name)}
                          />
                        </div>
                        <div className="flex-1">
                          <div className="text-sm">
                            <span className="flex justify-between">
                              <span className="font-medium text-gray-900 dark:text-gray-300">
                                {comment.name}
                                <span className="font-normal text-gray-600 ml-2 dark:text-gray-400">
                                  {comment.personId}
                                </span>
                              </span>
                              {comment.personId === userId &&
                                (deleteCommentConfirm === comment.id ? (
                                  <div className="flex dark:text-gray-300">
                                    <span className="hidden sm:block">
                                      Delete this comment ?
                                    </span>
                                    <span className="flex ml-4 space-x-4">
                                      <XMarkIcon
                                        height={20}
                                        className="text-red-600 cursor-pointer"
                                        onClick={() => {
                                          setDeleteCommentConfirm("");
                                        }}
                                      />
                                      <CheckIcon
                                        height={20}
                                        className="text-entntblue cursor-pointer"
                                        onClick={() => {
                                          handleDeleteComment(comment.id);
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <button
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setDeleteCommentConfirm(comment.id);
                                    }}
                                  >
                                    <TrashIcon
                                      height={20}
                                      className="text-red-600"
                                    />
                                  </button>
                                ))}
                            </span>
                          </div>
                          <div className="mt-1 text-sm text-gray-700 dark:text-gray-400">
                            <p>{comment.body}</p>
                          </div>
                          <div className="mt-2 space-x-2 text-sm">
                            <span className="font-medium text-gray-500">
                              {comment.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-transparent">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <Avatar
                  src={commentProfile}
                  height="h-10"
                  width="w-10"
                  avatarName={username && getAvatarName(username)}
                />
              </div>
              <div className="min-w-0 flex-1">
                <form>
                  <div>
                    <textarea
                      id="comment"
                      name="comment"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-entntblue sm:text-sm sm:leading-6 dark:bg-transparent dark:text-gray-300"
                      placeholder="Add a note"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                  <div className="mt-3 flex items-center justify-between">
                    <button
                      type="submit"
                      className="inline-flex cursor-pointer items-center justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-slate-200 disabled:cursor-not-allowed"
                      onClick={handleCreateComment}
                      disabled={note.trim() === ""}
                    >
                      Add note
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title="Added Successfully"
        description="Note has been added successfully"
        type="success"
      />
    </>
  );
}
