import React, { Fragment, useEffect, useState } from "react";
import { City, QuarantineCompany, QuarantineUniversity } from "../../Models";
import {
  Filters,
  GetCities,
  fetchCompanyLocations,
  fetchConfirmedUniversities,
  fetchQuarantineCompanies,
  fetchQuarantineUniversities,
} from "../../WebCalls";
import useApiToken from "../../hooks/useApiToken";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Link, useLocation } from "react-router-dom";
import DataGrid from "./Table/DataGrid";
import { Disclosure } from "@headlessui/react";
import Notification from "../Shared/Notification";
import Actions from "./ActionLog/Actions";
import { History } from "@mui/icons-material";

const tabs = ["Companies", "Universities"];

function Quarantine() {
  const token = useApiToken();
  const [selected, setSelected] = useState<string>(tabs[0]);
  const [query, setQuery] = useState<string>("");
  const [countNotification, setCountNotification] = useState<boolean>(false);
  const [minCount, setMinCount] = useState<string>("0");
  const [maxCount, setMaxCount] = useState<string>("100");
  const [showActions, setShowActions] = useState<boolean>(false);
  const [rejectQuery, setRejectQuery] = useState<string>("");
  const [confirmedData, setConfirmedData] = useState<
    QuarantineUniversity[] | QuarantineCompany[]
  >();
  const [quarantineData, setQuarantineData] = useState<
    QuarantineUniversity[] | QuarantineCompany[]
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(13);
  const [page, setPage] = useState<number>(1);
  const [locationSearch, setLocationSearch] = useState("");
  const [cities, setCities] = useState<City[]>([]);

  const location = useLocation();

  type filterOption = {
    value: string | number | boolean | null;
    label: string;
    checked: boolean;
    extraData?: number;
  };

  interface IlocationFilter{
    id: string;
    name: string;
    options: filterOption[];
    filteredOptions: filterOption[];
  }

  const initialLocationFilter: IlocationFilter = {
    id: "workLocation1City.Id",
    name: "Location",
    options: [],
    filteredOptions: [],
  };

  const [locationFilter, setLocationFilter] = useState(initialLocationFilter);

  useEffect(() => {
    if (location.pathname.includes("/companies")) setSelected(tabs[0]);
    if (location.pathname.includes("/universities")) setSelected(tabs[1]);
  }, [location.pathname]);

  useEffect(() => {
    GetCities().then((c) => {
      setCities(c);
    });
  }, []);

  useEffect(() => {
    let filteredCity = cities;
    if (locationSearch !== "")
      filteredCity = cities.filter((c) =>
        c.name.toLowerCase().startsWith(locationSearch.toLowerCase())
      );
    const filteredCities = locationFilter.options.filter((lf) => {
      return filteredCity.some((fc) => fc.id === lf.value);
    });

    setLocationFilter((prev) => ({ ...prev, filteredOptions: filteredCities }));
  }, [locationSearch, cities, locationFilter.options]);

  useEffect(()=>{
    fetchConfirmedData(rejectQuery);
 },[rejectQuery])

  useEffect(() => {
    const updatedLocationFilterOption = cities.map((c) => ({
      checked: false,
      value: c.id,
      label: c.name,
    }));

    const locationCityFilter = {
      ...initialLocationFilter,
      options: updatedLocationFilterOption,
    };
    setLocationFilter(locationCityFilter);
  }, [cities]);

  const handleLocationFilterChange = async (changedOption: filterOption) => {
    const updatedOptions = locationFilter.options.map((option) => {
      if (option.value === changedOption.value) {
        return { ...option, checked: !option.checked };
      }
      return option;
    });
    const updatedFilteredOptions = locationFilter.filteredOptions.map((fo) => {
      if (fo.value === changedOption.value) {
        return { ...fo, checked: !fo.checked };
      }
      return fo;
    });
    setLocationFilter((prev) => ({
      ...prev,
      options: updatedOptions,
      filteredOptions: updatedFilteredOptions,
    }));
    await fetchQuarantineData(undefined,undefined,[
      {
        FilterType: "count",
        MinCount: parseInt(minCount),
        MaxCount: parseInt(maxCount),
      },
      {
        FilterType: "location",
        Locations: updatedFilteredOptions
        .filter((o) => o.checked)
        .map((o) => o.label),
      }]);
  };

 const fetchConfirmedData = async (name?:string) => {
    if (selected === tabs[0]) {
      try {
        const comp = await fetchCompanyLocations(name);
        if (comp) {
          setConfirmedData(comp);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (selected === tabs[1]) {
      try {
        const uni = await fetchConfirmedUniversities(name,1,500,"");
        if (uni) {
          setConfirmedData(uni);
        } else console.error("Token is undefined or empty.");
      } catch (err) {
        console.log(err);
      }
    }
};

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setPage(1);
    setQuery(event.currentTarget.value.toLowerCase());
  }

  async function handleCountSubmit() {
    if (!minCount || !maxCount || parseInt(maxCount) < parseInt(minCount)) {
      setCountNotification(true);
      return;
    }
    await fetchQuarantineData(undefined, undefined, [
      {
        FilterType: "count",
        MinCount: parseInt(minCount),
        MaxCount: parseInt(maxCount),
      },
    ]);
  }

  const handleCountKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "Tab" ||
      event.key === "Escape" ||
      event.key === "Enter"
    ) {
      return;
    }

    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "a" ||
        event.key === "c" ||
        event.key === "v" ||
        event.key === "x")
    ) {
      return;
    }

    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const fetchQuarantineData = async (
    sortType='Name',
    sortDesc?: boolean,
    filters?: Filters[]
  ) => {
    setLoading(true);
    if (!token) return;
    if (selected === tabs[0]) {
      try {
        if (token !== undefined) {
          const { records, totalRecords } = await fetchQuarantineCompanies(
            token,
            page,
            13,
            query,
            sortType,
            sortDesc,
            filters
          );
          if (records) {
            setTotalRecords(totalRecords);
            setQuarantineData(records);
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else if (selected === tabs[1]) {
      try {
        if (token !== undefined) {
          const { records, totalRecords } = await fetchQuarantineUniversities(
            token,
            page,
            13,
            query,
            sortType,
            sortDesc,
            filters
          );
          if (records) {
            setQuarantineData(records);
            setTotalRecords(totalRecords);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchQuarantineData();
    };
    fetchData();
  }, [selected, query, token]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchConfirmedData();
    };
    fetchData();
    setPage(1);
    setQuery("");
    setMinCount("1");
    setMaxCount("100");
    setLocationSearch("");
  }, [selected,showActions]);

  return (
    <div className="overflow-hidden">
      <aside
        className={`dark:border-gray-500 fixed z-10 block h-[calc(100vh-4rem)] left-0 shadow-md border border-gray-200 transition-transform transform duration-200 w-[19rem] ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-[100%]"
        }`}
      >
        <div className="flex text-sm justify-between items-center py-1 mx-2 my-1 mt-2 rounded-full bg-white border border-gray-200 relative dark:bg-darkbg dark:border-gray-500">
          <div
            className={`absolute bg-entntblue rounded-full top-[10%] left-[2%] h-[80%] w-[48%] z-0 transform transition-all ${
              selected === tabs[1] ? "translate-x-full" : "-translate-x-0"
            }`}
          ></div>
          <Link
            to={`/quarantine/companies`}
            className="z-10 flex-1 text-center"
          >
            <button
              type="button"
              className={`inline-flex items-center gap-x-1.5  bg-transparent px-2 py-1 text-sm ${
                selected === tabs[0]
                  ? "text-gray-100"
                  : "text-gray-400 font-medium"
              }`}
            >
              Companies
            </button>
          </Link>
          <Link
            to={`/quarantine/universities`}
            className="flex-1 z-10 text-center"
          >
            <button
              type="button"
              className={`inline-flex items-center gap-x-1.5  bg-transparent px-2 py-1 text-sm ${
                selected === tabs[1]
                  ? "text-gray-100"
                  : "text-gray-400 font-medium"
              }`}
            >
              Universities
            </button>
          </Link>
        </div>

        <div className="flex flex-row justify-end items-end sm:my-4 w-full">
          <div className="relative w-full mx-2">
            <input
              type="text"
              placeholder="Quick Search"
              className="disabled:cursor-not-allowed w-full xl rounded-lg bg-white py-2 pl-10 pr-3 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 dark:text-gray-400 dark:border-gray-600 dark:bg-transparent focus-visible:ring-offset-blue-100 sm:text-sm"
              disabled={showActions}
              onChange={handleSearch}
              value={query}
            />
            <MagnifyingGlassIcon
              className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 dark:text-gray-500"
              aria-hidden="true"
            />
          </div>
        </div>

        <div
          className="flex w-full items-center justify-between py-2 px-3 text-gray-400 cursor-pointer hover:text-gray-900"
          onClick={() => setShowActions((prev) => !prev)}
        >
          <span className="font-medium text-sm text-gray-900 dark:text-gray-300">
            Actions Log
          </span>
          {
            showActions?
            <XMarkIcon
            className={"w-5 text-gray-900"}
            aria-hidden="true"
            />
            :
            <History
              className={"w-4"}
              aria-hidden="true"
            />
          }
        </div>

        <Disclosure
          as="div"
          className="border-b border-gray-200 py-4 dark:border-gray-500"
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full items-center justify-between px-3 text-sm text-gray-400 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-200">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  Count
                </span>
                {open ? (
                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col gap-[1rem] px-6 pt-4 pb-2 text-sm text-gray-500">
                <div className="flex justify-between items-center">
                  <input
                    type="text"
                    value={minCount}
                    onChange={(e) => setMinCount(e.target.value)}
                    placeholder="min"
                    className="w-[6rem] text-sm py-[3px] rounded-md"
                    onKeyDown={handleCountKeyDown}
                  />
                  <input
                    type="text"
                    value={maxCount}
                    onChange={(e) => setMaxCount(e.target.value)}
                    placeholder="max"
                    className="w-[6rem] text-sm py-[3px] rounded-md"
                    onKeyDown={handleCountKeyDown}
                  />
                </div>
                <button
                  className="disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  onClick={handleCountSubmit}
                  disabled={showActions}
                >
                  Apply
                </button>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure
        as="div"
        className="border-b border-gray-200 py-4 px-3 dark:border-gray-500"
      >
        {({ open, close }) => (
          <div>
            <div className="relative">
              <h3 className="-my-4 flow-root">
                <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                  {locationFilter.name}
                  </span>
                  <span className="ml-3 flex items-center">
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left ring-1 ring-gray-300 sm:text-sm dark:ring-gray-500">
                      <input
                        type="text"
                        value={locationSearch}
                        placeholder="Search City"
                        disabled={showActions}
                        className="disabled:cursor-not-allowed w-full border-none py-1 pl-3 pr-10 text-sm text-gray-900 focus:ring-0 placeholder:text-gray-400 dark:text-gray-300 dark:bg-darkbg"
                        onChange={(event) =>
                          setLocationSearch(event.target.value)
                        }
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 dark:text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </span>
                </Disclosure.Button>
              </h3>

              <Disclosure.Panel
                className={`dark:bg-darkbglight dark:border-gray-500 shadow-sm pl-1 py-1 h-fit absolute bg-white rounded-md z-50 border border-gray-200 bottom-[2.6rem] -right-[0.20rem]`}
              >
                <div className="max-h-[10.5rem] min-w-[11.8rem] pl-2 py-1 overflow-auto thin-scroll bg-transparent z-10">
                  <div className="space-y-2">
                    {locationFilter.filteredOptions.map((option, optionIdx) => (
                      <div key={optionIdx} className="flex items-center">
                        <input
                          id={`locaiton-filter-${optionIdx}`}
                          type={"checkbox"}
                          onChange={() => {
                            handleLocationFilterChange(option);
                          }}
                          checked={option.checked}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />

                        <label
                          id={`locaiton-filter-${optionIdx}`}
                          className="ml-3 text-sm text-gray-600 flex justify-between w-full pr-2 dark:text-gray-400"
                        >
                          <span>{option.label}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
            {open && (
              <div
                className="fixed inset-0 z-20"
                onClick={() => {
                  close();
                }}
              ></div>
            )}
            <div className="z-10 text-xs flex flex-wrap box-border mt-2">
              {locationFilter.options.map((o, index) =>
                o.checked ? (
                  <span
                    key={o.label + index}
                    className="flex items-center bg-blue-100 text-entntblue py-1 px-2 mr-2 mt-2 rounded-full relative"
                  >
                    {o.label}
                    <XMarkIcon
                      onClick={() => {
                        handleLocationFilterChange(o);
                      }}
                      className="h-4 w-4 cursor-pointer text-gray-500 ml-1 hover:text-gray-600"
                    />
                  </span>
                ) : (
                  <Fragment key={index}></Fragment>
                )
              )}
            </div>
          </div>
        )}
      </Disclosure>

        <div
          className={`absolute top-40 ${
            isSidebarOpen ? "-right-2 shadow-md" : "-right-4 shadow-xl"
          } h-16 border border-gray-300 rounded-md flex items-center justify-center cursor-pointer bg-white dark:bg-darkbglight dark:border-gray-500 dark:text-gray-300`}
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        >
          {isSidebarOpen ? (
            <ChevronDoubleLeftIcon width={16} />
          ) : (
            <ChevronDoubleRightIcon width={16} />
          )}
        </div>
      </aside>

      <main
        className={`${
          !isSidebarOpen
            ? "w-full"
            : "relative sm:transform translate-x-[19rem] sm:w-[calc(100%-19rem)]"
        } 
                  transition-all duration-200 ease-out`}
      >
        <div className="relative w-full overflow-x-hidden">
          {showActions ? (
            <Actions
              selected={selected}
              loading={loading}
              setLoading={setLoading}
              setShowActions={setShowActions}
            />
          ) : (
            <DataGrid
              loading={loading}
              setLoading={setLoading}
              fetchQuarantineData={fetchQuarantineData}
              selected={selected}
              fetchConfirmedData={fetchConfirmedData}
              confirmedData={confirmedData}
              quarantineData={quarantineData}
              setQuarantineData={setQuarantineData}
              token={token ?? ""}
              totalPages={Math.ceil(totalRecords / 13)}
              currentPage={page}
              setCurrentPage={setPage}
              minCount={minCount}
              maxCount={maxCount}
              rejectQuery={rejectQuery}
              setRejectQuery={setRejectQuery}
            />
          )}
        </div>
      </main>
      <Notification
        title="Validation Error"
        description={`Please enter a valid Range.`}
        type="error"
        show={countNotification}
        setShow={setCountNotification}
      />
    </div>
  );
}

export default Quarantine;
