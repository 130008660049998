import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Role, Skill } from "../../../Models";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { getUngradedRoles, updateCandidateMetrics } from "../../../WebCalls";

import { GetRoles } from "../../../WebCalls";
import { useParams } from "react-router-dom";
import { fetchAllSkills } from "../../../WebCalls";
import { Spinner } from "../../Shared/Spinner";
import Notification from "../../Shared/Notification";
type filterOption = {
  value: string | number | boolean | null;
  label: string;
  checked: boolean;
  extraData?: number;
};
interface IFilter {
  id: string;
  name: string;
  options: filterOption[];
}
interface IRangeFilter {
  id: string;
  name: string;
  minVal: number;
  maxVal: number;
  currentVal: { min: number; max: number };
}

const initialFilters: IFilter[] = [
  {
    id: "RoleId",
    name: "Roles",
    options: [{ value: "", label: "All", checked: true, extraData: 0 }],
  },

  {
    id: "testGiven",
    name: "Test",
    options: [{ value: 1, label: "Appeared", checked: false }],
  },
  {
    id: "Skills",
    name: "Skills",
    options: [{ value: 1, label: "All", checked: false }],
  },
];

const initialRangeFilters: IRangeFilter[] = [
  {
    id: "upperage",
    name: "Max age",
    minVal: 0,
    maxVal: 60,
    currentVal: {
      min: 0,
      max: 60,
    },
  },
  {
    id: "upperexp",
    name: "Max exp",
    minVal: 0,
    maxVal: 20,
    currentVal: {
      min: 0,
      max: 20,
    },
  },
];

type Props = {
  handleFilterQuery: (query: string) => void;
  handleStatusFilterIsAll: (isAll: boolean) => void;
};

export default function RoleSidebarCandidate({
  handleFilterQuery,
  handleStatusFilterIsAll,
}: Props) {
  const [rangeFilters, setRangeFilter] =
    useState<IRangeFilter[]>(initialRangeFilters);
  const [filters, setFilters] = useState<IFilter[]>(initialFilters);

  const [isOpenRoleFilter, setIsOpenRoleFilter] = useState<boolean>(false);
  const [isOpenSkillFilter, setIsOpenSkillFilter] = useState<boolean>(false);
  const [isOpenTestFilter, setIsOpenTestFilter] = useState<boolean>(false);

  const [computingMetrics, setComputingMetrics] = useState(false);
  const [metricsAreUpdated, setMetricsAreUpdated] = useState(true);
  const [metricsUpdateNotification, setMetricsUpdateNotification] =
    useState(false);

  const [currentRangeFilters, setCurrentRangeFilter] =
    useState<IRangeFilter[]>(initialRangeFilters);

  const params = useParams();

  const peresentroleid = params.roleId;

  useEffect(() => {
    getUngradedRoles().then((roles) => {
      if (peresentroleid) {
        if (roles && roles.length > 0) setMetricsAreUpdated(false);
      }
    });
    const getSkillsAndRoles = async () => {
      const skillsData: Skill[] = await fetchAllSkills();

      let updatedFilters = filters.map((filter) => {
        if (filter.id === "Skills") {
          return {
            ...filter,
            options: [
              { value: "", label: "All", checked: true },
              ...skillsData
                .filter((skill) => skill.id !== peresentroleid)
                .map((skill) => ({
                  value: skill.id,
                  label: skill.name,
                  checked: false,
                })),
            ],
          };
        }
        return filter;
      });

      const response = await GetRoles();

      const fetchedRoles: Role[] = response;

      updatedFilters = updatedFilters.map((filter) => {
        if (filter.id === "RoleId") {
          return {
            ...filter,
            options: [
              { value: "", label: "All", checked: true },
              ...fetchedRoles
                .filter((role) => role.id !== peresentroleid)
                .map((role) => ({
                  value: role.id,
                  label: role.title,
                  checked: false,
                })),
            ],
          };
        }
        return filter;
      });

      setFilters(updatedFilters);
    };

    getSkillsAndRoles();
  }, [peresentroleid]);

  useEffect(() => {
    let query: string[] = [];

    filters.forEach((f) => {
      let sameFilter: string[] = [];
      f.options.forEach((option) => {
        if (option.checked && option.value !== "") {
          sameFilter.push(`${f.id} eq ${option.value}`);
        }
      });
      if (sameFilter.length > 0)
        query.push("(" + sameFilter.join(" or ") + ")");
    });

    rangeFilters.forEach((f) => {
      if (f.currentVal.min !== f.minVal || f.currentVal.max !== f.maxVal)
        query.push(
          `${f.id} gte ${f.currentVal.min} and ${f.id} lte ${f.currentVal.max}`
        );
    });

    handleFilterQuery(query.join(" and "));
  }, [filters, rangeFilters, handleFilterQuery]);

  const handleFilterChange = (
    section: IFilter,
    changedOption: filterOption
  ) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === section.id) {
        const updatedOptions = filter.options.map((option) => {
          if (option.value === changedOption.value) {
            return { ...option, checked: !option.checked };
          }
          if (changedOption.value !== "" && option.value === "")
            return { ...option, checked: false };
          if (changedOption.value === "") return { ...option, checked: false };
          return option;
        });
        return { ...filter, options: updatedOptions };
      }

      return filter;
    });

    let noneStatusSelected = true;
    let allStatusIsSelected = false;
    updatedFilters[0].options.forEach((uf, ind) => {
      if (uf.checked) {
        if (ind === 0) allStatusIsSelected = true;
        noneStatusSelected = false;
        return;
      }
    });

    if (allStatusIsSelected || noneStatusSelected) {
      updatedFilters[0].options[0].checked = true;
      handleStatusFilterIsAll(true);
    } else handleStatusFilterIsAll(false);
    setFilters(updatedFilters);
  };

  const handleRangeChange = (
    section: IRangeFilter,
    value: { min: number; max: number },
    current = false
  ) => {
    const updatedRangeFilter = rangeFilters.map((rf) => {
      if (rf.id === section.id) return { ...rf, currentVal: value };
      return rf;
    });
    if (current) setCurrentRangeFilter(updatedRangeFilter);
    else setRangeFilter(updatedRangeFilter);
  };

  const handleComputeMetrics = () => {
    if (!peresentroleid) return;
    setComputingMetrics(true);
    updateCandidateMetrics(peresentroleid).then(() => {
      setComputingMetrics(false);
      setMetricsAreUpdated(true);
      setMetricsUpdateNotification(true);
    });
  };

  return (
    <div className="px-4">
      {/* CV Filter */}
      <Disclosure as="div" className="border-b border-gray-200 pt-4 pb-2">
        {({ open, close }) => (
          <>
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  CV Filter
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>
            <Disclosure.Panel className="pl-6 pt-2">
              <Disclosure>
                <Disclosure.Button
                  className="flex w-full items-center justify-between py-3 pb-0 text-sm text-gray-400 hover:text-gray-500"
                  onClick={() => {
                    setIsOpenRoleFilter(!isOpenRoleFilter);
                  }}
                >
                  <span className="font-medium text-gray-900 dark:text-gray-300">
                    Roles
                  </span>
                  <span className="ml-6 flex items-center">
                    {isOpenRoleFilter ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-2">
                  {filters
                    .filter((filter) => filter.id === "RoleId")
                    .map((section) => (
                      <div key={section.id}>
                        {section.options.map((option, optionIdx) => (
                          <div
                            key={optionIdx}
                            className="flex pl-4  items-center pb-2"
                          >
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type={option.value === "" ? "radio" : "checkbox"}
                              onChange={() =>
                                handleFilterChange(section, option)
                              }
                              checked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                </Disclosure.Panel>
              </Disclosure>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Performance Filter */}
      <Disclosure as="div" className="border-b border-gray-200 py-4">
        {({ open }) => (
          <>
            <h3 className="-my-4 flow-root">
              <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900 dark:text-gray-300">
                  Performance Filter
                </span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>
            <Disclosure.Panel className="pt-2 pl-6 pb-0">
              <Disclosure as="div" className="border-b border-gray-200">
                <Disclosure.Button
                  className="flex w-full items-center justify-between py-3 text-md text-gray-400 hover:text-gray-500"
                  onClick={() => setIsOpenTestFilter(!isOpenTestFilter)}
                >
                  <span className="font-medium text-sm text-gray-900 dark:text-gray-300">
                    Test Filter
                  </span>
                  <span className="ml-6 flex items-center">
                    {isOpenTestFilter ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className={"pb-2"}>
                  {filters
                    .filter((filter) => filter.id === "testGiven")
                    .map((section) => (
                      <div key={section.id}>
                        {section.options.map((option, optionIdx) => (
                          <div
                            key={optionIdx}
                            className="flex pl-4 items-center pb-2"
                          >
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              onChange={() =>
                                handleFilterChange(section, option)
                              }
                              checked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm  text-gray-600"
                            >
                              Test <span> </span>
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure as="div" className="border-b border-gray-200">
                <Disclosure.Button
                  className="flex w-full items-center justify-between py-3 text-md text-gray-400 hover:text-gray-500 "
                  onClick={() => setIsOpenSkillFilter(!isOpenSkillFilter)}
                >
                  <span className="font-medium text-sm  text-gray-900 dark:text-gray-300">
                    Skill Filter
                  </span>
                  <span className="ml-6 flex items-center">
                    {isOpenSkillFilter ? (
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>

                <Disclosure.Panel className={"pb-2"}>
                  {filters
                    .filter((filter) => filter.id === "Skills")
                    .map((section) => (
                      <div key={section.id}>
                        {section.options.map((option, optionIdx) => (
                          <div
                            key={optionIdx}
                            className="flex pl-4 items-center pb-2"
                          >
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              onChange={() =>
                                handleFilterChange(section, option)
                              }
                              checked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              <span>{option.label}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                </Disclosure.Panel>
              </Disclosure>

              {/* Range Filters */}
              {currentRangeFilters.map((section, i) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className={`${i === 0 ? "border-b border-gray-200" : ""} py-4 dark:border-gray-500`}
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-4 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-900 dark:text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6 pb-4 px-6">
                        <div>
                          <div key={section.id} className="flex items-center">
                            {section.id === "upperage" ? (
                              <Slider
                                range
                                max={section.maxVal}
                                min={0}
                                value={[0, section.currentVal.max]}
                                onChange={(value) => {
                                  const [min, max] = value as number[];
                                  if (
                                    min >= section.minVal &&
                                    max <= section.maxVal
                                  ) {
                                    handleRangeChange(
                                      section,
                                      { min, max },
                                      true
                                    );
                                  }
                                }}
                                onChangeComplete={(value) => {
                                  const [min, max] = value as number[];
                                  if (
                                    min >= section.minVal &&
                                    max <= section.maxVal
                                  ) {
                                    handleRangeChange(section, { min, max });
                                  }
                                }}
                                marks={{
                                  [section.minVal]: section.minVal,
                                  [section.maxVal]: section.maxVal,
                                }}
                                styles={{
                                  track: {
                                    background: "#0D42EC",
                                  },
                                  handle: {
                                    borderColor: "#0D42EC",
                                  },
                                }}
                              />
                            ) : (
                              <Slider
                                range
                                max={section.maxVal}
                                min={section.minVal}
                                value={[
                                  section.currentVal.min,
                                  section.currentVal.max,
                                ]}
                                onChange={(value) => {
                                  const [min, max] = value as number[];
                                  if (
                                    min >= section.minVal &&
                                    max <= section.maxVal
                                  ) {
                                    handleRangeChange(
                                      section,
                                      { min, max },
                                      true
                                    );
                                  }
                                }}
                                onChangeComplete={(value) => {
                                  const [min, max] = value as number[];
                                  if (
                                    min >= section.minVal &&
                                    max <= section.maxVal
                                  ) {
                                    handleRangeChange(section, { min, max });
                                  }
                                }}
                                marks={{
                                  [section.minVal]: section.minVal,
                                  [section.maxVal]: section.maxVal,
                                }}
                                styles={{
                                  track: {
                                    background: "#0D42EC",
                                  },
                                  handle: {
                                    borderColor: "#0D42EC",
                                  },
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="absolute bottom-10 flex flex-col space-y-2">
        {!metricsAreUpdated ? (
          <span className="text-xs text-red-500">
            Metrics are not updated for this role.
          </span>
        ) : (
          <span className="text-xs text-indigo-500">
            Metrics are up to date
          </span>
        )}
        <button
          type="button"
          className="  rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-400 disabled:cursor-not-allowed "
          // disabled={metricsAreUpdated}
          onClick={handleComputeMetrics}
        >
          {computingMetrics ? (
            <div className="flex">
              <Spinner />
              <span className="ml-2">Calculating</span>
            </div>
          ) : (
            "Update Metrics"
          )}
        </button>
      </div>
      <Notification
        show={metricsUpdateNotification}
        setShow={setMetricsUpdateNotification}
        title="Metrics Calculation Complete"
        description="Please refresh the page to view the updates."
        type="success"
      />
    </div>
  );
}
