import {
  BriefcaseIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  MapPinIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { Role } from "../../../Models";
import { useState } from "react";
import { dateConverter } from "../../../Helpers";

const tabs = ["Candidates", "Edit"];

export default function RoleSideBarHeader({
  role,
  currentTab,
}: {
  role: Role | undefined;
  currentTab: string;
}) {
  const { roleId } = useParams<{ roleId: string }>();
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  let timeoutIdRef: NodeJS.Timeout;
  return (
    <div className="mx-auto bg-gray-100 dark:bg-darkbglight">
      <div className="min-w-0 px-4 pt-2 pb-1">
        {role && (
          <div className="text-gray-900 dark:text-gray-300 flex items-center space-x-2 justify-center">
            <h3 className="text-center text-lg font-semibold leading-7 sm:text-base sm:tracking-tight">
              {role.title}
            </h3>
            <div>
              <InformationCircleIcon
                className="hidden sm:block h-5 text-entntblue hover:text-entntorange cursor-pointer"
                onMouseEnter={(e) => {
                  timeoutIdRef = setTimeout(() => {
                    setInfoModalOpen(true);
                  }, 500);
                }}
                onMouseLeave={(e) => {
                  if (timeoutIdRef) {
                    window.clearTimeout(timeoutIdRef);
                  }
                  setInfoModalOpen(false);
                }}
              />
              {infoModalOpen && (
                <div className="absolute transform translate-x-4 flex flex-col sm:mt-0 bg-white z-20 border border-gray-200 w-[20rem] rounded-md shadow-md px-4 py-2 space-y-2 dark:bg-darkbglight dark:border-gray-500">
                  <div className="whitespace-normal flex items-center text-xs text-gray-700  dark:text-gray-400 ">
                    <BriefcaseIcon
                      className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
                      aria-hidden="true"
                    />
                    {role.employmentType}
                  </div>
                  <div className="flex items-center text-xs text-gray-700 dark:text-gray-400">
                    <MapPinIcon
                      className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
                      aria-hidden="true"
                    />
                    {role.location}
                  </div>
                  <div className="whitespace-normal flex items-center text-xs text-gray-700 dark:text-gray-400">
                    <CurrencyDollarIcon
                      className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
                      aria-hidden="true"
                    />
                    {role.salary}
                  </div>
                  <div className="whitespace-normal flex items-center text-xs text-gray-700 dark:text-gray-400">
                    <CalendarDaysIcon
                      className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
                      aria-hidden="true"
                    />
                    {dateConverter(role.date)}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex text-sm justify-between items-center py-1 my-1 mt-2 rounded-full bg-white border border-gray-200 relative dark:bg-darkbg dark:border-gray-500">
          <div
            className={`absolute bg-entntblue rounded-full top-[10%] left-[2%] h-[80%] w-[48%] z-0 transform transition-all ${
              currentTab === tabs[1] ? "translate-x-full" : "-translate-x-0"
            }`}
          ></div>
          <Link
            to={`/roles/${roleId}/candidates`}
            className="z-10 flex-1 text-center"
          >
            <button
              type="button"
              className={`inline-flex items-center gap-x-1.5  bg-transparent px-3 py-1 text-sm ${
                currentTab === tabs[0]
                  ? "text-gray-100"
                  : "text-gray-400 font-medium"
              }`}
            >
              <UserGroupIcon
                className={`-ml-0.5 h-4 w-4 ${
                  currentTab === tabs[0] ? "text-gray-100" : "text-gray-400"
                }`}
                aria-hidden="true"
              />
              Candidates
            </button>
          </Link>
          <Link
            to={`/roles/${roleId}/edit`}
            className="flex-1 z-10 text-center"
          >
            <button
              type="button"
              className={`inline-flex items-center gap-x-1.5  bg-transparent px-3 py-1 text-sm ${
                currentTab === tabs[1]
                  ? "text-gray-100"
                  : "text-gray-400 font-medium"
              }`}
            >
              <Cog6ToothIcon
                className={`-ml-0.5 h-4 w-4 ${
                  currentTab === tabs[1] ? "text-gray-100" : "text-gray-400"
                }`}
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
