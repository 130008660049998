import { UserCircleIcon } from "@heroicons/react/24/outline";

type props = {
  height?: string;
  width?: string;
  src?: string | undefined;
  avatarName?: string;
};

export default function Avatar({
  height = "h-16",
  width = "w-16",
  src,
  avatarName,
}: props) {
  return (
    <>
      {src || avatarName ? (
        src ? (
          <img className={`${height} ${width} rounded-full`} src={src} alt="" />
        ) : (
          <div
            className={`${height} ${width} rounded-full bg-red-50 flex justify-center items-center font-medium text-red-800`}
          >
            {avatarName}
          </div>
        )
      ) : (
        <UserCircleIcon
          className={`${height} ${width} text-gray-300 dark:text-gray-500`}
        />
      )}
    </>
  );
}
