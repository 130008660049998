import { getColorShade } from "../../../Helpers";
import UpdateRanking from "./UpdateRanking";
import { CategoryFull } from "../../../Models";

interface Props {
  columnData: any;
  tab: string;
  checked: boolean;
  handleSelectColumn: (columnId: string) => void;
  handleEditRankLabel: (
    category: CategoryFull,
    selectedColumn?: string
  ) => void;
  rankCategoryLabels: CategoryFull[];
}
const RankTableColumn = ({
  columnData,
  tab,
  checked,
  handleSelectColumn,
  handleEditRankLabel,
  rankCategoryLabels,
}: Props) => {
  return (
    <>
      <tr
        className="bg-white border-b dark:bg-gray-900 border-2	border-gray-100 dark:border-gray-700"
        key={columnData.id}
      >
        <td className="sm:table-cell text-center px-2 w-10">
          <input
            type="checkbox"
            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 dark:border-gray-100"
            checked={checked}
            onChange={() => handleSelectColumn(columnData.id)}
          />
        </td>

        <td className="px-4 py-1 text-gray-900  dark:text-white font-semibold">
          {tab !== "Companies" ? (
            columnData.name
          ) : columnData.url ? (
            <a
              href={columnData.url}
              rel="noreferrer"
              target="_blank"
              className="hover:text-blue-600"
            >
              {columnData.name}
            </a>
          ) : (
            columnData.name
          )}
        </td>
        {tab !== "Companies" && (
          <td className="px-6 py-1 text-gray-900  dark:text-white">
            {columnData?.state?.name}
          </td>
        )}
        {tab !== "Companies" && (
          <td className="px-6 py-1 text-gray-900  dark:text-white">
            <a
              href={columnData.url}
              target="_blank"
              rel="noreferrer"
              className="hover:text-entntblue"
            >
              {columnData.url}
            </a>
          </td>
        )}
        {tab === "Companies" && (
          <>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData.linkedIn && (
                <a
                  href={columnData.linkedIn}
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-600 hover:text-blue-700 cursor-pointer font-medium"
                >
                  LinkedIn
                </a>
              )}
            </td>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData?.location}
            </td>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData?.revenue}
            </td>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData?.stockMarket}
            </td>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData?.relevance}
            </td>
            <td className="px-6 py-1 text-gray-900  dark:text-white">
              {columnData?.hotStartup}
            </td>
          </>
        )}
        <td className="px-6 py-1 flex gap-2 text-gray-600">
          <div
            style={{
              background: columnData.rankingCategory
                ? tab === "Universities"
                  ? `linear-gradient(to right, ${
                      (
                        getColorShade(
                          columnData.rankingCategory.rankNumber,
                          true
                        ) as { grad: string; postGrad: string }
                      ).grad
                    } 50%, ${
                      (
                        getColorShade(
                          columnData.rankingCategory.rankNumber,
                          true
                        ) as { grad: string; postGrad: string }
                      ).postGrad
                    } 50%)`
                  : (getColorShade(
                      columnData.rankingCategory.rankNumber,
                      false
                    ) as string)
                : "gray",
            }}
            className={`flex items-center space-x-2 max-w-max px-2 py-1 rounded-lg font-semibold ${
              columnData.rankingCategory === null
                ? "text-white"
                : columnData.rankingCategory.rankNumber >= 3
                  ? "text-gray-800"
                  : "text-gray-100"
            }
            }`}
          >
            <UpdateRanking
              selected={columnData.rankingCategory?.rankCategoryId}
              handleEditRankLabel={handleEditRankLabel}
              selectedColumn={columnData.id}
              rankCategoryLabels={rankCategoryLabels}
            />

            <span className="pr-4">
              {columnData.rankingCategory === null
                ? "unranked"
                : columnData.rankingCategory.title}
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default RankTableColumn;
