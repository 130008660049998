import React, { useCallback, useEffect, useState } from 'react'
import { Filter, Question, QuestionGroup, Questions, Skill } from '../../Models';
import DropdownQuestionGroup from '../Shared/Dropdowns/DropdownQuestionGroup';
import { fetchAllSkills, getAllQuestions, getAllQuestionsByGid } from '../../WebCalls';
import QuestionCard from '../QuestionCard';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../Shared/PaginationComponent';
import Loading from '../Shared/Loading';
import { debounce } from '../../helpers/constants';
import { ArrowDownIcon, ArrowPathIcon, ArrowUpIcon, ArrowsUpDownIcon, MagnifyingGlassIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import QuestionsFilter from '../Shared/Filter/QuestionsFilter';
import NoData from '../Shared/NoData';

function QuestionsComponent({ questionGroups }: { questionGroups: Question[] }) {

    const [questions, setQuestions] = useState<Questions[]>([]);
    const [selectedQGroup, setSelectedQGroup] = useState<QuestionGroup | undefined>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 15;
    const [totalCount, setTotalCount] = useState<number>()
    const [loading, setLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('')
    const navigate = useNavigate();
    const [clearQuestionGroup, setClearQuestionGroup] = useState<boolean>();
    const [filters, setfilters] = useState<Filter[]>([]);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
    const [accuracy, setAccuracy] = useState<any>({
        min: 0,
        max: 100
    });
    const [sortOrder, setSortOrder] = useState<{ [key: string]: 'default' | 'asc' | 'desc' }>({
        type: 'default',
        time: 'default',
        accuracy: 'default',
    });




    const getAllSkills = useCallback(() => {
        fetchAllSkills().then((res) => {
            setSkills(res);

        });
    }, [])

    useEffect(() => {
        initialFilter();
    }, [questionGroups, skills]);


    useEffect(() => {
        getAllSkills();
    }, [getAllSkills]);


    useEffect(() => {
        handleSort();
    }, [sortOrder])


    const initialFilter = async () => {
        if (questionGroups && skills) {
            const skillOptions = skills.map((s: any) => {
                return { value: s, label: s, checked: false };
            })
            const groupOptions = questionGroups?.map((q) => {
                return { value: q as any, label: q as any, checked: false };

            })

            setfilters([
                {
                    id: 'skill',
                    name: 'Skills',
                    options: skillOptions,
                    type: "checkbox"
                },
                {
                    id: 'group',
                    name: 'Question Groups',
                    options: groupOptions,
                    type: "radio"
                },
                {
                    id: 'accuracy',
                    name: 'Accuracy',
                    options: [],
                    type: "radio"
                }

            ]);
        }
    }
    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        let res;
        const { min, max } = accuracy;

        if (selectedQGroup || selectedSkills || min !== 0 || max !== 100) {
            res = await getAllQuestionsByGid(accuracy, selectedQGroup?.id, selectedSkills, currentPage, searchQuery);
            setQuestions(res.questions);
            setTotalCount(res.totalCount);
            setLoading(false);
        }
        else {
            res = await getAllQuestions(currentPage, searchQuery);
            setQuestions(res.questions);
            setTotalCount(res.totalCount);
        }
        setLoading(false);
    }, [selectedQGroup, currentPage, searchQuery, selectedSkills, accuracy]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    const setSelectedQuestionGroup = (selected: QuestionGroup | undefined) => {
        setSelectedQGroup(selected);
        setCurrentPage(1);
        setClearQuestionGroup(false);

    }

    const handleQuestionAddNavigate = () => {
        navigate('/questions/add')
    }


    const handleQuestionGroupAddNavigate = () => {
        navigate('/questions/group/add')
    }


    const handleTableFilter = (filterType: string) => {

        if (!questions) {
            return;
        }

        let filteredQuestions: Questions[] = [];
        switch (filterType) {
            case 'type':
                const typeOrder = ['single', 'multiple', 'text', 'video'];
                filteredQuestions = [...questions].sort((a, b) => {
                    return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
                });
                break;
            case 'time':
                filteredQuestions = [...questions].sort((a, b) => a.time - b.time);
                break;
            case 'tag':
                filteredQuestions = [...questions].sort((a, b) => (a.tags > b.tags ? 1 : -1));
                break;
            default:
                filteredQuestions = questions;
                break;
        }
        setQuestions(filteredQuestions);
        setLoading(false);

    };


    const handleQuestionSearch = debounce((e: any) => {
        const val = e.target.value.trim();
        setSearchQuery(val);
        if (val === '') {
            setCurrentPage(1);
        }
        if (selectedQGroup || selectedSkills) {
            getAllQuestionsByGid(accuracy, selectedQGroup?.id, selectedSkills, currentPage, val).then(res => {
                setQuestions(res.questions)
                setTotalCount(res.totalCount)

            })
            setLoading(false);

        }
        else {
            getAllQuestions(currentPage, val).then(res => {
                setQuestions(res.questions)
                setTotalCount(res.totalCount)

            })
            setLoading(false);
        }
    }, 1000)

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleClearFilter = () => {
        setClearQuestionGroup(true);
        fetchQuestions();
        setSearchQuery('');
        initialFilter();
        setSelectedQGroup(undefined);
        setSelectedSkills([]);
        setCurrentPage(1);
        setAccuracy({
            min: 0,
            max: 100
        })

    }


    const handleFilter = (filter: Filter[]) => {
        let selectedSkills: string[] = [];
        let currentSelectedGroup: QuestionGroup | undefined = undefined;
        setCurrentPage(1);

        filter.forEach((e: Filter) => {
            if (e.id === 'skill') {
                e.options.forEach((a: any) => {
                    if (a.checked === true) {
                        selectedSkills.push(a.value.id)
                    }
                })
            } else if (e.id === 'group') {
                e.options.forEach((a: any) => {
                    if (a.checked === true) {
                        currentSelectedGroup = {
                            id: a.value.id,
                            title: a.value.title
                        }
                        setSelectedQuestionGroup(currentSelectedGroup)
                    }
                });
                setSearchQuery('');

            }
        });
        // filteredAssessmentQuestion(currentSelectedGroup, selectedSkills);
        setSelectedSkills(selectedSkills)
    };

    const getAccuracy = (value: any) => {
        setAccuracy(value);
    };


    const sortTable = (column: string) => {
        setSortOrder((prevSortOrder) => {
            const currentOrder = prevSortOrder[column];
            let newOrder: 'default' | 'asc' | 'desc' = 'asc';

            if (currentOrder === 'asc') {
                newOrder = 'desc';
            }


            const updatedSortOrder: { [key: string]: 'default' | 'asc' | 'desc' } = {};

            for (const key in prevSortOrder) {
                if (Object.prototype.hasOwnProperty.call(prevSortOrder, key)) {
                    updatedSortOrder[key] = key === column ? newOrder : 'default';
                }
            }

            return updatedSortOrder;
        });
    };


    const handleSort = () => {
        let sortedQuestions = [...questions];

        const typeOrder = ['single', 'multiple', 'text', 'video'];

        switch (sortOrder.type) {
            case 'asc':
                sortedQuestions.sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));
                break;
            case 'desc':
                sortedQuestions.sort((a, b) => typeOrder.indexOf(b.type) - typeOrder.indexOf(a.type));
                break;
            default:
                break;
        }

        switch (sortOrder.time) {
            case 'asc':
                sortedQuestions.sort((a, b) => a.time - b.time);
                break;
            case 'desc':
                sortedQuestions.sort((a, b) => b.time - a.time);
                break;
            default:
                break;
        }

        switch (sortOrder.accuracy) {
            case 'asc':
                sortedQuestions.sort((a, b) => a.accuracy - b.accuracy);
                break;
            case 'desc':
                sortedQuestions.sort((a, b) => b.accuracy - a.accuracy);
                break;
            default:
                break;
        }

        setQuestions(sortedQuestions);
        setLoading(false);
    };

    return (
        <>
            <header className="bg-gray-100 py-8 dark:bg-darkbglight">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                    <div className="min-w-0 flex-1">

                        <div className="min-w-0 flex-1">
                            <div className='flex items-center justify-between mb-4'>
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:tracking-tight dark:text-gray-300">
                                    Questions
                                </h1>
                                <div className='flex flex-col sm:flex-row mt-2'>
                                    <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={handleQuestionAddNavigate}>
                                        Add Question
                                    </button>
                                    <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={handleQuestionGroupAddNavigate}>
                                        Add Question Group
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
            <div className='container mx-auto px-4 '>
                <div className='flex flex-row justify-end items-end my-4 space-x-2 '>
                    <div className='relative'>
                        <input type="text" placeholder='Search' className='w-full x1 rounded-lg bg-white py-2 pl-10 pr-3 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 dark:text-gray-400 dark:border-gray-600 dark:bg-transparent focus-visible:ring-offset-blue-100 sm:text-sm' onChange={handleQuestionSearch} />
                        <MagnifyingGlassIcon
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 dark:text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                    <QuestionsFilter filters={filters} handleFilter={handleFilter} getAccuracy={getAccuracy} accuracy={accuracy} />

                    <div>
                        {
                            (selectedSkills.length > 0 || selectedQGroup || selectedSkills.length > 0 || (accuracy.min !== 0 || accuracy.max !== 100)) && <div className='flex flex-col justify-center items-center py-2 pr-3 ' onClick={handleClearFilter}>
                                <ArrowPathIcon height={20} color='gray' cursor={'pointer'} />
                            </div>
                        }
                    </div>
                </div>
                {(!loading && questions && questions?.length > 0 ) ? <div>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 ">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >
                                        Question
                                    </th>
                                    <th scope="col" className="px-6 py-3 hidden sm:table-cell">
                                        <div className="flex items-center">
                                            Type

                                            {sortOrder.type === 'asc' ?
                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                    sortTable('type')
                                                }} />
                                                :
                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                    sortTable('type')
                                                }} />
                                            }
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3 hidden sm:table-cell">
                                        <div className="flex items-center">
                                            Time
                                            {sortOrder.time === 'asc' ?
                                                <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                    sortTable('time')
                                                }} />
                                                :
                                                <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                    sortTable('time')
                                                }} />
                                            }


                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3 hidden sm:table-cell">
                                        <div className="flex items-center">
                                            Accuracy
                                            {
                                                sortOrder.accuracy === 'asc' ?
                                                    <ArrowUpIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                        sortTable('accuracy')
                                                    }} />
                                                    :
                                                    <ArrowDownIcon className="w-4 h-4 ml-1 text-gray-500 cursor-pointer" onClick={() => {
                                                        sortTable('accuracy')
                                                    }} />
                                            }


                                        </div >
                                    </th >
                                    <th scope="col" className="px-6 py-3 hidden sm:table-cell">
                                        <div className="flex items-center">
                                            Tags
                                        </div>
                                    </th>
                                    <th scope="col" className="px-6 py-3  ">

                                    </th>
                                </tr >
                            </thead >
                            {questions?.map((q) => {
                                return (
                                    <QuestionCard key={q.id} question={q} />
                                )
                            })
                            }
                        </table >
                    </div >

                </div >
                    : !loading && questions?.length === 0 ? <div className='' >
                        <NoData title='No Questions Found' description="There are currently no available Questions. Click 'Add Question' to start adding Questions for the Assessment." />
                    </div>
                        :
                        <>
                            <Loading />
                        </>
                }
            </div >
            {(totalCount !== undefined && totalCount > 15 && questions && questions.length > 0) && <PaginationComponent
                currentPage={currentPage}
                totalPages={Math.ceil((totalCount || 0) / itemsPerPage)}
                handlePageChange={handlePageChange}
            />
            }

        </>
    )
}

export default QuestionsComponent;
